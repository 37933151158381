export const apiAuthBaseUrl  = process.env.REACT_APP_AUTH_API_URL
export const apiDataBaseUrl = process.env.REACT_APP_DATA_API_URL

export const leadStatusesOptions = [
    {id: 'active', name: 'Active'},
    {id: 'pending', name: 'Pending'},
    {id: 'flagged', name: 'Flagged'},
    {id: 'blacklisted', name: 'Blacklisted'},
    {id: 'inactive', name: 'Inactive'}
]

export const campaignEngagementOptions = [
    {id: 'engaged', name: 'Clicked'},
    {id: 'unsubscribe', name: 'Unsubscribe'}
]

export const campaignSmsStatusOptions = [
    {id: 'sent', name: 'Sent'},
    {id: 'sending', name: 'Sending'},
    {id: 'delivered', name: 'Delivered'},
    {id: 'queued', name: 'Queued'},
    {id: 'blocked_by_carrier', name: 'Blocked'},
    {id: 'failed', name: 'Failed'},
    {id: 'inactive_device', name: 'Inactive device'},
]

export const engagementPeriodOptions = [
    {id: '24_hours', name: '24 Hours'},
    {id: 'last_7_days', name: 'Last 7 Days'},
    {id: 'last_30_days', name: 'Last 30 Days'},
    {id: 'last_90_days', name: 'Last 90 Days'},
    {id: 'this_month', name: 'This Month'},
    {id: 'this_year', name: 'This Year'},
    {id: 'all_time', name: 'All Time'}
]

export const storageKeys = {
    leadsHiddenColumnsInList: 'leadsHiddenColumnsInList',
    leadsHiddenColumnsInSegment: 'leadsHiddenColumnsInSegment',
    leadsHiddenColumnsInLeads: 'leadsHiddenColumnsInLeads',
    leadsHiddenColumnsInCampaign: 'leadsHiddenColumnsInCampaign',
    leadsHiddenColumnsInCampaignAudience: 'leadsHiddenColumnsInCampaignAudience',
    leadsHiddenColumnsInCampaignsList: 'leadsHiddenColumnsInCampaignsList',
}
