import React, {useMemo} from 'react';
import { useAuth } from "../../Contexts/AuthProvider";
import Ava1 from "../../assets/img-new/pava.png";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core';
import LimitCounter from "../../components/LimitCounter";

const ProfileDetails = () => {
    const history = useHistory()
    const {user, userName, subscriptions} = useAuth()
    const { t } = useTranslation();

    const subscriptionName = useMemo(() => {
        return (Array.isArray(subscriptions) && subscriptions.length > 0) ? subscriptions[0].name : null
    }, [subscriptions])

    function getInitials(userName) {
      const words = userName.split(" ");
      if (words.length === 1) {
        return userName.charAt(0);
      } else {
        return words[0].charAt(0) + words[1].charAt(0);
      }
    }

    return (
        <>
            <div className="mainSubscriptionPlanHolder">
                <div className="mainSubscriptionPlan">
                    <div className="userInfoWrapper">
                        <div className="userInfoHolder">
                        <div className="avaHolder">
                            <div>{getInitials(userName)}</div>
                        </div>
                        <div className="userNameHolder">
                            <div className="titleLike">{t("New Profile.ProfileDetails.Profile Details")}</div>
                            <div className="userName">{userName}</div>
                        </div>    
                        </div>
                        {/*<div className="userStatHolder">*/}
                        {/*  <div className="statHolder">*/}
                        {/*    <img src="" alt=""/>*/}
                        {/*  </div>*/}
                        {/*  <div className="statTitle">Profile information</div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="planInfoHolder">
                        <div className="nameHolder">
                            <div className="subName">{t("New Profile.ProfileDetails.Current Plan")}</div>
                            <div className="name">{subscriptionName ? t(`PlansCards.${subscriptionName}`) : ''}</div>
                        </div>
                        <div className="planInfoWrapper">
                            <table className="spInfoList">
                                <tbody>
                                    <tr>
                                        <td>{t("New Profile.ProfileDetails.Imported Contacts")}</td>
                                        <td>
                                            <LimitCounter limitName={'import-limit'} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("New Profile.ProfileDetails.Free SMS")}</td>
                                        <td>
                                            <LimitCounter limitName={'free-sms-limit'} />
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                            <div className="btnHolder">
                                <Button
                                    onClick={e => history.push("/profile/subscriptions")}
                                    className='btn-billing'
                                    // color='primary'
                                    variant='contained'
                                    size="small"
                                >
                                    {t("New Profile.ProfileDetails.Change plan")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileDetails
