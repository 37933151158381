import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '../Contexts/AppContext'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getShortUrlDomains, removeShortUrlDomain } from '../api'
import ListTable from '../components/ListTable/ListTable'
import DeleteModal from '../components/modals/DeleteModal'
import ShortUrlDomainItemContextMenu from '../components/ShortUrlDomainItemContextMenu'
import SaveShortUrlDomainModal from '../components/modals/SaveShortUrlDomainModal'
import PlusCircle from '../assets/img/plus_circle.svg'
import EventEmitter from '../Utils/EventEmitter'
import {useTranslation} from "react-i18next";

const ShortUrlDomains = () => {
    const { t } = useTranslation();
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [pagination] = useState(null)
    const [sorting, setSorting] = useState([])
    const [,setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [availableAttributes, setAvailableAttributes] = useState([])
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [currentModel, setCurrentModel] = useState(false)
    const tableColumns = useMemo(() => {
        return [
            {
                Header: t('Short Url Domains.Domain Name'),
                accessor: 'url'
            },
            {
                Header: t('Short Url Domains.Access'),
                accessor: 'access',
                Cell: ({value}) => (
                    <div className={value ? "chipLike success" : "chipLike"}>
                        <span className="chipLikeLabel">{value ? 'Enabled' : 'Disabled'}</span>
                    </div>
                )
            },
            {
                Header: '',
                id: 'actions',
                className: 'text-right',
                Cell: ({value, row}) => {
                    return (
                        <ShortUrlDomainItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditDomain(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    )
                }
            }
        ]
    }, [t])
    const queryClient = useQueryClient()
    const {setContextMenu} = useAppDispatch()

    //breadcrumbs
    useEffect(() => {
        setBreadcrumbs([
            {label: t('Short Url Domains.Settings'), link: '/settings'},
            {label: t('Short Url Domains.Short Url Domains')}
        ])
        setSection('settings')
    }, [setBreadcrumbs, setSection, t])

    useEffect(() => {
        setContextMenu([
            {
                label: t('Short Url Domains.Add New Domain'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('ShortUrlDomainCreate'),
            },
        ])
    }, [setContextMenu, t])

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true)

        const listener = EventEmitter.addListener('ShortUrlDomainCreate', onEvent)
        return () => { listener.remove() }
    }, [])

    const shortUrlDomainsRequest = useQuery(
        ['shortUrlDomains', sorting],
        () => getShortUrlDomains(sorting),
        {keepPreviousData: true}
    )

    const deleteShortUrlDomainRequest = useMutation(removeShortUrlDomain)

    useEffect(() => {
        if (shortUrlDomainsRequest.isSuccess) setAvailableAttributes(shortUrlDomainsRequest.data.data)
    },[shortUrlDomainsRequest.isSuccess, shortUrlDomainsRequest.data])
    

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])


    const handleEditDomain = (attr) => {
        setCurrentModel(attr)
        setShowUpdateModal(true)
    }

    const showDeleteConfirmation = (attr) => {
        setCurrentModel(attr)
        setShowDeleteModal(true)
    }

    const deleteDomain = async () => {
        if (!currentModel) return;

        await deleteShortUrlDomainRequest.mutateAsync(currentModel.id)
        setShowDeleteModal(false)
        await queryClient.invalidateQueries('shortUrlDomains')
        setCurrentModel(null)
    }


    const modelLabel = currentModel ? `"${currentModel.url}" domain` : 'domain'

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={availableAttributes}
                loading={shortUrlDomainsRequest.isLoading}
                fetching={shortUrlDomainsRequest.isFetching}
                onSort={setSorting}
                emptyDataText={t('Short Url Domains.No Domains Found')}
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                disableSearchBlock={true}
            />
            <DeleteModal
                loading={deleteShortUrlDomainRequest.isLoading}
                open={showDeleteModal}
                handleDelete={deleteDomain}
                handleClose={() => setShowDeleteModal(false)}
                instance={modelLabel}
            />

            <SaveShortUrlDomainModal
                open={showCreateModal}
                handleClose={() => {
                    setShowCreateModal(false)
                }}
            />

            {
                currentModel && (
                    <SaveShortUrlDomainModal
                        defaultValues={currentModel}
                        open={showUpdateModal}
                        handleClose={() => {
                            setCurrentModel(null)
                            setShowUpdateModal(false)
                        }}
                    />
                )
            }
        </div>
    )
}

export default ShortUrlDomains
