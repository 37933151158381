import React, { Fragment, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { BookmarkBorder } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import SaveSMSTemplateModal from './modals/SaveSMSTemplateModal';

const SaveSmsTemplateButton = ({disabled, message}) => {

    const [showModal, setShowModal] = useState(false)
    const {t} = useTranslation()

    return (
        <Fragment>
            <Tooltip title={t('SMS Templates.Save Template')}>
                <span>
                    <IconButton
                        disabled={disabled}
                        size="small"
                        onClick={() => setShowModal(true)}
                    >
                        <BookmarkBorder />
                    </IconButton>
                </span>

            </Tooltip>
            <SaveSMSTemplateModal
                simpleMode={true}
                simpleModeMessage={message}
                typeOptions={[]}
                availableAttributes={[]}
                open={showModal}
                handleClose={() => {
                    setShowModal(false);
                }}
            />
        </Fragment>

    );
};

export default SaveSmsTemplateButton;
