import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../Contexts/AuthProvider'

const useApiCall = url => {
    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(false)
    const [ignoreAuthToken, setIgnoreAuthToken] = useState(false)
    const [error, setError] = useState(null)
    const [options, setOptions] = useState({})
    const { token } = useAuth()

    const doCall = useCallback((options = {}) => {
        if (options.ignoreAutToken) {
            setIgnoreAuthToken(true)
        }
        setOptions(options)
        setLoading(true)
    }, [])

    useEffect(() => {
        let cancel

        const fetchData = async () => {

            try {
                let requestOptions
                if (ignoreAuthToken) {
                    requestOptions = {
                        ...options,
                        cancelToken: new axios.CancelToken(c => cancel = c)
                    }
                } else {
                    requestOptions = {
                        ...options,
                        // ...{
                        //     headers: {
                        //         authorization: token ? `Token ,,,,,,,,,,,,,,` : ``
                        //     }
                        // },
                        cancelToken: new axios.CancelToken(c => cancel = c)
                    }
                }

                const requestUrl = url || requestOptions.url

                const response = await axios(`${requestUrl}`, requestOptions)
                setResponse(response.data)
            } catch (e) {
                if (axios.isCancel(e))
                    return

                console.log(e)
                if (e && e.response && e.response.data && e.response.data.message) {
                    setError({ message: e.response.data.message })
                } else {
                    setError({ message: 'Incorrect password or user not found.' })
                }
            }
        }

        if (!loading)
            return

        fetchData().then()

        return () => { cancel() }
    }, [
        ignoreAuthToken,
        url,
        loading,
        options,
        token
    ])

    return [{ response, loading, error }, doCall]
}

export default useApiCall
