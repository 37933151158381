import React from 'react'
import {useTranslation} from "react-i18next";

const TableEmptyListMessage = ({children}) => {
    const { t } = useTranslation();
    return (
        <tr>
            <td colSpan="100%">
                <p className="text-center" style={{paddingTop: '15px', color: 'rgb(144 144 144)'}}>{ children || t('Table Empty List Message.No records found')}</p>
            </td>
        </tr>
    )
}

export default TableEmptyListMessage
