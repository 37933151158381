import React, { useEffect, useMemo, useRef, useState } from 'react';
import AlertToastr from '../../Utils/alert'
import { useTranslation } from "react-i18next";
import { useAppDispatch } from '../../Contexts/AppContext';
import { useMutation, useQuery } from 'react-query';
import { balanceSubscribe, getSubscriptions } from '../../api';
import PlanItemCard from './PlanItemCard';
import { useAuth } from "../../Contexts/AuthProvider";
import moment from "moment";
import { LiqPayForm } from "../../components/modals/AddBalanceModal";

const SubscriptionPlan = () => {
    const { t } = useTranslation();
    const { isLoggedIn, subscriptions } = useAuth()
    const [availablePlans, setAvailablePlans] = useState([]);
    const [, setActiveSubscriptions] = useState([]);
    const formLiqPayRef = useRef(null)
    const [liqpay, setLiqPayForm] = useState({ data: '', signature: '' })

    const { setBreadcrumbs, setSection, setContextMenu } = useAppDispatch();

    const subscriptionsRequest = useQuery(
        ['subscriptions'],
        () => getSubscriptions(),
        { keepPreviousData: true },
    );

    const subscribeMutation = useMutation(balanceSubscribe)

    useEffect(() => {
        setContextMenu([]);
    }, [setContextMenu]);

    //breadcrumbs
    useEffect(() => {
        setBreadcrumbs([
            // {label: 'Profile', link: '/change-password'},
            { label: t('Profile.Subscriptions') },
        ]);
        setSection('settings');
    }, [setBreadcrumbs, setSection, t]);

    useEffect(() => {
        if (subscriptionsRequest.isSuccess) {
            const { available_plans, active_subscriptions } = subscriptionsRequest.data
            setAvailablePlans(available_plans);
            setActiveSubscriptions(active_subscriptions);
        }
    }, [
        subscriptionsRequest.isSuccess,
        subscriptionsRequest.data
    ])


    const planSelect = async plan => {
        const data = await subscribeMutation.mutateAsync(plan.id)

        // if subscribe success
        if (data && data.hasOwnProperty('active_subscriptions')) {
            // setActiveSubscriptions(data.active_subscriptions);
            AlertToastr.showAlert(t('Subscriptions.Successfully subscribed to new plan'))
            // тимчасово, треба якесь правильне рішення
            setTimeout(() => window.location.reload(), 2000)
            // if need top up balance first
        } else if (data && data.hasOwnProperty('data')) {
            setLiqPayForm(data.data)
            formLiqPayRef.current.submit()
        }
    }

    const activeSubscription = useMemo(() => {
        return Array.isArray(subscriptions) && subscriptions.length > 0 ? subscriptions[0] : null
    }, [subscriptions])

    const isDisabledSubscription = useMemo(() => {
        if (Array.isArray(subscriptions) && subscriptions.length > 0) {
            // const isTrial = subscriptions[0] && subscriptions[0].name.toLowerCase() === 'trial'
            // const isEnded = subscriptions[0] && moment().isAfter(moment(subscriptions[0].ends_at, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS))

            // return !isTrial || !isLoggedIn || isEnded
            return !isLoggedIn
        }

        return !isLoggedIn
    }, [isLoggedIn, subscriptions])

    return (
        <>
            <div className="contentPartTitleHolder">
                <div className="contentPartTitle">{t("New Profile.Subscriptions")}</div>
            </div>
            <div className="cpSubTitle">{t("New Profile.SubscriptionPage.Plan")}</div>
            <div className="subscriptionPlanListHolder mb45">
                <ul className="subscriptionPlanList">
                    {availablePlans.map(item => (
                        item.name !== 'Trial' &&
                        <PlanItemCard
                            key={item.id}
                            item={item}
                            disabled={isDisabledSubscription}
                            onSelect={planSelect}
                        />
                    ))}
                    {/*<li className="splItem">*/}
                    {/*    <div className="splItemContent">*/}
                    {/*        <div className="splTitleHolder">*/}
                    {/*            <div className="titlePart">*/}
                    {/*                <div className="spTitle">Professional</div>*/}
                    {/*                <div className="spSubTitle">35 days remaining</div>*/}
                    {/*            </div>*/}
                    {/*            <div className="pricePart">*/}
                    {/*                <div className="price">25$/</div>month*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <table className="spInfoList">*/}
                    {/*            <tr>*/}
                    {/*                <td>Imported Contacts</td>*/}
                    {/*                <td>100/1000</td>*/}
                    {/*            </tr>*/}
                    {/*            <tr>*/}
                    {/*                <td>Free SMS</td>*/}
                    {/*                <td>56/1000</td>*/}
                    {/*            </tr>*/}
                    {/*        </table>*/}
                    {/*        <div className="btnHolder">*/}
                    {/*            <a href="/" className="btnLike">{t("New Profile.SubscriptionPage.Upgrade")}</a>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li className="splItem">*/}
                    {/*    <div className="splItemContent">*/}
                    {/*        <div className="splTitleHolder">*/}
                    {/*            <div className="titlePart">*/}
                    {/*                <div className="spTitle">Professional</div>*/}
                    {/*                <div className="spSubTitle">35 days remaining</div>*/}
                    {/*            </div>*/}
                    {/*            <div className="pricePart">*/}
                    {/*                <div className="price">25$/</div>month*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <table className="spInfoList">*/}
                    {/*            <tr>*/}
                    {/*                <td>Imported Contacts</td>*/}
                    {/*                <td>100/1000</td>*/}
                    {/*            </tr>*/}
                    {/*            <tr>*/}
                    {/*                <td>Free SMS</td>*/}
                    {/*                <td>56/1000</td>*/}
                    {/*            </tr>*/}
                    {/*        </table>*/}
                    {/*        <div className="btnHolder">*/}
                    {/*            <a href="/" className="btnLike">{t("New Profile.SubscriptionPage.Upgrade")}</a>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                </ul>
            </div>
            {/*<div className="cpSubTitle">Payment Method</div>*/}
            {/*<div className="paymentMethodListHolder mb45">*/}
            {/*    <ul className="paymentMethodList">*/}
            {/*        <li className="pmlItem">*/}
            {/*            <div className="pmlItemContent">*/}
            {/*                <div className="title">Credit Card</div>*/}
            {/*                <div className="cardInfo">*/}
            {/*                    <div className="imgHolder">*/}
            {/*                        <img src={Cards} alt=""/>*/}
            {/*                    </div>*/}
            {/*                    <div className="numberHolder">*/}
            {/*                        **** **** **** 1839*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </li>*/}
            {/*        <li className="pmlItem default">*/}
            {/*            <div className="pmlItemContent">*/}
            {/*                <div className="imgHolder">*/}
            {/*                    <img src={Plus} alt=""/>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}

            <LiqPayForm data={liqpay.data} signature={liqpay.signature} ref={formLiqPayRef} />
        </>
    )
}

export default SubscriptionPlan
