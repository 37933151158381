import React, { Fragment, useState } from 'react';
import SaveAttributeModal from './modals/SaveAttributeModal';
import {useTranslation} from "react-i18next";

const AddAttributeButton = ({disabled}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const className = disabled
        ? 'text-mute text-semi-bold cursor-pointer text-right'
        : 'text-mute text-semi-bold cursor-pointer text-right linkPrimary';

    return (
        <Fragment>
            <div
                className={className}
                style={!disabled ? {} : {color: '#ccc'}}
                onClick={(e) => {
                    if (disabled) return;
                    setShowModal(true)
                }}
            >
                {t('Audience Filter.Add an attribute')}
            </div>
            <SaveAttributeModal
                open={showModal}
                handleClose={() => {
                    setShowModal(false);
                }}
            />
        </Fragment>
    );
};

export default AddAttributeButton;
