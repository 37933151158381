import React, { Fragment, useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import DataService from '../../Services/DataService'
import File from '../../assets/img/file_blank.svg'
import AlertToastr from '../../Utils/alert'
import {
    setLeads,
    setSources,
    setLists,
    setOverallSources,
    setSource,
    setList
} from '../../Store/modules/sources/actions'
import { connect } from 'react-redux'
import {useTranslation} from "react-i18next";

const ImportProgressModal = ({
                                 open,
                                 setOpen,
                                 importId,
                                 importSyncId,
                                 importFilename,
                                 list,
                                 setLists,
                                 setSources,
                                 setLeads,
                                 setSource,
                                 setList,
                                 source,
                                 sources,
                                 setOverallSources
                             }) => {
    const [progress, setProgress] = useState(0)
    const [syncProgress, setSyncProgress] = useState(0)
    const [isFileImported, setIsFileImported] = useState(false)
    const [isLeadSynchronized, setIsLeadSynchronized] = useState(false)
    const [isImportFailed, setIsImportFailed] = useState(false)
    const [impId, setImpId] = useState(null)
    const [syncId, setSyncId] = useState(null)
    const [filename, setFilename] = useState('')
    const [isUpdatedData, setIsUpdatedData] = useState(false)
    const { t } = useTranslation();
    useEffect(() => {
        if (importId)
            setImpId(importId)
        if (importSyncId)
            setSyncId(importSyncId)
        if (importFilename)
            setFilename(importFilename)
    }, [importId, importSyncId, importFilename])

    useEffect(() => {
        async function fetchLeads() {
            let listResponse = await DataService.getList(list.id)
            if (listResponse && listResponse.data && listResponse.data.data)
                setList(listResponse.data.data)
        }

        if (list && isLeadSynchronized && !isUpdatedData) {
            setIsUpdatedData(true)
            fetchLeads().then()
        }
    }, [list, setLeads, isLeadSynchronized, isUpdatedData, setList])

    useEffect(() => {
        async function fetchSources() {
            let response = await DataService.getSources()
            if (!response || !response.data)
                return
            if (response.data.data)
                setSources(response.data.data)
            if (response.data.over_all_sources)
                setOverallSources(response.data.over_all_sources)
        }

        if (!source && isLeadSynchronized)
            fetchSources().then()
    }, [setSources, isLeadSynchronized, sources, setOverallSources, source])


    useEffect(() => {
        async function fetchListsAndSource() {
            let sourceResponse = await DataService.getSource(source.id)
            if (sourceResponse && sourceResponse.data && sourceResponse.data.data)
                setSource(sourceResponse.data.data)
        }

        if (source && !list && isLeadSynchronized && !isUpdatedData) {
            setIsUpdatedData(true)
            fetchListsAndSource().then()
        }
    }, [source, list, setLists, isLeadSynchronized, isUpdatedData, setSource])

    useEffect(() => {
        if (open && impId) {
            const interval = setInterval(() => {
                DataService.getImportById(impId).then(res => {
                    if (res && res.data && res.data.data) {
                        setProgress(+res.data.data.progress)
                        if (+res.data.data.progress === 100) {
                            clearInterval(interval)
                            setIsFileImported(true)
                        }
                    }
                }).catch((error) => {
                    clearInterval(interval)
                    setIsImportFailed(true)
                    if (error && error.response && error.response.data && error.response.data.message)
                        AlertToastr.showErrorAlert(error.response.data.message)
                })
            }, 3000)
        }

    }, [open, impId])

    useEffect(() => {
        if (isFileImported && impId && syncId)
            DataService.syncLeads(syncId).then(response => {
                const interval = setInterval(() => {
                    DataService.getSyncImportById(impId).then(res => {
                        if (res && res.data && res.data.data) {
                            setSyncProgress(+res.data.data.progress)
                            if (+res.data.data.progress === 100) {
                                clearInterval(interval)
                                setIsLeadSynchronized(true)
                            }
                        }
                    }).catch((error) => {
                        clearInterval(interval)
                        setIsImportFailed(true)
                        if (error && error.response && error.response.data && error.response.data.message)
                            AlertToastr.showErrorAlert(error.response.data.message)
                    })
                }, 3000)
            })

    }, [isFileImported, impId, syncId])

    const onClose = () => {
        setIsFileImported(false)
        setIsLeadSynchronized(false)
        setProgress(0)
        setOpen(false)
    }

    const getMessage = () => {
        let msg = t('Modals.Importing source data')
        if (isFileImported)
            msg = t('Modals.Lead synchronization')
        if (isLeadSynchronized)
            msg = t('Modals.Import finished')
        if (isImportFailed)
            msg = t('Modals.Import failed')
        return msg
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={(e) => onClose()}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{getMessage()}</h2>
                    </div>
                    {!isFileImported && !isImportFailed &&
                    <div className="fileProgressHolder">
                        <ul className="fileProgress">
                            <li className="fileProgressItem">
                                <div className="icoHolder">
                                    <img src={File} alt=""/>
                                </div>
                                <div className="progress">
                                    <div className="progressStatus mb5">
                                        <div className="name text-mute text-semi-bold">{filename}</div>
                                        <div className="qnt text-light text-mute">
                                            {`${Math.round(
                                                progress
                                            )}%`}
                                        </div>
                                    </div>
                                    <LinearProgress variant="determinate" value={progress}/>
                                </div>
                            </li>
                        </ul>
                    </div>
                    }
                    {isFileImported && !isImportFailed &&
                    <div className="fileProgressHolder">
                        <ul className="fileProgress">
                            <li className="fileProgressItem">
                                <div className="icoHolder">
                                    <img src={File} alt=""/>
                                </div>
                                <div className="progress">
                                    <div className="progressStatus mb5">
                                        <div className="name text-mute text-semi-bold">{filename}</div>
                                        <div className="qnt text-light text-mute">
                                            {`${Math.round(
                                                syncProgress
                                            )}%`}
                                        </div>
                                    </div>
                                    <LinearProgress variant="determinate" value={syncProgress}/>
                                </div>
                            </li>
                        </ul>
                    </div>
                    }

                    <div className="btnHolder hasPadTop">
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={!isLeadSynchronized && !isImportFailed}
                            onClick={(e) => onClose()}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

const mapStateToProps = ({sources}) => ({
    list: sources.list,
    sources: sources.sources,
    source: sources.source,
    lists: sources.lists
})

const mapDispatchToProps = {
    setLeads,
    setLists,
    setSources,
    setSource,
    setList,
    setOverallSources
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportProgressModal)
