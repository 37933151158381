import React from 'react'
import { NavLink } from 'react-router-dom'

const MenuItem = ({item, disabled}) => {

    const {label, link, icon, action} = item

    if (disabled) {
        return (
            <div className="menuListItem" style={{opacity: .5}}>
                <img src={icon}
                     className="ico"
                     alt={label}
                />
                <span className="caption" >{label}</span>
            </div>
        )
    }

    if (link) return (
        <NavLink to={link}
                 exact
                 className="menuListItem"
                 activeClassName='active'
        >
            <img src={icon}
                 className="ico"
                 alt={label}
            />
            <span className="caption">{label}</span>
        </NavLink>
    )

    if (action) {
        return (
            <div className="menuListItem" onClick={action}>
                <img src={icon} className="ico" alt={label} />
                <span className="caption">{label}</span>
            </div>
        )
    }

    return null
}

export default MenuItem
