import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../Contexts/AppContext'
import { useQuery } from 'react-query'
import { getImportFile } from '../api'
import ImportFileFailedLeads from './ImportFileFailedLeads'
import {useTranslation} from "react-i18next";

const ImportFile = () => {
    const { t } = useTranslation();
    const {fileId} = useParams()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [filename, setFilename] = useState('')
    const {setContextMenu} = useAppDispatch()

    useEffect(() => {
        setContextMenu([])
    }, [setContextMenu])

    const {isFetching} = useQuery(
        ['importFile', fileId],
        () => getImportFile(fileId),
        {
            keepPreviousData: true,
            onSuccess: data => {
                setFilename(data.data.name)
            }
        }
    )

    useEffect(() => {
        setBreadcrumbs([
            {label: t('Import Files.Settings'), link: '/settings'},
            {label: t('Import Files.Import History'), link: '/settings/import-history'},
            {label: filename, loading: isFetching}
        ])
        setSection('settings')
    }, [setBreadcrumbs, setSection, filename, isFetching, t])

    return <ImportFileFailedLeads fileId={fileId} />
}

export default ImportFile
