import React, { useState } from 'react';
import LeadAttributeEditValue from './LeadAttributeEditValue';
import LeadAttributeValue from './LeadAttributeValue';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { t } from 'i18next';

const LeadAttributeRow = ({ attr, onSuccessUpdate }) => {

    const [edit, setEdit] = useState(false)

    const switchToEditMode = () => setEdit(true)
    const switchToViewMode = () => setEdit(false)

    const handleEdit = (updatedAttribute) => {
        if (updatedAttribute) {
            if (onSuccessUpdate) onSuccessUpdate(updatedAttribute)
        }
        switchToViewMode()
    }

    const translateAttr = (attrName) => {
        switch (attrName) {
            case "Phone":
                return t('LeadLog.Phone')
            case "Verified":
                return t('LeadLog.Verified')
            case "mobile":
                return t('LeadLog.mobile')
            case "First Name":
                return t('LeadLog.First Name')
            case "Last Name":
                return t('LeadLog.Last Name')
            case "Note":
                return t('LeadLog.Note')
            case "Item":
                return t('LeadLog.Item')
            default:
                return attrName
        }
    }

    return (
        <div className="tr">
            <div className="td">{translateAttr(attr.name)}</div>
            <div className="td">
                <div className="editTextOnClick">
                    <div className="captionPart">
                        {
                            edit
                                ? <LeadAttributeEditValue onEditDone={handleEdit} attr={attr} />
                                : <LeadAttributeValue onDoubleClick={switchToEditMode} value={attr.value} />
                        }
                    </div>
                    {
                        attr.type === 'phone' && (
                            <div className="captionPart">
                                <span className='caption'>
                                    {/*{ attr.ban && <Fragment><span style={{background: '#3c3c3c', color: '#fff', borderRadius: '4px', padding: '1px 4px'}}>blacklisted</span><br /></Fragment>}*/}
                                    {attr.status_label && <>{t('LeadLog.Status')}: <span>{translateAttr(attr.status_label)}</span><br /></>}
                                    {attr.carrier_name && <>{t('LeadLog.Carrier')}: <span>{translateAttr(attr.carrier_name)}</span><br /></>}
                                    {attr.carrier_type && <>{t('LeadLog.Carrier type')}: <span>{translateAttr(attr.carrier_type)}</span><br /></>}
                                </span>
                            </div>
                        )
                    }
                    {
                        !edit && <IconButton onClick={() => setEdit(true)} size="small"><EditIcon /></IconButton>
                    }
                </div>
            </div>
        </div>
    );
};

export default LeadAttributeRow;
