import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useMutation, useQueryClient } from 'react-query';
import { storeLeadAttribute } from '../../api';
import AlertToastr from '../../Utils/alert';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import SaveAttributeModal from './SaveAttributeModal';

const useStyles = makeStyles((theme) =>
    ({
        collapseWrapper: {
            display: 'block',
        },
        selectAdornment: {
            marginRight: theme.spacing(3),
        },
    }),
);

const SaveAttributeWithValueModal = props => {
    const classes = useStyles();

    const {t} = useTranslation();
    const {
        open,
        handleClose,
        defaultValues,
        leadId,
        attributes = [],
    } = props;

    const queryClient = useQueryClient();

    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,
        watch
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues,
    });

    const [showSaveAttrModal, setShowAttrModal] = useState(false);
    const [createdAttrId, setCreatedAttrId] = useState(null)
    const nameRef = useRef();

    const storeAttributeMutation = useMutation(storeLeadAttribute);

    useEffect(() => {
        if (!createdAttrId) return;
        if (attributes.find(item => item.id === createdAttrId)) {
            setValue('attribute_id', createdAttrId)
        }
    }, [
        createdAttrId, attributes, setValue
    ])

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    useEffect(() => {
        if (storeAttributeMutation.error) {
            AlertToastr.showErrorResponseAlert(storeAttributeMutation.error);
        }
    }, [storeAttributeMutation.error]);


    const onSubmit = handleSubmit(async data => {
        const attrModel = attributes.find(attr => attr.id === data.attribute_id)
        if (!attrModel) return;

        const storeData = {
            lead_id: leadId,
            type: attrModel.type,
            ...data,
        }

        try {
            await storeAttributeMutation.mutateAsync(storeData);
            handleClose();
            queryClient.invalidateQueries('leadView').then()
            queryClient.invalidateQueries('leadLogs').then()
        } catch (e) {
            console.log(e);
        }
    });

    const title = defaultValues ? t('Modals.Edit Value') : t('Modals.Add Value');
    const hasEmptyFields = !watch('attribute_id') || !watch('value')

    const renderAttributesField = () => {
        return (
            <Fragment>
                <Controller
                    name={'attribute_id'}
                    control={control}
                    defaultValue=""
                    render={({onChange, value}) => {
                        return (
                            <TextField
                                select
                                onChange={onChange}
                                value={value}
                                variant="outlined"
                                margin="normal"
                                label={t('Modals.Attribute')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className={classes.selectAdornment}>
                                            <IconButton
                                                onClick={() => setShowAttrModal(true)}
                                                edge="end"
                                            >
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        </InputAdornment>),
                                }}
                            >
                                <MenuItem value="" disabled>
                                    {t('Modals.Select Attribute')}
                                </MenuItem>
                                {attributes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        );
                    }}
                />
                <SaveAttributeModal
                    onSuccessCreate={(item) => {
                        setCreatedAttrId(item.id)
                    }}
                    open={showSaveAttrModal}
                    handleClose={() => {
                        setShowAttrModal(false);
                    }}
                />
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modal"
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className="mb15">{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        {renderAttributesField()}
                        <TextField
                            inputRef={(e) => {
                                register(e);
                                nameRef.current = e;
                            }}
                            name="value"
                            label={t('Modals.Value')}
                            variant="outlined"
                            margin="normal"
                        />
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={storeAttributeMutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={storeAttributeMutation.isLoading || hasEmptyFields}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={onSubmit}
                                >
                                    {t('Modals.Save')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default SaveAttributeWithValueModal;
