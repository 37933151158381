import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import useApiCall from '../../Hooks/useApiCall';
import authEndpoints from '../../Endpoints/auth.endpoints';
import { useAuth } from '../../Contexts/AuthProvider';
import AlertToastr from '../../Utils/alert';
import {useTranslation} from "react-i18next";

const LoginPage = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [request, signin] = useApiCall();
    const {login, isLoggedIn} = useAuth();

    const onLoginClicked = (e) => {
        e.preventDefault();
        signin({
            ...authEndpoints.login(),
            data: {
                email: email,
                password: password,
            },
        });
    };

    useEffect(() => {
        if (request.error) {
            AlertToastr.showErrorAlert(request.error.message);
        }
    }, [request.error]);

    useEffect(() => {
        if (!request.response)
            return;
        login(request.response.data.access_token);

    }, [request.response, login]);

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <div className="formHolder">
            <p className="mb20">{t('Auth.Login to your account')}</p>
            <form noValidate autoComplete="off">
                <TextField
                    id="email"
                    label={t("Auth.Email")}
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className='loginField'
                    margin="normal"
                    autoComplete="username"
                />
                <TextField
                    id="pass"
                    label={t("Auth.Password")}
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    className='loginField'
                    margin="normal"
                    autoComplete="current-password"
                />
                <div className="mtm4">
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={t("Auth.Keep me logged in")}
                    />
                </div>
                <div className="btnHolder mt20 mb15">
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        onClick={onLoginClicked}
                    >
                        {t("Auth.Log in")}
                    </Button>
                </div>
                <a href="/forgot-password" className="text-mute text-semi-bold linkColorNormal cursor-pointer">
                    {t("Auth.Forgot your password")}
                </a>
            </form>
        </div>
    );
};

export default LoginPage;
