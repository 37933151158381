import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Dashboard from '../assets/img/segments.svg'
import Camp from '../assets/img/campaigns.svg'
import Templates from '../assets/img/templates.svg'
import IdCard from '../assets/img/contactsIcon.svg'
import DemoIcon from '../assets/img/demoIcon.svg'
// import SettingsIcon from '../assets/img/settings.svg'
import UserCheckIcon from '../assets/img/contactCheck.svg'
import IcoData from '../assets/img/folders.svg'
import { useQuery } from 'react-query'
import { getConfig, getSMSTemplates, getSourcesWithLists, lookupStatus } from '../api';
import { useAuth } from './AuthProvider'
import {useTranslation} from "react-i18next";

const AppStateContext = React.createContext(undefined)
const AppDispatchContext = React.createContext(undefined)

const AppProvider = ({children}) => {
    const { t } = useTranslation();
    const {userName, accessDenied, balance, user} = useAuth()
    const [config, setConfig] = useState({})
    const [section, setSection] = useState(null)
    const [newBalance, setBalance] = useState(balance)
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [contextMenu, setContextMenu] = useState([])
    const [contextLinks, setContextLinks] = useState([])
    const [exportFiles, setExportFiles] = useState({})
    const [visibleLeadsColumns, setVisibleLeadsColumns] = useState([])
    const [availableSources, setAvailableSources] = useState([])
    const [availableSMSTemplates, setAvailableSMSTemplates] = useState([])

    const [lookupDone, setLookupDone] = useState(false)
    const [lookupPlay, setLookupPlay] = useState(false)
    const [lookupInfo, setLookupInfo] = useState({count: 0, is_paid: false})
    const [isRequestDemoModalOpen, setRequestDemoModalOpen] = useState(false);

    const handleOpenRequestDemoModal = () => {
        setRequestDemoModalOpen(true);
      };

    const [warnings, setWarnings] = useState({})

    useEffect(() => setBalance(balance), [balance])

    const mainMenu = useMemo(() => {
        if (!user) return []

        const isOperator = user.isTeamOperator();

        return user ? [
            {label: t('main menu.leads'), link: '/leads', icon: IdCard},
            {label: t('main menu.sources'), link: '/sources', icon: IcoData, hide: isOperator},
            {label: t('main menu.audiences'), link: '/', icon: Dashboard, hide: isOperator},
            {label: t('main menu.campaigns'), link: '/campaigns', icon: Camp, hide: isOperator},
            {label: t('main menu.templates'), link: '/sms-templates', icon: Templates, hide: isOperator},
            {label: t('Alpha Names.Alpha Name'), link: '/alpha-names', icon: Templates, hide: isOperator},
            {label: t('main menu.lookup management'), link: '/lookup-management', icon: UserCheckIcon, hide: isOperator},
            {
                label: t('main menu.request demo'),
                action: handleOpenRequestDemoModal,
                icon: DemoIcon,
                hide: isOperator,
              }
            // {label: 'Analytics', icon: Chart, link: '/analytics'},
            // {label: t('main menu.settings'), icon: SettingsIcon, link: '/settings', hide: isOperator}
        ] : []
    }, [t, user])

    const configRequest = useQuery(
        ['generalConfig'],
        () => getConfig(),
        {keepPreviousData: true, enabled: !!user && !accessDenied}
    )

    useEffect(() => {
        if (configRequest.isSuccess) {
            setConfig(configRequest.data)
        }
    }, [configRequest.isSuccess, configRequest.data])

    const availableSourcesRequest = useQuery(
        ['availableSources'],
        () => getSourcesWithLists(),
    {keepPreviousData: true, enabled: !!user && !accessDenied}
    )


    useEffect(() => {
        if (availableSourcesRequest.isSuccess) {
            setAvailableSources(availableSourcesRequest.data.data)
        }
    }, [
        availableSourcesRequest.isSuccess, availableSourcesRequest.data
    ])

    const lookupStatusRequest = useQuery(
        'lookupStatus',
        () => lookupStatus(),
        {keepPreviousData: true, enabled: !!user}
    )

    useEffect(() => {
        if (lookupStatusRequest.isSuccess) {
            setLookupPlay(lookupStatusRequest.data['in_progress'])

            // lookupStatusRequest.data['is_paid'] = true
            // lookupStatusRequest.data['available_account_balance'] = {telnyx: false}

            setLookupInfo({
                is_paid: lookupStatusRequest.data['is_paid'],
                count: lookupStatusRequest.data['looked_up_count'],
                time_left: lookupStatusRequest.data['time_left']
            })


            setLookupInfo({
                is_paid: lookupStatusRequest.data['is_paid'],
                count: lookupStatusRequest.data['looked_up_count'],
                time_left: lookupStatusRequest.data['time_left']
            })

            if (lookupStatusRequest.data['is_paid']) {
                if (lookupStatusRequest.data['available_account_balance']) {
                    const balances = lookupStatusRequest.data['available_account_balance']
                    if (!balances['telnyx']) {
                        setWarnings(w => ({...w, lookup: true}))
                    }
                }
            }
        }

    }, [
        lookupStatusRequest.isSuccess,
        lookupStatusRequest.data,
        setLookupPlay,
        setLookupInfo
    ])

    const smsTemplatesRequest = useQuery(
        ['smsTemplates'],
        () => getSMSTemplates(),
        { keepPreviousData: true, enabled: !!user },
    );

    useEffect(() => {
        if (smsTemplatesRequest.isSuccess) {
            setAvailableSMSTemplates(smsTemplatesRequest.data.data)
        }
    }, [
        smsTemplatesRequest.isSuccess, smsTemplatesRequest.data
    ])

    const addExportFile = useCallback(data => {
        setExportFiles(files => {
            const newFiles = {...files}
            newFiles[data['segment_id']] = data
            return newFiles
        })
    }, [])

    const removeExportFile = useCallback(segmentId => {
        setExportFiles(files => {
            const newFiles = {...files}
            delete newFiles[segmentId]
            return newFiles
        })
    }, [])

    return (
        <AppStateContext.Provider value={{
            breadcrumbs,
            contextMenu,
            contextLinks,
            mainMenu,
            section,
            visibleLeadsColumns,
            exportFiles,
            config,
            balance: newBalance,
            configIsLoading: configRequest.isLoading || !userName,
            availableSMSTemplates,
            availableSources,
            lookupDone,
            lookupInfo,
            lookupPlay,
            warnings,
            isRequestDemoModalOpen,
        }}>
            <AppDispatchContext.Provider value={{
                setBreadcrumbs,
                setContextMenu,
                setContextLinks,
                setSection,
                setVisibleLeadsColumns,
                addExportFile,
                removeExportFile,
                setLookupDone,
                setLookupInfo,
                setBalance,
                setLookupPlay,
                setWarnings,
                setRequestDemoModalOpen,
            }}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

const useAppState = () => {
    const context = React.useContext(AppStateContext)
    if (context === undefined) {
        throw new Error('useAppState must be used within a AppProvider')
    }
    return context
}

const useAppDispatch = () => {
    const context = React.useContext(AppDispatchContext)
    if (context === undefined) {
        throw new Error('useAppDispatch must be used within a AppProvider')
    }
    return context
}

export {
    AppProvider,
    useAppState,
    useAppDispatch
}
