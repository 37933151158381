import React from 'react';
import SubscriptionListItem from './SubscriptionListItem';

const PlanList = ({items}) => {

    return (
        <div style={{marginBottom: '20px'}}>
            {
                items && items.map(item => {
                    return (
                        <SubscriptionListItem
                            key={item.slug}
                            item={item}
                        />
                    )
                })
            }
        </div>
    );
};

export default PlanList;
