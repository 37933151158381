import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { getImports } from '../api'
import { useAppDispatch } from '../Contexts/AppContext'
import {toServerDatetimeFormat} from '../Utils/dates'
import { useHistory } from 'react-router-dom'
import ListTable from '../components/ListTable/ListTable'
import {useTranslation} from "react-i18next";

const ImportHistory = () => {
    const { t } = useTranslation();
    const [list, setList] = useState([])
    const [pagination, setPagination] = useState(null)
    const [search, setSearch] = useState()
    const [sorting, setSorting] = useState([{key: 'created_at', direction: 'desc'}])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(15)
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const history = useHistory()
    const {setContextMenu} = useAppDispatch()
    const tableColumns = useMemo(() => {
        return [
            {
                Header: t('Import History.Import Date'),
                accessor: 'created_at',
                Cell: ({value}) => toServerDatetimeFormat(value)
            },
            {
                Header: t('Import History.Filename'),
                accessor: 'name',
            },
            {
                Header: t('Import History.Total Processed'),
                accessor: 'leads_processed',
            },
            {
                Header: t('Import History.Unique'),
                accessor: 'leads_total',
            },
            {
                Header: t('Import History.Duplicates'),
                accessor: 'duplicates_resolved_count',
            },
            {
                Header: t('Import History.Failed'),
                accessor: 'leads_failed',
            },
            {
                Header: t('Import History.Ignored Phones'),
                accessor: 'ignored_phones_count',
            },
            {
                Header: t('Import History.Invalid Phones'),
                accessor: 'invalid_format_phones_count',
            },
        ]

    }, [t])

    const tableValues = useMemo(() => {
        return list
    }, [list])

    const tableSorting = useMemo(() => {
        if (!sorting) return null;

        return sorting.map(item => ({
            id: item.key, desc: item.direction === 'desc'
        }))
    }, [sorting])

    useEffect(() => {
        setContextMenu([])
    }, [setContextMenu])

    useEffect(() => {
        setBreadcrumbs([
            {label: t('Import History.Settings'), link: '/settings'},
            {label: t('Import History.Import History')}
        ])
        setSection('settings')
    }, [setBreadcrumbs, setSection, t])

    const {isLoading, isFetching, isSuccess, data} = useQuery(
        ['importsList', page, limit, sorting, search],
        () => getImports(page, limit, sorting, search),
        {
            keepPreviousData: true,
        }
    )

    useEffect(() => {
        if (isSuccess) {
            setList(data.data)
            setPagination(data.meta)
        }
    }, [
        isSuccess, data
    ])

    const handleItemClick = item => {
        history.push(`/settings/import-history/${item.id}`)
    }

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])

    const handleSearchChange = useCallback((str) => {
        setPage(1)
        setSearch(str)
    }, [setPage, setSearch])



    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={tableValues}
                loading={isLoading}
                fetching={isFetching}
                isSelectionColumn={false}
                // onSelectedRows={setSelectedRows}
                onRowClick={handleItemClick}
                onSort={setSorting}
                emptyDataText={t('Import History.No Files Found')}
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                onSearch={handleSearchChange}
                initSort={tableSorting}
            />
        </div>
    )
}

export default ImportHistory
