import React, { useState } from 'react'
import Button from "@material-ui/core/Button";
import SingleSmsModal from "./modals/SingleSmsModal";
import {useTranslation} from "react-i18next";

const HeaderSendSms = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    return (
        <>
            <span style={{marginRight: 32}}>
                <Button color="primary" onClick={() => setOpen(true)} variant="contained" size="small">
                    {t("Auth.Send SMS")}
                </Button>
            </span>

            <SingleSmsModal open={open} handleClose={() => setOpen(false)} />
        </>
    )
}

export default HeaderSendSms

