import React, { useMemo } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {useTranslation} from "react-i18next";

const HCBar = ({campaignStat, currentFilter}) => {
    const { t } = useTranslation();
    const options = useMemo(() => {

        if (!campaignStat) return {}


        return {
            credits: {enabled: false},
            chart: {
                type: 'bar',
                backgroundColor: '#fbfbfb',
                height: 150
            },

            title: {
                text: t('Charts.Delivered Statistic')
            },
            xAxis: {
                visible: false,
            },
            yAxis: {
                visible: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                valueSuffix: ' messages',
                pointFormat: '{point.y}'
            },

            series: [{
                name: t('Charts.Delivered'),
                data: [['Delivered',campaignStat?.amount_status_delivered]],
                color: '#4D94F4'
            }, {
                name: t('Charts.Sent'),
                data: [['Sent',campaignStat?.amount_sent]],
                color: '#A9CFFC'
            }],

            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y}'
                    }
                }
            },
        }
    }, [campaignStat, t])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default HCBar
