import React from 'react';
import { Formik, Field, Form } from 'formik';
import {Button} from "@material-ui/core";
import * as Yup from 'yup';
import TextInputField, {TextInputFieldDisabled} from "../fields/Input.form";
import {useTranslation} from "react-i18next";

const FormChangePasswordSchema = (t) => Yup.object().shape({
    name: Yup
        .string()
        .required(t("New Profile.ProfilePage.Username is required")),
    phone: Yup.string(),
});

export default function FormPersonalInformation({ onSubmit, initialValues }) {
    const { t } = useTranslation();
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={FormChangePasswordSchema(t)}
            onSubmit={async (values, { resetForm }) => {
                await new Promise((r) => setTimeout(r, 500));
                onSubmit(values, resetForm);
            }}
        >
            <Form className="profileFormHolder">
                <div className="formHolder mb45">
                    <div className="cpSubTitle">{t("New Profile.ProfilePage.Personal Information")}</div>
                    <Field
                        type="text"
                        name="name"
                        id="name"
                        label={t("New Profile.ProfilePage.Username")}
                        component={TextInputField}
                    />
                    <Field
                        type="text"
                        name="phone"
                        id="phone"
                        label={t("New Profile.ProfilePage.Phone")}
                        component={TextInputField}
                    />
                    <Field
                        type="text"
                        name="email"
                        id="email"
                        label={t("Auth.Email")}
                        component={TextInputFieldDisabled}
                    />
                    <div className="btnHolder mt20">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            {t("New Profile.ProfilePage.Submit")}
                        </Button>
                    </div>
                </div>
            </Form>
        </Formik>
    );
}
