import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {useTranslation} from "react-i18next";

const TeamItemContextMenu = ({item, onToggleBlock}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose =  () => {
        setAnchorEl(null)
    }


    const toggleBlock = () => {
        setAnchorEl(null)
        onToggleBlock(item)
    }

    const disableMenu = !item;

    return (
        <div>
            <IconButton
                disabled={disableMenu}
                onClick={handleClick}
                size='small'>
                <MoreVertIcon/>
            </IconButton>
            {
                item && (
                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={toggleBlock}>
                            {item.blocked ? t('Team.Actions.Unblock') : t('Team.Actions.Block')}
                        </MenuItem>
                    </Menu>
                )
            }
        </div>
    )
}

export default TeamItemContextMenu
