export default class User {
    constructor(data) {
        this.id = data?.id;
        this.name = data?.name;
        this.email = data?.email;
        this.phone = data?.phone;
        this.role = data?.role;
        this.subscriptions = data?.subscriptions;
        this.balance = data?.balance;
        this.balance_currency = data?.balance_currency;
        this.free_sms = data?.free_sms;
        this.email_verified_at = data?.email_verified_at;
    }

    static teamRoles() {
        return ['team_owner', 'team_admin', 'team_operator'];
    }

    isTeamOperator() {
        return this.role === 'team_operator';
    }

    mainSubscription() {
        return this.subscriptions && this.subscriptions[0];
    }

    getFeatures() {
        const subscription = this.mainSubscription();
        return subscription && subscription.features ? subscription.features : [];
    }

    getFeaturesSlugs() {
        return this.getFeatures().map(f => f.slug);
    }

    getFeatureBySlug(featureSlug) {
        return this.getFeatures().find(f => f.slug === featureSlug);
    }

    canUseFeature(featureSlug) {
        const feature = this.getFeatureBySlug(featureSlug);
        if (!feature) return false;

        return !!feature?.can_use_feature;
    }

    canManageTeam() {
        return this.canUseFeature('manage-team')
    }

    canUseAlphaName() {
        return this.canUseFeature('alpha-name-limit')
    }
}
