import Api from './Api.service'
import { apiDataBaseUrl } from '../config'

class DataService {

    getUserInfo = async () => {
        try {
            return await Api.get(`${apiDataBaseUrl}/user`)
        } catch (e) {
            console.log('error while fetching user info')
        }
    }

    createSource = async (name) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/sources`, { name: name })
        } catch (e) {
            throw e
        }
    }

    getSourcesPage = async (pageNumber, rowCount, sortColumnId, sortOrder, filter) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/sources?page=${pageNumber + 1}&order[0]=${sortColumnId},${sortOrder}&limit=${rowCount}&search=${filter}`)
        } catch (e) {
            console.log('error while fetching sources')
        }
    }

    getSource = async (sourceId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/sources/${sourceId}`)
        } catch (e) {
            console.log('error while fetching source')
        }
    }

    createList = async (sourceId, name, status) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/sources/${sourceId}/lists`, { name: name, status: status })
        } catch (e) {
            throw e
        }
    }

    getListsBySourceId = async (sourceId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/sources/${sourceId}/lists?limit=100`)
        } catch (e) {
            console.log('error while fetching lists')
        }
    }

    getListsBySourcesIds = async (sourceIds) => {
        let idParams = sourceIds.map((s, index) => `filter[source_id][${index}]=${s}`).join('&')
        try {
            return await Api.get(`${apiDataBaseUrl}/lists?${idParams}`)
        } catch (e) {
            console.log('error while fetching lists')
        }
    }

    getSources = async () => {
        try {
            return await Api.get(`${apiDataBaseUrl}/sources?limit=100`)
        } catch (e) {
            console.log('error while fetching sources')
        }
    }

    getSourcesWithList = async () => {
        try {
            return await Api.get(`${apiDataBaseUrl}/all-sources-with-lists?limit=100`)
        } catch (e) {
            console.log('error while fetching sources')
        }
    }

    getLists = async (sourceId, pageNumber, rowCount, sortColumnId, sortOrder, filter) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/sources/${sourceId}/lists?page=${pageNumber + 1}&order[0]=${sortColumnId},${sortOrder}&limit=${rowCount}&search=${filter}`)
        } catch (e) {
            console.log('error while fetching lists')
        }
    }

    getLeads = async (listId, pageNumber, rowCount, sortColumnId, sortOrder, filter) => {
        try {
            if (filter)
                return await Api.get(`${apiDataBaseUrl}/lists/${listId}/leads?page=${pageNumber + 1}&order[0]=${sortColumnId},${sortOrder}&limit=${rowCount}&search[0]=${filter}`)
            else
                return await Api.get(`${apiDataBaseUrl}/lists/${listId}/leads?page=${pageNumber + 1}&order[0]=${sortColumnId},${sortOrder}&limit=${rowCount}`)
        } catch (e) {
            console.log('error while fetching leads')
        }
    }

    getSegmentLeads = async (segmentId, pageNumber, rowCount, sortColumnId, sortOrder, filter) => {
        try {
            if (filter)
                return await Api.get(`${apiDataBaseUrl}/segments/${segmentId}/leads?page=${pageNumber + 1}&order[0]=${sortColumnId},${sortOrder}&limit=${rowCount}&search[0]=${filter}`)
            else
                return await Api.get(`${apiDataBaseUrl}/segments/${segmentId}/leads?page=${pageNumber + 1}&order[0]=${sortColumnId},${sortOrder}&limit=${rowCount}`)
        } catch (e) {
            console.log('error while fetching leads')
        }
    }

    getList = async (listId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/sources/lists/${listId}`)
        } catch (e) {
            console.log('error while fetching list')
        }
    }

    getLead = async (leadId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/leads/${leadId}`)
        } catch (e) {
            console.log('error while fetching lead')
        }
    }

    getLeadSegments = async (leadId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/leads/${leadId}/segments`)
        } catch (e) {
            console.log('error while fetching lead segments')
        }
    }

    uploadFile = async (listId, file, blackList = false) => {
        try {
            let fd = new FormData();
            fd.append('file', file)
            fd.append('list_id[0]', listId)
            fd.append('black_list', blackList ? '1' : '0')
            return await Api.post(`${apiDataBaseUrl}/imports`, fd)
        } catch (e) {
            throw e
        }
    }

    getImportById = async (importId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/imports/${importId}`)
        } catch (e) {
            console.log('error while fetching import')
        }
    }

    createLeadsAttributes = async (attrList) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/lead-attributes`, { list: attrList })
        } catch (e) {
            throw e
        }
    }

    getLeadsAttributes = async () => {
        try {
            return await Api.get(`${apiDataBaseUrl}/lead-attributes`)
        } catch (e) {
            console.log('error while fetching leads attributes')
        }
    }

    syncImportAttributes = async (syncImportId, attrList, checkMobileStatus = false) => {
        try {
            const data = {list: attrList}
            if (checkMobileStatus) {
                data['check_mobile_status'] = true
            }
            return await Api.post(`${apiDataBaseUrl}/sync-import/${syncImportId}/import-attributes/multiple`, data)
        } catch (e) {
            throw e
        }
    }

    runImport = async (importId) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/imports/${importId}/dispatch`)
        } catch (e) {
            throw e
        }
    }

    syncLeads = async (syncImportId) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/sync-import/${syncImportId}`, { status: 'pending' })
        } catch (e) {
            throw e
        }
    }

    getSyncImportById = async (importId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/imports/${importId}/sync-import`)
        } catch (e) {
            console.log('error while fetching import')
        }
    }

    getActiveImports = async () => {
        try {
            return await Api.get(`${apiDataBaseUrl}/imports/active`)
        } catch (e) {
            console.log('error while fetching active imports')
        }
    }

    updateLeadValue = async (leadValueId, value) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/lead-values/${leadValueId}`, { value: value })
        } catch (e) {
            throw e
        }
    }

    updateEmailLeadValue = async (leadValueId, value) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/lead-emails/${leadValueId}`, { value: value })
        } catch (e) {
            throw e
        }
    }

    updatePhoneLeadValue = async (leadValueId, value) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/lead-phones/${leadValueId}`, { value: value })
        } catch (e) {
            throw e
        }
    }

    deleteLead = async (leadId) => {
        try {
            return await Api.delete(`${apiDataBaseUrl}/leads/${leadId}`)
        } catch (e) {
            throw Error('Removing lead error')
        }
    }

    deleteLeads = async (selectedLeadsIds) => {
        // let idParams = selectedLeadsIds.map((l, index) => `ids[${index}]=${l}`).join('&')
        // try {
        //     return await Api.delete(`${apiDataBaseUrl}/leads/batch?${idParams}`)
        // } catch (e) {
        //     throw Error('Removing leads error')
        // }
    }

    deleteLists = async (selectedListsIds) => {
        let idParams = selectedListsIds.map((l, index) => `ids[${index}]=${l}`).join('&')
        try {
            return await Api.delete(`${apiDataBaseUrl}/sources/lists/batch?${idParams}`)
        } catch (e) {
            throw Error('Removing lists error')
        }
    }

    deleteList = async (listId) => {
        try {
            return await Api.delete(`${apiDataBaseUrl}/sources/lists/${listId}`)
        } catch (e) {
            throw Error('Removing list error')
        }
    }

    deleteSources = async (selectedSourcesIds) => {
        let idParams = selectedSourcesIds.map((l, index) => `ids[${index}]=${l}`).join('&')
        try {
            return await Api.delete(`${apiDataBaseUrl}/sources/batch?${idParams}`)
        } catch (e) {
            throw Error('Removing sources error')
        }
    }

    deleteSource = async (sourceId) => {
        try {
            return await Api.delete(`${apiDataBaseUrl}/sources/${sourceId}`)
        } catch (e) {
            throw Error('Removing source error')
        }
    }

    updateSource = async (sourceId, sourceName, status) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/sources/${sourceId}`, { name: sourceName, status: status })
        } catch (e) {
            throw e
        }
    }

    updateList = async (listId, listName, status) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/sources/lists/${listId}`, { name: listName, status: status })
        } catch (e) {
            throw e
        }
    }

    getLeadLogs = async (leadId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/leads/${leadId}/log`)
        } catch (e) {
            console.log('error while fetching lead logs')
        }
    }

    getSegments = async (pageNumber, rowCount, sortColumnId, sortOrder, filter) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/segments?page=${pageNumber + 1}&order[0]=${sortColumnId},${sortOrder}&limit=${rowCount}&search=${filter}`)
        } catch (e) {
            console.log('error while fetching segments')
        }
    }

    createSegment = async (name, isUpdateDynamically) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments`, { name: name, ignore_new_leads: !isUpdateDynamically })
        } catch (e) {
            throw e
        }
    }

    createChildSegment = async (parentSegmentId, name, isUpdateDynamically) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments/${parentSegmentId}/segments`, { name: name, ignore_new_leads: !isUpdateDynamically })
        } catch (e) {
            throw e
        }
    }

    updateSegment = async (segmentId, segmentName, isUpdateDynamically) => {
        try {
            return await Api.put(`${apiDataBaseUrl}/segments/${segmentId}`, { name: segmentName, ignore_new_leads: !isUpdateDynamically })
        } catch (e) {
            throw e
        }
    }

    cloneSegment = async (segmentId, segmentName, isUpdateDynamically) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments/${segmentId}/clone`, { name: segmentName, ignore_new_leads: !isUpdateDynamically })
        } catch (e) {
            throw e
        }
    }

    getSegment = async (segmentId) => {
        try {
            return await Api.get(`${apiDataBaseUrl}/segments/${segmentId}`)
        } catch (e) {
            console.log('error while fetching segment')
        }
    }

    syncSourcesToSegment = async (segmentId, sourcesIds) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments/${segmentId}/sources/sync`, { sources: sourcesIds })
        } catch (e) {
            throw e
        }
    }

    syncListsToSegment = async (segmentId, listsIds) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments/${segmentId}/lists/sync`, { lists: listsIds })
        } catch (e) {
            throw e
        }
    }

    syncConditionsToSegment = async (segmentId, conditions, empties) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments/${segmentId}/conditions/sync`, { conditions: conditions, empties: empties })
        } catch (e) {
            throw e
        }
    }

    exportToCSV = async ({segmentId, attributes, name, search, order}) => {
        try {
            return await Api.post(`${apiDataBaseUrl}/segments/${segmentId}/leads/csv`, { attributes, name, search, order })
        } catch (e) {
            throw e
        }
    }

    deleteSegment = async (segmentId) => {
        try {
            return await Api.delete(`${apiDataBaseUrl}/segments/${segmentId}`)
        } catch (e) {
            throw Error('Removing segment error')
        }
    }

    deleteSegments = async (selectedSegmentsIds) => {
        let idParams = selectedSegmentsIds.map((l, index) => `ids[${index}]=${l}`).join('&')
        try {
            return await Api.delete(`${apiDataBaseUrl}/segments/batch?${idParams}`)
        } catch (e) {
            throw Error('Removing segments error')
        }
    }
}

export default new DataService()
