import React, { Fragment, useCallback, useEffect, useState, useMemo } from 'react'
import { useAppDispatch } from '../../Contexts/AppContext'
import DeleteIcon from '../../assets/img-new/delete.svg'
import EventEmitter from '../../Utils/EventEmitter'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { getLead, removeLeads, updateLeadsStatus } from '../../api'
import DeleteModal from '../../components/modals/DeleteModal'
import Settings from '../../assets/img/settings.svg'
import Sms from '../../assets/img/sms.svg'
import Plus from '../../assets/img/plus_circle.svg'
import { statusContextMenu, formatLeadAttributes } from '../../helpers'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";
import SingleSmsModal from '../../components/modals/SingleSmsModal'
import { useParams } from 'react-router-dom'
import SaveAttributeWithValueModal from '../../components/modals/SaveAttributeWithValueModal'
import { getAttributes } from '../../api';

const LeadPageActions = ({lead, loading, onDelete, }) => {
    const { t } = useTranslation();
    const {setContextMenu} = useAppDispatch()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const deleteLeadsMutation = useMutation(removeLeads)
    const updateLeadsStatusMutation = useMutation(updateLeadsStatus)
    const queryClient = useQueryClient()
    const [open, setOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [availableAttributes, setAvailableAttributes] = useState([])
    const [leadAttributes, setLeadAttributes] = useState([])
    const {leadId} = useParams()

    useEffect(() => {
      setContextMenu([
        {
          label: t("Auth.Send SMS"),
          action: () => setOpen(true),
          disabled: loading,
          icon: Sms,
          component: SingleSmsModal,
        },
        {
          label: t("Modals.Add Value"),
          icon: Plus,
          action: () => setShowModal(true),
          disabled: loading,
          component: SaveAttributeWithValueModal,
        },
        {
          label: t("Sources.Remove Lead"),
          icon: DeleteIcon,
          action: () => EventEmitter.emit("LeadDelete"),
          disabled: loading,
        },
        {
          label: updateLeadsStatusMutation.isLoading
            ? t("Sources.Updating")
            : t("Sources.Set status"),
          icon: Settings,
          disabled: updateLeadsStatusMutation.isLoading || !lead || loading,
          items: statusContextMenu().filter(
            (item) => lead && item.status !== lead.status
          ),
        },
      ])
    }, [setContextMenu, t, loading, lead, updateLeadsStatusMutation.isLoading])

      const leadRequest = useQuery(
        ['leadView', leadId],
        () => getLead(leadId),
        {keepPreviousData: true}
    )
    
      useEffect(() => {
        if (leadRequest.isSuccess) {
            const leadModel = leadRequest.data.data
            if (leadModel.lead_values) {
                setLeadAttributes(formatLeadAttributes(leadModel.lead_values))
            }
        }

    }, [leadRequest.isSuccess, leadRequest.data]) 
    
    const visibleAttributes = useMemo(() => {
      const leadAttributesNames = leadAttributes.map(a => a.name);
      return availableAttributes.filter(attr => !leadAttributesNames.includes(attr.name))
  }, [
      leadAttributes, availableAttributes
  ])

  const attributesRequest = useQuery(
      ['attributes'],
      () => getAttributes(),
      {keepPreviousData: true}
  )

  useEffect(() => {
      if (attributesRequest.isSuccess) setAvailableAttributes(attributesRequest.data.data)
  },[attributesRequest.isSuccess, attributesRequest.data])
    

    const updateItemsStatus = useCallback(async status => {
        if (!lead) return
        const response = await updateLeadsStatusMutation.mutateAsync({ids: [lead.id], status})
        const {info, errors} = response
        if (info && info.length) {
            info.forEach(message => AlertToastr.showAlert(message))
        }
        if (errors && errors.length) {
            errors.forEach(message => AlertToastr.showErrorAlert(message))
        }
        queryClient.invalidateQueries('leadView').then()
    }, [queryClient, updateLeadsStatusMutation, lead])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('LeadDelete', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = (status) => updateItemsStatus(status)

        const listener = EventEmitter.addListener('LeadsBulkChangeStatus', onEvent)
        return () => { listener.remove() }
    }, [updateItemsStatus])

    const deleteItems = async () => {
        setShowDeleteModal(false)
        if (lead) {
            await deleteLeadsMutation.mutateAsync([lead.id])
            queryClient.invalidateQueries('leadsList').then(() => {
                if (onDelete) onDelete()
            })
        }
        
    }

    const currentLeadPhone = lead?.lead_values[0].value ?? ''

    return (
        <Fragment>
            <SingleSmsModal open={open} handleClose={() => setOpen(false)} phoneNumber={currentLeadPhone}/>
            <SaveAttributeWithValueModal
                leadId={leadId}
                attributes={visibleAttributes}
                open={showModal}
                handleClose={() => {
                    setShowModal(false);
                }}
            />

            <DeleteModal
                loading={deleteLeadsMutation.isLoading}
                open={showDeleteModal}
                handleDelete={deleteItems}
                handleClose={() => setShowDeleteModal(false)}
                instance={'the lead'}
            />
        </Fragment>
    )
}

export default LeadPageActions
