import React, { Fragment, useState, useEffect } from "react";
import { Dialog, TextField, Button } from "@material-ui/core";
import { useAuth } from "../../Contexts/AuthProvider";
import { useTranslation } from "react-i18next";
import AlertToastr from "../../Utils/alert";

const RequestDemoModal = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  const [values, setValues] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    setValues({
      name: user?.name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      message: "",
    });
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("source", "App");
    formData.append("name", values.name || user.name || "");
    formData.append("email", values.email || user.email || "");
    formData.append("phone", values.phone || user.phone || "");
    formData.append("message", values.message || user.message || "");

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://formspree.io/f/xdoyener");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        setValues({});
        // this.setState({ status: "SUCCESS" });
      } else {
        // this.setState({ status: "ERROR" });
      }
    };
    xhr.send(formData);
    onClose();
    AlertToastr.showAlert(t("Auth.Success Demo"))
  };

  const handleChange = (event) => {
    event.persist();
    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };


  return (
    <Fragment>
      <Dialog open={isOpen} onClose={onClose} className="modal">
        <div className="modalBody">
          <div className="modalTitleHolder">
            <h2 className="mb15">{t("main menu.request demo")}</h2>
          </div>
          <div className="modalField">
            <TextField
              label={t("Modals.Name")}
              defaultValue={values.name}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              fullWidth
              name="name"
            />
          </div>
          <div className="modalField">
            <TextField
              label={t("Modals.Email")}
              defaultValue={values.email}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              fullWidth
              name="email"
            />
          </div>
          <div className="modalField mb15">
            <TextField
              label={t("Modals.Phone Number")}
              defaultValue={values.phone}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              fullWidth
              name="phone"
            />
          </div>
          <div className="modalField mb15">
            <TextField
              label={t("Modals.Message")}
              variant="outlined"
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              name="message"
            />
          </div>
          <div className="btnHolder hasPadTop twoBtn">
            <div className="part">
              <Button
                variant="outlined"
                color="primary"
                className="secondaryColor requestModalBtn"
                fullWidth
                onClick={onClose}
              >
                {t("Modals.Cancel")}
              </Button>
            </div>
            <div className="part">
              <Button
                variant="contained"
                color="primary"
                className="primaryColor requestModalBtn"
                fullWidth
                type="submit"
                onClick={handleSubmit}
              >
                {t("Modals.Confirm")}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default RequestDemoModal;
