import React, { Fragment, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import { setLead, setLeads, setLeadColumns, setList, setSource } from '../../Store/modules/sources/actions'
import Checkbox from '@material-ui/core/Checkbox'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import SearchInput from '../../components/SearchInput'
import TableEmptyListMessage from '../../components/TableEmptyListMessage'
import ListPagination from '../../components/ListPagination'
import ListLoader from '../../components/loaders/ListLoader'
import { getHtmlSortClass } from '../../helpers'
import {useTranslation} from "react-i18next";

const LeadsTableComponent = ({
                                 data,
                                 limit,
                                 setPage,
                                 setLimit,
                                 isSuccess,
                                 search,
                                 setSearch,
                                 leadsValues,
                                 handleSelectedIdChange,
                                 columns,
                                 setColumns,
                                 orderableColumnIds,
                                 leadSelected,
                                 sorting,
                                 setSorting,
                                 isLoading,
                                 isFetching
                             }) => {
    const [anchorImportEl, setAnchorImportEl] = useState(null)
    const isColumnPopoverOpen = Boolean(anchorImportEl)
    const { t } = useTranslation();
    const handleColumnsChange = (column, checked) => {
        const newColumns = [...columns]
        newColumns.find(col => col.id === column.id).selected = checked
        setColumns(newColumns)
    }

    const resetColumns = () => {
        const newColumns = columns.map((column) => {
            return {...column, selected: true}
        })
        setColumns(newColumns)
    }

    const getClassNamesFor = (name) => {
        if (!sorting) return
        if (name === 'status')
            return 'text-right'
        return sorting.key === name ? getHtmlSortClass(sorting.direction, isFetching) : ''
    }

    const requestSort = (key) => {
        if (!orderableColumnIds.includes(key))
            return
        let direction = 'asc'
        if (sorting && sorting.key === key && sorting.direction === 'asc') {
            direction = 'desc'
        }
        setSorting({key, direction})
    }

    const renderSearchBlock = () => {
        return (
            <div className="searchHolder">
                <div className="inputHolder">
                    <SearchInput
                        search={search}
                        onChange={s => setSearch(s)}
                    />
                </div>
            </div>
        )
    }

    const renderTableHeader = () => {
        return (
            <thead>
            {
                columns && (
                    <tr>
                        <th className='checkboxHolder'>
                            {/* <Checkbox
                                        color="primary"
                                        size='small'
                                    /> */}
                        </th>
                        {columns.map((column) => {
                            if (column.selected)
                                return (
                                    <th
                                        onClick={() => requestSort(column.id)}
                                        className={`${orderableColumnIds.includes(column.id) ? 'clickable' : ''} ${getClassNamesFor(column.id)}`}
                                        key={column.id}>
                                        {column.name}
                                    </th>
                                )
                            return (null)
                        })}
                    </tr>
                )
            }
            </thead>
        )
    }

    const renderEmptyList = () => {
        return <TableEmptyListMessage>{t('Sources.No Leads Found')}</TableEmptyListMessage>
    }

    const renderListData = () => {
        return (
            <Fragment>
                {leadsValues && leadsValues.map((leadValues) => {
                    return <tr key={leadValues.id}>
                        <td className='checkboxHolder'>
                            <Checkbox
                                checked={leadValues.selected}
                                onChange={(e) => handleSelectedIdChange(leadValues.id, e.target.checked)}
                                color="primary"
                                size='small'
                            />
                        </td>
                        {leadValues.leadValues.map((leadValue) => {
                            if (leadValue.id !== 'status')
                                return (
                                    <td style={{whiteSpace: 'nowrap'}} onClick={() => leadSelected(leadValues)}
                                        key={`${leadValues.id}-${leadValue.id}`}>{leadValue.value}</td>
                                )
                            else if (leadValue.value === 'active')
                                return (
                                    <td onClick={() => leadSelected(leadValues)} key={leadValue.id}
                                        className='text-right'>
                                        <div className="chipLike success">
                                            <span className="chipLikeLabel">{t("Sources.Active")}</span>
                                        </div>
                                    </td>
                                )
                            else
                                return (
                                    <td onClick={() => leadSelected(leadValues)} key={leadValue.id}
                                        className='text-right'>
                                        <div className="chipLike warning">
                                            <span className="chipLikeLabel">{t("Sources.Inactive")}</span>
                                        </div>
                                    </td>
                                )
                        })}
                    </tr>
                })}
            </Fragment>
        )
    }

    const renderList = () => {
        if (!isSuccess) return null

        const {meta} = data

        if (meta) {
            if (meta['total'] === 0) {
                return renderEmptyList()
            }
            if (meta['total'] > 0) {
                return renderListData()
            }
        }
        return null
    }

    const renderPagination = () => {
        if (!isSuccess) return null

        const {meta} = data

        if (meta && meta['total'] > 0) {
            return (
                <ListPagination
                    limit={limit}
                    pagination={meta}
                    onChange={setPage}
                    onLimitChange={(l) => {
                        setPage(1)
                        setLimit(l)
                    }}
                />
            )
        }
        return null
    }

    const renderColumnsSelector = () => {
        return (
            <>
                <Button
                    endIcon={<ArrowDropDownIcon/>}
                    onClick={(e) => setAnchorImportEl(e.currentTarget)}
                >
                    {t('Columns')}
                </Button>
                <Popover
                    open={isColumnPopoverOpen}
                    anchorEl={anchorImportEl}
                    onClose={() => setAnchorImportEl(null)}
                    className='importPopover'
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <div className="importPopoverContent">
                        <div className="ipTitleWrapper">
                            <div className="title">{t("Sources.Columns")}</div>
                            <div onClick={() => resetColumns()}
                                 className="linkColorNormal text-semi-bold text-mute clickable">{t("Sources.Reset")}
                            </div>
                        </div>
                        <div className="fileProgressHolder">
                            {
                                columns && columns.map(column => {
                                    return (
                                        <div key={column.id}>
                                            <label>
                                                <Checkbox name={column.name} checked={column.selected}
                                                          disabled={!(column.type === 'string')} color='primary'
                                                          onChange={(e) => {
                                                              handleColumnsChange(column, e.target.checked)
                                                          }}/>
                                                {column.name}
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Popover>
            </>
        )
    }

    return (
        <div className="pageTableBlock">
            <div className="searchHolder hasFilter">
                {renderSearchBlock()}
                <div className="userMenuHolder">
                    {renderColumnsSelector()}
                </div>
            </div>
            <div className="tableHolder hasCheckboxes rowClickable">
                <table>
                    {renderTableHeader()}
                    <tbody>
                    {isLoading && <ListLoader/>}
                    {renderList()}
                    </tbody>
                </table>
                {renderPagination()}
            </div>
        </div>
    )
}

const mapStateToProps = ({sources}) => ({
    leads: sources.leads,
    list: sources.list,
    runDataUpdate: sources.runDataUpdate,
    leadColumns: sources.leadColumns,
    source: sources.source
})

const mapDispatchToProps = {
    setLead,
    setLeads,
    setLeadColumns,
    setList,
    setSource
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadsTableComponent)
