import moment from 'moment'
import Moment from 'react-moment'
import React from 'react'

const MomentWithLocale = ({children, ...props}) => {
    return <Moment {...props} >{children}</Moment>
}

export const toLocalFormat = datetime => datetime ? moment.utc(datetime).local().format('MM-DD-YYYY hh:mm A') : null
export const toServerFormat = datetime => datetime ? moment.utc(datetime).format('MM-DD-YYYY hh:mm A') : null
export const toServerDateFormat = datetime => datetime ? moment.utc(datetime).local().format('YYYY-MM-DD') : null
export const toServerDatetimeFormat = datetime => datetime ? moment(datetime).format('YYYY-MM-DD HH:mm:ss') : null
export const toUTCDatetimeFormat = datetime => moment(datetime).local().utc().format('YYYY-MM-DD HH:mm:ss')
export const itemListDateTimeFormat = datetime => moment.utc(datetime).local().format('MMM DD YYYY - hh:mm A')
export const itemListDateFormat = datetime => moment.utc(datetime).local().format('MMM DD YYYY')
export const hoursMinutes = datetime => moment.utc(datetime).local().format('HH:mm')
export const liveHumanize = datetime => <MomentWithLocale fromNow
                                                          interval={3000}>{moment.utc(datetime).local()}</MomentWithLocale>
export const chatDay = datetime => {
    return moment.utc(datetime).local().format('DD MMMM')
}
export const timestampToCalendar = time => moment.unix(time).calendar()
export const timestampToOverviewFormat = time => moment.unix(time).local().format('MMMM DD YYYY, HH:mm')
