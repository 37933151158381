import React, { useEffect } from 'react'
import SelectRow from './filters/SelectRow'
import {campaignSmsStatusOptions, campaignEngagementOptions} from '../config'
import { useCampaignDispatch, useCampaignState } from '../Contexts/CampaignContext'

const CampaignStatisticFilters = ({onChange}) => {

    // const [smsStatuses, setSmsStatuses] = useState([])
    // const [engagements, setEngagements] = useState([])
    const {smsStatuses, engagements} = useCampaignState()
    const {setSmsStatuses, setEngagements} = useCampaignDispatch()

    useEffect(() => {
        if (onChange) {
            const filters = {}
            if (smsStatuses.length) {
                filters['sms_status'] = smsStatuses
            }
            if (engagements.length) {
                filters['engagement'] = engagements
            }
            onChange(filters)
        }
    }, [smsStatuses, engagements, onChange])

    return (
        <div className="segmentsFilterHolder">
            {/*<p className="mb30">Showing leads matching the following:</p>*/}
            <div className="segmentsFilter">
                <SelectRow
                    items={campaignSmsStatusOptions}
                    selectedItems={smsStatuses}
                    onChange={setSmsStatuses}
                    entityLabel={'Sms Status'}
                />
                <SelectRow
                    items={campaignEngagementOptions}
                    selectedItems={engagements}
                    onChange={setEngagements}
                    entityLabel={'Engagement'}
                />
            </div>
        </div>
    )
}

export default CampaignStatisticFilters
