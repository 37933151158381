import React, { Fragment, useEffect, useMemo, useState, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getShortUrlDomainsWithAccess, storeCampaignStep, updateCampaignStep } from '../../api'
import AlertToastr from '../../Utils/alert'
import MenuItem from '@material-ui/core/MenuItem'
import ReactHookFormSelect from '../form/ReactHookFormSelect'
import { useCampaignState } from '../../Contexts/CampaignContext'
import Chip from '@material-ui/core/Chip'
import { useTranslation } from "react-i18next";
import SmsCounter from "./SmsCounter";
import SelectSmsTemplateButton from "../SelectSmsTemplateButton";
import SaveSmsTemplateButton from "../SaveSmsTemplateButton";
import {useAuth} from "../../Contexts/AuthProvider";

const SaveCampaignStepModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        defaultValues,
        campaign,
        disableDelay
    } = props
    const { user } = useAuth()
    const { availableAttributes } = useCampaignState()
    const messageRef = useRef(null);
    // const campaignStepTypes = useMemo(() => config['campaign_step_type_list'] || [], [config])
    // const shortUrlsOptions = useMemo(() => config['short_urls'] || [], [config])
    const [shortUrlsOptions, setShortUrlsOptions] = useState([])
    const queryClient = useQueryClient()
    // const [isDelay, setIsDelay] = useState(false)
    const [messageCursorPosition, setMessageCursorPosition] = useState()
    const availableAttributesWithLink = useMemo(() => {
        // let attrs = []
        let attrs = [{ id: 'link', name: 'Link', slug: 'link' }]
        if (availableAttributes) {
            attrs = [...availableAttributes, ...attrs]
            return attrs
        }
    }, [availableAttributes])

    const {
        control,
        register,
        handleSubmit,
        watch,
        reset,
        setValue
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues
    })

    const typeValue = watch('type')
    const messageValue = watch('message')
    const messageHasLink = useMemo(() => {
        if (!messageValue) return false;
        return messageValue.includes('{{link}}')
    }, [messageValue])

    const storeCampaignStepMutation = useMutation(storeCampaignStep)
    const updateCampaignStepMutation = useMutation(updateCampaignStep)

    const shortUrlDomainsRequestWithAccess = useQuery(
        ['shortUrlDomainsWithAccess'],
        () => getShortUrlDomainsWithAccess(),
        { keepPreviousData: true }
    )

    useEffect(() => {
        if (shortUrlDomainsRequestWithAccess.isSuccess) {
            setShortUrlsOptions(shortUrlDomainsRequestWithAccess.data.map(item => ({
                key: item.id,
                label: item.url
            })))
        }
    }, [shortUrlDomainsRequestWithAccess.isSuccess, shortUrlDomainsRequestWithAccess.data])

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues, reset])

    useEffect(() => {
        if (storeCampaignStepMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignStepMutation.error)
        }
    }, [storeCampaignStepMutation.error])

    useEffect(() => {
        if (updateCampaignStepMutation.error) {
            AlertToastr.showErrorResponseAlert(updateCampaignStepMutation.error)
        }
    }, [updateCampaignStepMutation.error])

    const onSubmit = handleSubmit(async data => {
        data.type = "sms"
        if (defaultValues) {
            await updateCampaignStepMutation.mutateAsync({ id: defaultValues.id, ...data })
            handleClose()
            queryClient.invalidateQueries('campaignStepsView').then()
        } else {
            try {
                await storeCampaignStepMutation.mutateAsync({ id: campaign.id, ...data, delay: data.delay || 0 })
                handleClose()
                queryClient.invalidateQueries('campaignStepsView').then()
            } catch (e) {
                console.log(e)
            }
        }

    })

    const setCursorPosition = e => {
        setMessageCursorPosition(e.target.selectionStart)
    }

    const insertToken = token => {
        let value = watch('message')

        let caretPosition
        try {
            const messageInput = messageRef.current.children[1].children[0]
            caretPosition = messageInput.selectionDirection ==='backward' ? messageInput.selectionStart : messageInput.selectionEnd;
            if (caretPosition === undefined) {
                caretPosition = value.length
            }
        } catch (e) {
            caretPosition = messageCursorPosition === undefined ? value.length : messageCursorPosition
        }


        let textToInsert = `{{${token}}}`
        let cursorPosition = messageCursorPosition === undefined ? value.length : messageCursorPosition
        let textBeforeCursorPosition = value.substring(0, caretPosition)
        let textAfterCursorPosition = value.substring(caretPosition, value.length)
        let newValue = textBeforeCursorPosition + textToInsert + textAfterCursorPosition
        setValue('message', newValue, { shouldDirty: false })
        setMessageCursorPosition(undefined)

        const messageInput = messageRef.current.children[1].children[0]
        const nextCursorPosition = cursorPosition + textToInsert.length + 1
        messageInput.focus()
        // setTimeout(() => {
        //     messageInput.setSelectionRange(nextCursorPosition, nextCursorPosition)
        // }, 100);

    }

    const title = defaultValues ? t('Modals.Update Step') : t('Modals.Add Step')

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        {/* <ReactHookFormSelect
                            disabled={configIsLoading}
                            variant="outlined"
                            margin="normal"
                            id="type-select"
                            name="type"
                            label={t("Modals.Step Type")}
                            control={control}
                            defaultValue=''
                        >
                            {campaignStepTypes.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect> */}

                        {
                            typeValue !== 'lookup' && (
                                <Fragment>
                                    <ReactHookFormSelect
                                        variant="outlined"
                                        margin="normal"
                                        id="type-select"
                                        name="short_url_domain_id"
                                        label={t("Modals.Domain")}
                                        control={control}
                                        defaultValue={(shortUrlsOptions[0] && shortUrlsOptions[0].key) || ''}
                                    >
                                        {shortUrlsOptions.map((option) => (
                                            <MenuItem key={option.key} value={option.key}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormSelect>
                                    <Controller
                                        name="message"
                                        control={control}
                                        defaultValue=''
                                        render={({ onChange, onBlur, value }) => (
                                            <div style={{
                                                position: 'relative'
                                            }}>
                                                <TextField
                                                    ref={messageRef}
                                                    onChange={(e) => {
                                                        setCursorPosition(e)
                                                        onChange(e)
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    label={t("Modals.Message")}
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    margin='normal'
                                                    onClick={setCursorPosition}
                                                />

                                                <SmsCounter text={value} />

                                                <div style={{
                                                    opacity: .5,
                                                    position: 'absolute',
                                                    bottom: 25,
                                                    right: 0
                                                }}>
                                                    <SelectSmsTemplateButton
                                                        onSelect={(messageValue) => setValue('message', messageValue)}
                                                    />

                                                    {user && !user.isTeamOperator() &&
                                                        <SaveSmsTemplateButton
                                                            message={messageValue}
                                                            disabled={!messageValue || messageValue.length < 5}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {availableAttributesWithLink.map((attr) => {
                                        return (
                                            <Chip
                                                key={attr.id}
                                                label={attr.name}
                                                onClick={() => insertToken(attr.slug || attr.name)}
                                                className='tagLikeChip'
                                            />
                                        )
                                    })}
                                    {messageHasLink && (
                                        <TextField
                                            inputRef={register}
                                            name="redirect_to"
                                            label={t("Modals.Redirect Link")}
                                            variant="outlined"
                                            margin='normal'
                                        />
                                    )}
                                    {/* <TextField
                                        inputRef={register}
                                        name="limit_cap"
                                        label={t("Campaign Step Item.Limit Cap")}
                                        variant="outlined"
                                        margin='normal'
                                    // type='number'
                                    /> */}
                                </Fragment>

                            )
                        }

                        {/*{*/}
                        {/*    !disableDelay && (*/}
                        {/*        <div className="align-center wrapper content-justified mb15">*/}
                        {/*            Set delay after previous step (min)*/}
                        {/*            <IOSSwitch*/}
                        {/*                color="primary"*/}
                        {/*                checked={!!isDelay}*/}
                        {/*                onChange={() => {*/}
                        {/*                    setIsDelay(v => !v)*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    )*/}
                        {/*}*/}
                        {
                            !disableDelay && (
                                <TextField
                                    inputRef={register}
                                    name="delay"
                                    label={t("Modals.Delay")}
                                    variant="outlined"
                                    margin='normal'
                                    defaultValue='0'
                                    InputProps={{
                                        endAdornment: 'min'
                                    }}
                                />
                            )
                        }

                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={storeCampaignStepMutation.isLoading || updateCampaignStepMutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={storeCampaignStepMutation.isLoading || updateCampaignStepMutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => {
                                    }}
                                >
                                    {title}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveCampaignStepModal
