import React from "react";
import TextField from "@material-ui/core/TextField";

const TextInputField = ({
                            field,
                            label,
                            type = 'text',
                            className = "mt0",
                            autoFocus = false,
                            form: {touched, errors}
                        }) => {
    return (
        <>
            <TextField
                type={type}
                variant="outlined"
                margin='normal'
                label={label}
                autoFocus={autoFocus}
                className={className}
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]}
                {...field}
            />
        </>
    );
};

export const TextInputFieldDisabled = ({
                                         field,
                                         label,
                                         type = 'text',
                                         className = "mt0",
                                         autoFocus = false,
                                         form: {touched, errors}
                                     }) => {
    return (
        <>
            <TextField
                type={type}
                variant="outlined"
                margin='normal'
                label={label}
                disabled={true}
                autoFocus={autoFocus}
                className={className}
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]}
                {...field}
            />
        </>
    );
};

export default TextInputField;
