import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {useTranslation} from "react-i18next";

const AttributeItemContextMenu = ({item, onEdit, onDelete}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose =  () => {
        setAnchorEl(null)
    }

    const handleEdit = () => {
        setAnchorEl(null)
        onEdit()
    }

    const handleDelete = () => {
        setAnchorEl(null)
        onDelete()
    }

    const disableMenu = !item || ['email', 'phone'].includes(item.type)

    return (
        <div>
            <IconButton
                disabled={disableMenu}
                onClick={handleClick}
                size='small'>
                <MoreVertIcon/>
            </IconButton>
            {
                item && (
                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={handleEdit}>{t('Attribute Item Context Menu.Edit Attribute')}</MenuItem>
                        <MenuItem onClick={handleDelete}>{t('Attribute Item Context Menu.Remove Attribute')}</MenuItem>
                    </Menu>
                )
            }
        </div>
    )
}

export default AttributeItemContextMenu
