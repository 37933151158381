import React, { useState } from 'react';
import ChangePassword from './ChangePassword';
import PersonalInformation from './PersonalInformation';
import SubscriptionPlan from './SubscriptionPlan';
import ProfileDetails from './ProfileDetails';
import { PrivateRoute } from '../../components/routes';
import { Link, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Transactions from './Transactions';
import Team from './team/Team';
import { useAuth } from '../../Contexts/AuthProvider';

const Profile = () => {
    const { t } = useTranslation();
    const { path, url } = useRouteMatch();
    const { user } = useAuth();
    const [activeLink, setActiveLink] = useState(url);
  
    const renderLink = (to, title, description, hide = false) => {
      if (hide) return null;
      const isActive = activeLink === to;
      const className = isActive ? 'active' : '';
      return (
        <li className="pnItem">
          <Link
            to={to}
            className={`pnItemContent ${className}`}
            onClick={() => setActiveLink(to)}
          >
            <span className="title">{title}</span>
            <span className="description">{description}</span>
          </Link>
        </li>
      );
    };

    const hide = user?.isTeamOperator() || !user?.canManageTeam()

    return (
        <div className="profilePageHolder">
            <div className="navPart">
                <div className="navPartContent">
                    <ProfileDetails />
                    <div className="profileNavHolder">
                        <ul className="profileNav">
                            {renderLink(url, t('New Profile.Profile'), t('New Profile.Change Personal Information'))}
                            {renderLink(`${url}/security`, t('New Profile.Security'), t('New Profile.Change Password'))}
                            {renderLink(`${url}/subscriptions`, t('New Profile.Subscriptions'), t('New Profile.Billing, Plans, Limits'))}
                            {renderLink(`${url}/transactions`, t('New Profile.Billing'), t('New Profile.Billing Flow'))}
                            {renderLink(`${url}/team`, t('Team.Team'), t('Team.Team Management'), hide)}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="contentPart">
                <div className="contentPartContent">
                    <Switch>
                        <PrivateRoute path={`${path}`} component={PersonalInformation} exact />
                        <PrivateRoute path={`${path}/security`} component={ChangePassword} />
                        <PrivateRoute path={`${path}/subscriptions`} component={SubscriptionPlan} />
                        <PrivateRoute path={`${path}/transactions`} component={Transactions} />
                        <PrivateRoute path={`${path}/team`} component={Team} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default Profile;

