import React from 'react'
import { NavLink } from "react-router-dom";
import { useAuth } from '../../Contexts/AuthProvider'
import {useTranslation} from "react-i18next";
import Logo from '../../assets/img/leads_logo.svg'

const AuthLayout = ({ children }) => {
    const { isLoggedIn } = useAuth()
    const { t } = useTranslation();
    return (
        <div className="authLayout">
            <div className="authContent">
                <div className="logoHolderAuth">
                    <img height="50" src={Logo} alt=""/>  
                </div>
                {/*<div className="imgPart">*/}
                {/*    <img src={AuthImg} alt=""/>*/}
                {/*</div>*/}
                <div className="formPart">
                    {!isLoggedIn && <ul className="authFormNav mb30">
                        <li className="authFormNavItem"><NavLink exact to="/signin" activeClassName='active'>{t('Auth Layout.Login')}</NavLink></li>
                        <li className="authFormNavItem"><NavLink to="/register" activeClassName='active'>{t('Auth Layout.Register')}</NavLink></li>
                    </ul>}
                    {children}
                </div>
            </div>
        </div>

    )
}

export default AuthLayout
