import React, { Fragment } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import {useTranslation} from "react-i18next";

const DeleteModal = ({open, handleDelete, handleClose, instance, loading = false}) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{t('Modals.Do you want to delete') + ` ${instance}?`}</h2>
                    </div>
                    <div className="btnHolder hasPadTop twoBtn">
                        <div className="part">
                            <Button
                                disabled={loading}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleClose}
                            >
                                {t('Modals.Cancel')}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleDelete}
                            >
                                {t('Modals.Delete')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default DeleteModal
