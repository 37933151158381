import React, { useMemo } from 'react'
import InlineLoader from './loaders/InlineLoader'
import {useTranslation} from "react-i18next";

const CampaignStatisticsHeader = ({ loading, onFilter, campaignStat }) => {
    const { t } = useTranslation();
    const items = useMemo(() => {
        return [
            {
                label: t('Campaign Statistics Header.Clicked'),
                count: campaignStat?.amount_eng,
                action: () => onFilter('amount_eng')
            },
            // {
            //     label: t('Campaign Statistics Header.Clicked last 90 days'),
            //     count: campaignStat?.amount_eng_last,
            //     action: () => onFilter('amount_eng_last')
            // },
            // {
            //     label: t('Campaign Statistics Header.Unsubscribe'),
            //     count: campaignStat?.amount_unsubscribe,
            //     action: () => onFilter('amount_unsubscribe')
            // },
            {
                label: t('Campaign Statistics Header.Non-clicked'),
                count: campaignStat?.amount_not_eng,
                action: () => onFilter('amount_not_eng')
            },
        ]
    }, [campaignStat, onFilter, t])

    return (
        <div className="pageHeadingBlockWrapper">
            {
                items.map(item => (
                    <div className="part" key={item.label}>
                        <div className="partContent cursor-pointer" onClick={item.action}>
                            <h2 className="mb10">{item.label}</h2>
                            <div>{loading ? <InlineLoader /> : item.count}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default CampaignStatisticsHeader
