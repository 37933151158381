import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useAppState } from '../../Contexts/AppContext'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
    getSMSGateways,
    storeCampaign,
    updateCampaign,
} from '../../api'
import AlertToastr from '../../Utils/alert'
import MenuItem from '@material-ui/core/MenuItem'
import ReactHookFormSelect from '../form/ReactHookFormSelect'
import { useHistory } from 'react-router-dom'
// import InputAdornment from '@material-ui/core/InputAdornment'
import moment from 'moment-timezone'
import { toServerDatetimeFormat } from '../../Utils/dates'
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uk from 'date-fns/locale/uk';
registerLocale('uk', uk)

const SaveCampaignModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        defaultValues,
    } = props
    const { i18n } = useTranslation();
    const currentLng = i18n.language
    const { config, configIsLoading } = useAppState()
    // const campaignTypes = useMemo(() => config['campaign_type_list'] || [], [config])
    // const campaignFrequencies = useMemo(() => config['campaign_frequency_period_list'] || [], [config])
    const [userGateways, setUserGateways] = useState([])
    const queryClient = useQueryClient()
    const history = useHistory()
    const timezones = useMemo(() => config['timezones_js'] || [], [config])
    const activeUserGateways = useMemo(() => {
        return userGateways.filter(item => item.active && !item.allow_lookup);
    }, [userGateways])
    const [currentUserGatewayId, setCurrentUserGatewayId] = useState(null)
    const [availableAlphaNames, setAvailableAlphaNames] = useState([])


    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues
    })

    const [startDate, setStartDate] = useState(defaultValues && Boolean(defaultValues.started_at) ? moment(defaultValues.started_at, "YYYY-MM-DD HH:mm").toDate() : moment().add(5, 'm').toDate());
    const watchCurrentUserGatewayId = watch('setting_gateway_message_id')

    useEffect(() => {
        setCurrentUserGatewayId(watchCurrentUserGatewayId)
    }, [watchCurrentUserGatewayId])

    const currentUserGatewayModel = useMemo(() => {
        if (activeUserGateways && activeUserGateways.length === 1) {
            const model = activeUserGateways[0]
            if (model && Array.isArray(model.from)) {
                setAvailableAlphaNames(model.from)
            } else {
                setAvailableAlphaNames([])
            }
            return model || null
        }

        if (!activeUserGateways || !activeUserGateways.length || !Number.isInteger(currentUserGatewayId)) {
            setAvailableAlphaNames([])
            return null
        }

        const model = activeUserGateways.find(item => item.id === currentUserGatewayId)
        if (model && Array.isArray(model.from)) {
            setAvailableAlphaNames(model.from)
        } else {
            setAvailableAlphaNames([])
        }

        return model || null
    }, [activeUserGateways, currentUserGatewayId])

    const nameRef = useRef()

    const userGatewaysRequest = useQuery(
        ['smsGateways'],
        () => getSMSGateways(),
        { keepPreviousData: true }
    )

    useEffect(() => {
        if (userGatewaysRequest.isSuccess) {
            const { data } = userGatewaysRequest.data
            setUserGateways(data)
        }
    }, [userGatewaysRequest.isSuccess, userGatewaysRequest.data])

    const storeCampaignMutation = useMutation(storeCampaign)
    const updateCampaignMutation = useMutation(updateCampaign)

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues, reset])

    useEffect(() => {
        if (storeCampaignMutation.error) {
            AlertToastr.showErrorResponseAlert(storeCampaignMutation.error)
        }
    }, [storeCampaignMutation.error])

    useEffect(() => {
        if (updateCampaignMutation.error) {
            AlertToastr.showErrorResponseAlert(updateCampaignMutation.error)
        }
    }, [updateCampaignMutation.error])

    const onSubmit = handleSubmit(async data => {
        data.frequency_count = 60
        data.frequency_period = 'min'
        if (activeUserGateways && activeUserGateways.length === 1)
            data.setting_gateway_message_id = activeUserGateways[0].id
        if (availableAlphaNames && availableAlphaNames.length === 1)
            data.alpha_name = availableAlphaNames[0].key

        const userGateway = userGateways.find(item => item.id === data.setting_gateway_message_id)
        const userGatewayType = userGateway?.type

        if (defaultValues) {
            if (data.started_at) {
                data.started_at = toServerDatetimeFormat(data.started_at)
            }
            const updateResponse = await updateCampaignMutation.mutateAsync({
                id: defaultValues.id,
                ...data,
                gateway: userGatewayType
            })
            reset(updateResponse.data)
            handleClose()
            queryClient.invalidateQueries('campaignView').then()
            queryClient.invalidateQueries('campaignsList').then()
        } else {

            if (data.started_at) {
                data.started_at = toServerDatetimeFormat(data.started_at)
            }
            try {
                const response = await storeCampaignMutation.mutateAsync({
                    ...data,
                    gateway: userGatewayType
                })
                history.push(`/campaigns/${response.data.id}`)
                queryClient.invalidateQueries('campaignsList').then()
            } catch (e) {
                console.log(e)
            }
        }


    })

    const title = defaultValues ? t('Modals.Update Campaign') : t('Modals.Create Campaign')
    const titleBtn = defaultValues ? t('Modals.Update Campaign Btn') : t('Modals.Create Campaign Btn')
    // const gateway = watch('gateway')

    return (
        <Fragment>
            <Dialog
                open={open}
                keepMounted={false}
                onClose={handleClose}
                onEnter={() => {
                    if(!Boolean(defaultValues && Boolean(defaultValues.started_at))) {
                        setStartDate(moment().add(5, 'm').toDate())
                    }
                }}
                className='modal'
                onEntered={() => { nameRef.current.focus() }}
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <TextField
                            inputRef={(e) => {
                                register(e)
                                nameRef.current = e
                            }}
                            name="name"
                            label={t("Modals.Campaign Name")}
                            variant="outlined"
                            margin='normal'
                        />
                        {/* <ReactHookFormSelect
                            disabled={configIsLoading}
                            variant="outlined"
                            margin="normal"
                            id="type-select"
                            name="type"
                            label={t("Modals.Campaign Type")}
                            control={control}
                            defaultValue={campaignTypes[0] && campaignTypes[0].key}
                        >
                            {campaignTypes.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect> */}

                        {activeUserGateways.length > 1 && <ReactHookFormSelect
                            disabled={userGatewaysRequest.isLoading}
                            variant="outlined"
                            margin="normal"
                            id="gateway-select"
                            name="setting_gateway_message_id"
                            label={t("Modals.Gateway")}
                            control={control}
                            defaultValue={userGateways.filter(item => item.active && !item.allow_lookup)[0] &&
                                userGateways.filter(item => item.active && !item.allow_lookup)[0].id}
                        >
                            {activeUserGateways.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>}

                        {currentUserGatewayModel && Array.isArray(availableAlphaNames) && availableAlphaNames.length > 1 &&
                            <ReactHookFormSelect
                                key={`alpha_name_sms_${availableAlphaNames.length}`}
                                disabled={configIsLoading}
                                variant="outlined"
                                margin="normal"
                                id="alpha_name"
                                name="alpha_name"
                                label={t("Modals.Alpha Name")}
                                control={control}
                                defaultValue={availableAlphaNames.length > 0 ? availableAlphaNames[0].key : ''}
                            >
                                {availableAlphaNames.map((option) => {
                                    return (
                                        <MenuItem key={option.key} value={option.key}>
                                            {option.label}
                                        </MenuItem>
                                    )
                                })}
                            </ReactHookFormSelect>
                        }

                        {/* <TextField
                            inputRef={register}
                            name="frequency_count"
                            label={t("Modals.Frequency")}
                            variant="outlined"
                            margin='normal'
                            className='freqAdornment'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ReactHookFormSelect
                                            disabled={configIsLoading}
                                            variant="standard"
                                            margin="normal"
                                            id="frequency-select"
                                            name="frequency_period"
                                            control={control}
                                            defaultValue={campaignFrequencies[0] && campaignFrequencies[0].key}
                                        >
                                            {campaignFrequencies.map((option) => (
                                                <MenuItem key={option.key} value={option.key}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </ReactHookFormSelect>
                                    </InputAdornment>
                                ),
                            }}
                        /> */}
                        <Controller
                            name="started_at"
                            control={control}
                            defaultValue={moment().add(5, 'm').toDate()}
                            render={({ onChange, onBlur, value, field }) => {
                                return (
                                    <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal MuiFormControl-fullWidth">
                                            <label
                                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled"
                                                data-shrink="true">{t("Modals.Stated At")}</label>

                                            <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={date => {
                                                        setStartDate(date)
                                                        onChange(moment(date).format("YYYY-MM-DD HH:mm"))
                                                    }}
                                                    timeInputLabel={t("Campaigns.Time")}
                                                    timeFormat="HH:mm"
                                                    dateFormat="yyyy-MM-dd HH:mm"
                                                    timeCaption="Time"
                                                    timeIntervals={5}
                                                    locale={currentLng === "ua" ? "uk" : "en"}
                                                    popperPlacement="top-end"
                                                    showPopperArrow={false}
                                                    popperModifiers={[
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [60, -200],
                                                            },
                                                        },
                                                        {
                                                            name: "preventOverflow",
                                                            options: {
                                                                rootBoundary: "viewport", // document or viewport
                                                                // tether: false,
                                                                // altAxis: true,
                                                            },
                                                        },
                                                    ]}
                                                    showTimeSelect
                                                    className="inputDatePicker"
                                                />
                                            </div>
                                        </div>
                                )
                            }}
                        />
                        <ReactHookFormSelect
                            disabled={configIsLoading}
                            variant="outlined"
                            margin="normal"
                            id="type-select"
                            name="started_at_tz"
                            label={t("Modals.Timezone")}
                            control={control}
                            defaultValue={moment.tz.guess()}
                        >
                            {timezones.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={storeCampaignMutation.isLoading || updateCampaignMutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={storeCampaignMutation.isLoading || updateCampaignMutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => { }}
                                >
                                    {titleBtn}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveCampaignModal
