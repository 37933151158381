import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import SourceHeader from '../../components/SourceHeader'
import { useQueries, useQuery } from 'react-query'
import {
    getListAggregations,
    getSource,
    getSourceLists
} from '../../api'
import SearchInput from '../../components/SearchInput'
import LoadingValue from '../../components/LoadingValue'
import { useAppDispatch } from '../../Contexts/AppContext'
import SourcePageActions from './SourcePageActions'
import BasicTable from '../../components/BasicTable'
import TableProgressCell from '../../components/TableProgressCell'
import {useTranslation} from "react-i18next";

const SourcePage = () => {
    const { t } = useTranslation();
    const {sourceId} = useParams()
    const [source, setSource] = useState(null)
    const [list, setList] = useState([])
    const [pagination, setPagination] = useState(null)
    const [search, setSearch] = useState('')
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const history = useHistory()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [selectedRows, setSelectedRows] = useState([])
    const tableColumns = useMemo(() => {
        return [
            {
                Header: t('Sources.List Name'),
                accessor: 'name'
            },
            {
                Header: t('Sources.Total Leads'),
                accessor: 'total_leads',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.Today'),
                accessor: 'total_today',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.Last 30 Days'),
                accessor: 'total_last_30_days',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.Duplicate'),
                accessor: 'duplicate',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.Blacklisted'),
                accessor: 'total_blacklisted_leads',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.Flagged'),
                accessor: 'total_flagged_leads',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Sources.VerificationLookup'),
                accessor: 'percentage_verified',
                disableSortBy: true,
                Cell: ({value, row}) => {
                    const {percentage_verified, percentage_lookup} = row.original
                    const progress = <TableProgressCell value={percentage_verified} value2={percentage_lookup} />
                    return <LoadingValue value={value} renderedValue={progress} />
                }
            },
            // {
            //     Header: 'Status',
            //     accessor: 'status',
            //     disableSortBy: true,
            //     className: 'text-right',
            //     Cell: ({value}) => {
            //         const chipClass = value ? 'chipLike success' : 'chipLike blackList'
            //         const chipLabel = value ? 'Active' : 'Inactive'
            //         return (
            //             <div className={chipClass}>
            //                 <span className="chipLikeLabel">{chipLabel}</span>
            //             </div>
            //         )
            //     }
            // }
        ]
    }, [t])

    const {isSuccess: isSourceSuccess, data: sourceData, isLoading: isSourceLoading} = useQuery(
        ['sourceView', sourceId],
        () => getSource(sourceId),
        {keepPreviousData: true}
    )

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ['sourceLists', sourceId, page, limit, search, sorting],
        () => getSourceLists(sourceId, page, limit, search, sorting),
        {keepPreviousData: true, refetchOnMount: false}
    )

    const listAggregationsRequests = useQueries(list.map(item => {
        return {
            queryKey: ['listAggregations', item.id],
            queryFn: () => getListAggregations(item.id),
            cacheTime: 1000 * 300,
            refetchOnMount: false,
        }
    }))

    useEffect(() => {
        listAggregationsRequests.forEach(req => {
            if (req.isSuccess) {
                setList(prev => {
                    const updatedItem = req.data.data;
                    const newList = [...prev]
                    const index = newList.findIndex(s => s.id === updatedItem.id)
                    if (index !== -1) {
                        newList[index] = {
                            ...newList[index],
                            total_leads: updatedItem.total_leads,
                            total_today: updatedItem.total_today,
                            total_last_30_days: updatedItem.total_last_30_days,
                            duplicate: updatedItem.duplicate,
                            total_blacklisted_leads: updatedItem.total_blacklisted_leads,
                            total_flagged_leads: updatedItem.total_flagged_leads,
                            percentage_lookup: updatedItem.percentage_lookup,
                            percentage_verified: updatedItem.percentage_verified,
                            // leads_count: updatedSegment.leads_count,
                            // duplicates_count: updatedSegment.duplicates_count,
                        }
                    }
                    return newList
                })
            }
        })
    }, [listAggregationsRequests])

    useEffect(() => {
        setBreadcrumbs([
            {label: t('Sources.Sources'), link: '/sources'},
            {label: source && source.name, loading: isSourceLoading}
        ])
        setSection('sources')
    }, [setBreadcrumbs, source, setSection, isSourceLoading, t])

    useEffect(() => {
        if (isSourceSuccess) {
            setSource(sourceData.data)
        }
    }, [isSourceSuccess, sourceData, setSource])

    useEffect(() => {
        if (isSuccess) {
            setList(data.data)
            setPagination(data.meta)
        }
    }, [isSuccess, data])

    useEffect(() => {
        setPage(1)
    }, [search])

    const handleItemClick = item => {
        history.push(`/sources/${source.id}/${item.id}`)
    }

    const renderSearchBlock = () => {
        return (
            <div className="searchHolder">
                <div className="inputHolder">
                    <SearchInput
                        search={search}
                        onChange={s => setSearch(s)}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="pageHolder">
            <div className="pageHeadingBlock mb15">
                <SourceHeader
                    source={source}
                    loading={isSourceLoading}
                />
            </div>
            <div className="pageTableBlock">
                {renderSearchBlock()}
                <div className="tableHolder hasCheckboxes rowClickable">
                    <BasicTable
                        columns={tableColumns}
                        data={list}
                        loading={isLoading}
                        fetching={isFetching}
                        isSelectionColumn={true}
                        onSelectedRows={setSelectedRows}
                        onRowClick={handleItemClick}
                        onSort={setSorting}
                        emptyDataText={t('Sources.No Lists Found')}
                        pagination={pagination}
                        pageLimit={limit}
                        onPageChange={setPage}
                        onLimitChange={(l) => {
                            setPage(1)
                            setLimit(l)
                        }}
                    />
                </div>
            </div>
            <SourcePageActions
                source={source}
                loading={isSourceLoading}
                selectedRows={selectedRows}
                bulkDone={() => {
                    setList(prev => [...prev])
                }}
            />
        </div>
    )
}

export default SourcePage
