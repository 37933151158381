import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../Contexts/AppContext';
import Invitations from './Invitations';
import PlusCircle from '../../../assets/img/plus_circle.svg';
import EventEmitter from '../../../Utils/EventEmitter';
import TeamMembers from './TeamMembers';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Redirect, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../../../components/routes';
import InviteCreateAction from './InviteCreateAction';
import { useAuth } from '../../../Contexts/AuthProvider';

const Team = () => {
    const { t } = useTranslation();
    const { path, url } = useRouteMatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const { user } = useAuth();

    const { setBreadcrumbs, setSection, setContextMenu } = useAppDispatch();

    const tabNameToIndex = useMemo(() => {
        return {
            0: `${url}`,
            1: `${url}/invites`,
        };
    }, [url]);

    const indexToTabName = useMemo(() => {
        return {
            [`${path}`]: 0,
            [`${path}/invites`]: 1,
        };
    }, [path]);

    const [value, setValue] = React.useState(() => {
        return indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0;
    });

    useEffect(() => {
        setContextMenu([
            {
                label: t('Team.Invite User'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('InviteCreate'),
            },
        ]);
    }, [setContextMenu, t]);

    useEffect(() => {
        setBreadcrumbs([
            { label: t('Team.Team') },
        ]);
        setSection('settings');
    }, [setBreadcrumbs, setSection, t]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push(tabNameToIndex[newValue]);
    };


    if (!user?.canManageTeam()) return <Redirect to='/profile' />
    if (user?.isTeamOperator()) return <Redirect to='/profile' />

    return (
        <>
            <div className="contentPartTitleHolder">
                <div className="contentPartTitle">{t('Team.Team')}</div>
            </div>

            <div className="pageHolder">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    className="pageTabs"
                >
                    <Tab label={t('Team.Team')} disableRipple />
                    <Tab label={t('Team.Invites')} disableRipple />
                </Tabs>
                <Switch>
                    <PrivateRoute path={`${path}`} component={TeamMembers} exact />
                    <PrivateRoute path={`${path}/invites`} component={Invitations} />
                </Switch>
                <InviteCreateAction
                    onSuccessSave={() => {
                        if (!pathname.includes('invites')) {
                            setValue(1);
                            history.push(`${path}/invites`);
                        }
                    }}
                />
            </div>
        </>
    );
};

export default Team;
