import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getSegment, getSegmentLeadsCount } from '../api'

const SegmentContext = React.createContext(undefined)

const SegmentProvider = ({children}) => {

    const {segmentId} = useParams()
    const [segment, setSegment] = useState()
    const [leadsCount, setLeadsCount] = useState()

    const segmentRequest = useQuery(
        ['segmentView', segmentId],
        () => getSegment(segmentId),
        {keepPreviousData: true}
    )

    const segmentLeadsCountRequest = useQuery(
        ['segmentLeadsCount', segmentId],
        () => getSegmentLeadsCount(segmentId),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (segmentRequest.isSuccess) {
            setSegment(segmentRequest.data.data)
        }
    }, [
        segmentRequest.isSuccess,
        segmentRequest.data
    ])

    useEffect(() => {
        if (segmentLeadsCountRequest.isSuccess) {
            setLeadsCount(segmentLeadsCountRequest?.data?.data?.count)
        }
    }, [
        segmentLeadsCountRequest.isSuccess,
        segmentLeadsCountRequest.data
    ])

    return (
        <SegmentContext.Provider value={{
            segment,
            segmentRequest,
            leadsCount,
            setLeadsCount
        }}>
            {children}
        </SegmentContext.Provider>
    )
}

const useSegmentState = () => {
    const context = React.useContext(SegmentContext)
    if (context === undefined) {
        throw new Error('useSegmentState must be used within a SegmentProvider')
    }
    return context
}

export {
    SegmentProvider,
    useSegmentState
}
