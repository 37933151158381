import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import {useTranslation} from "react-i18next";

const SmallListPagination = props => {
    const { t } = useTranslation();
    const {
        pagination,
        limit,
        onChange,
        onLimitChange
    } = props

    const {current_page, total} = pagination

    return (
        <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={total}
            page={current_page-1}
            labelRowsPerPage={t('Rows per page')}
            SelectProps={{
                inputProps: { 'aria-label': t('Rows per page') },
                native: true,
            }}
            onChangePage={(event, page) => {
                onChange(page+1)
            }}
            rowsPerPage={limit}
            onChangeRowsPerPage={event => {
                onLimitChange(parseInt(event.target.value))
            }}
        />
    )
}

export default SmallListPagination
