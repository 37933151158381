import React, { Fragment, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useMutation, useQueryClient } from 'react-query'
import {
    storeSMSGateway,
    updateSMSGateway,
} from '../../api'
import AlertToastr from '../../Utils/alert'
import MenuItem from '@material-ui/core/MenuItem'
import ReactHookFormSelect from '../form/ReactHookFormSelect'
import IOSSwitch from '../IOSSwitch'
import {useTranslation} from "react-i18next";

const SaveSMSGatewayModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        defaultValues,
        gatewaysOptions = [],
        gatewaysAttributes = {}
    } = props

    const queryClient = useQueryClient()

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues,
    })

    const storeMutation = useMutation(storeSMSGateway)
    const updateMutation = useMutation(updateSMSGateway)

    const typeField = watch('type')

    useEffect(() => {
        if (defaultValues) return
        if (!gatewaysOptions || !gatewaysOptions.length) return
        if (formState.dirtyFields['name'] === undefined) {
            const typeModel = gatewaysOptions.find(item => item.key === typeField)
            if (typeModel) setValue('name', typeModel.label)
        }
    }, [formState.dirtyFields, typeField, gatewaysOptions, setValue, defaultValues])

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues, reset])

    useEffect(() => {
        if (storeMutation.error) {
            AlertToastr.showErrorResponseAlert(storeMutation.error)
        }
    }, [storeMutation.error])

    useEffect(() => {
        if (updateMutation.error) {
            AlertToastr.showErrorResponseAlert(updateMutation.error)
        }
    }, [updateMutation.error])

    const onSubmit = handleSubmit(async data => {
        if (defaultValues) {
            await updateMutation.mutateAsync({id: defaultValues.id, ...data})
            reset()
            handleClose()
            queryClient.invalidateQueries('smsGateways').then()
        } else {
            try {
                await storeMutation.mutateAsync(data)
                reset()
                handleClose()
                queryClient.invalidateQueries('smsGateways').then()
            } catch (e) {
                console.log(e)
            }
        }
    })

    const renderGatewayTextField = (key, label) => {
        if (!typeField) return null

        if (!gatewaysAttributes[typeField]) return null

        if (!Array.isArray(gatewaysAttributes[typeField])) return null

        if (!gatewaysAttributes[typeField].includes(key)) return null

        return (
            <Controller
                name={key}
                control={control}
                defaultValue=""
                render={({onChange, value}) => {
                    return (
                        <TextField
                            onChange={onChange}
                            value={value}
                            variant="outlined"
                            margin="normal"
                            label={label}
                        />
                    )
                }}
            />
        )
    }

    const title = defaultValues ? t('Modals.Edit SMS Gateway') : t('Modals.Add SMS Gateway')
    const buttonTitle = t('Modals.Save')

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <ReactHookFormSelect
                            variant="outlined"
                            margin="normal"
                            id="gateway-select"
                            name="type"
                            label={t("Modals.Gateway")}
                            control={control}
                            defaultValue=""
                        >
                            <MenuItem value="" disabled>
                                {t('Modals.Select Gateway')}
                            </MenuItem>
                            {gatewaysOptions.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        <Controller
                            name='name'
                            control={control}
                            defaultValue=""
                            render={({onChange, value}) => {
                                return (
                                    <TextField
                                        onChange={onChange}
                                        value={value}
                                        variant="outlined"
                                        margin="normal"
                                        label={t('Modals.Name')}
                                    />
                                )
                            }}
                        />
                        {renderGatewayTextField('api_key', t('Modals.API Key'))}
                        {renderGatewayTextField('sid', t('Modals.SID'))}
                        {renderGatewayTextField('token', t('Modals.Token'))}
                        {renderGatewayTextField('access_token', t('Modals.Access Token'))}
                        {renderGatewayTextField('user', t('Modals.User'))}
                        {renderGatewayTextField('password', t('Modals.Password'))}
                        {renderGatewayTextField('from_twilio', t('Modals.From'))}
                        {renderGatewayTextField('from_telnyx', t('Modals.From'))}
                        {renderGatewayTextField('messaging_profile_id', t('Modals.Messaging Profile ID'))}

                        <div className="align-center wrapper content-justified mt15">
                            <div style={{fontWeight: '600', fontSize: '14px'}}>{t('Modals.Is Active')}</div>
                            <Controller
                                name="active"
                                control={control}
                                defaultValue={true}
                                render={({onChange, value}) => (
                                    <IOSSwitch
                                        color="primary"
                                        checked={value}
                                        onChange={(e) => onChange(e.target.checked)}
                                    />
                                )}
                            />


                        </div>

                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={storeMutation.isLoading || updateMutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={storeMutation.isLoading || updateMutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => {
                                    }}
                                >
                                    {buttonTitle}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveSMSGatewayModal
