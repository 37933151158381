import React from "react";
import {useAuth} from "../../Contexts/AuthProvider";
import {useAppState} from "../../Contexts/AppContext";

const UserBalance = ({value = null}) => {
    const { user } = useAuth()
    const { balance } = useAppState()

    const amount = Boolean(value) ? value : balance
    const currency = user && user.balance_currency ? user.balance_currency : 'UAH'
    const currencyToSign = {UAH: '₴', USD: '$', EUR: '€'}
    const sign = currencyToSign.hasOwnProperty(currency) ? currencyToSign[currency] : ''

    return Boolean(user) && Boolean(amount) ? <>{amount} {sign}</> : null
}

export default UserBalance