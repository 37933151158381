import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from '../../Contexts/AppContext'
import EventEmitter from '../../Utils/EventEmitter'
import PlusCircle from '../../assets/img/plus_circle.svg'
import SaveCampaignModal from '../../components/modals/SaveCampaignModal'
import Delete from '../../assets/img-new/delete.svg'
import { useMutation, useQueryClient } from 'react-query'
import DeleteModal from '../../components/modals/DeleteModal'
import { removeCampaigns, updateCampaignsStatus } from '../../api'
import MomentUtils from '@date-io/moment'
import Settings from '../../assets/img/settings.svg'
import { campaignStatusContextMenu } from '../../helpers'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";

const CampaignsActions = ({selectedRows}) => {
    const { t } = useTranslation();
    const {setContextMenu} = useAppDispatch()
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const queryClient = useQueryClient()
    const campaignsRemoveRequest = useMutation(removeCampaigns)
    const updateCampaignsStatusMutation = useMutation(updateCampaignsStatus)


    useEffect(() => {
        if (updateCampaignsStatusMutation.error) {
            AlertToastr.showErrorResponseAlert(updateCampaignsStatusMutation.error)
        }
    }, [updateCampaignsStatusMutation.error])

    useEffect(() => {
        const items = [
            {
                label: t('Campaigns.Add New Campaign'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('CampaignCreate'),
            },
            {
                label: t('Campaigns.Delete Selection'),
                icon: Delete,
                disabled: !selectedRows.length,
                action: () => EventEmitter.emit('CampaignBulkDeletion')
            },
            {
                label: updateCampaignsStatusMutation.isLoading ? t('Campaigns.Updating') : t('Campaigns.Set status'),
                icon: Settings,
                disabled: !selectedRows.length || updateCampaignsStatusMutation.isLoading,
                items: campaignStatusContextMenu()
            }
        ]
        setContextMenu(items)
    }, [setContextMenu, selectedRows, updateCampaignsStatusMutation.isLoading, t])

    const updateItemsStatus = useCallback(async status => {
        const response = await updateCampaignsStatusMutation.mutateAsync({ids: selectedRows, status})
        const {info, errors} = response
        if (info && info.length) {
            info.forEach(message => AlertToastr.showAlert(message))
        }
        if (errors && errors.length) {
            errors.forEach(message => AlertToastr.showErrorAlert(message))
        }

        queryClient.invalidateQueries('campaignsList').then()
    }, [queryClient, selectedRows,updateCampaignsStatusMutation])

    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('CampaignCreate', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('CampaignBulkDeletion', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = (status) => updateItemsStatus(status)

        const listener = EventEmitter.addListener('CampaignsBulkChangeStatus', onEvent)
        return () => { listener.remove() }
    }, [updateItemsStatus])

    const deleteItems = async() => {
        setShowDeleteModal(false)
        await campaignsRemoveRequest.mutateAsync(selectedRows)
        await queryClient.invalidateQueries('campaignsList')
    }

    return (
        <Fragment>
            <SaveCampaignModal
                open={showSaveModal}
                handleClose={(event) => {
                    setShowSaveModal(false)
                }}
            />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteItems}
                handleClose={(e) => setShowDeleteModal(false)}
                instance='selected campaigns'/>
        </Fragment>
    )
}

export default CampaignsActions
