import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useApiCall from '../../Hooks/useApiCall';
import authEndpoints from '../../Endpoints/auth.endpoints';
import { useAuth } from '../../Contexts/AuthProvider';
import AlertToastr from '../../Utils/alert';
import PrivacyPolicy from '../../assets/files/Privacy_policy_08_22.pdf';
import Conditions from '../../assets/files/Terms_&_conditions_08_22.pdf';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getInviteInfo } from '../../api';

const InviteRegisterPage = () => {
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [request, register, loading] = useApiCall();
    const { login, isLoggedIn } = useAuth();
    const { token } = useParams();

    const inviteInfoRequest = useQuery(
        ['invite', token],
        () => getInviteInfo(token),
        { keepPreviousData: true, enabled: !!token, retry: false },
    );

    useEffect(() => {
        if (inviteInfoRequest.isSuccess) {
            setEmail(inviteInfoRequest.data.data.email);
        }
    }, [inviteInfoRequest.isSuccess, inviteInfoRequest.data]);

    const onRegisterClicked = (e) => {
        e.preventDefault();
        register({
            ...authEndpoints.register(),
            data: {
                name: name,
                email: email,
                password: password,
                password_confirmation: confirmPassword,
                token
            },
        });
    };

    useEffect(() => {
        if (inviteInfoRequest.error) {
            AlertToastr.showErrorResponseAlert(inviteInfoRequest.error);
        }
    }, [inviteInfoRequest.error]);

    useEffect(() => {
        if (request.error) {
            AlertToastr.showErrorAlert(request.error.message);
        }
    }, [request.error]);

    useEffect(() => {
        if (!request.response) return;
        login(request.response.data.access_token, true)
    }, [request.response, login]);

    const CheckboxLabel = () => {
        return (
            <span className="fontSize14">
                {`${t('Auth.By continuing, you’re agreeing to')} `}
                {i18n.language === 'en' && `${t('Auth.Leads')} `}
                <a href={Conditions} target="_blank" rel="noreferrer" className="linkPrimary">
                    {t('Auth.Terms and conditions')}
                </a>
                {` ${t('Auth.and')} `}
                <a href={PrivacyPolicy} target="_blank" rel="noreferrer" className="linkPrimary">
                    {t('Auth.Privacy Policy')}
                </a>
                {i18n.language !== 'en' && ` ${t('Auth.Leads')}`}
            </span>
        );
    };

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    const isLoading = loading || inviteInfoRequest.isLoading || !!inviteInfoRequest.error;

    return (
        <div className="formHolder">
            <p className="mb20">{t('Auth.For invite registration please')}</p>
            <form noValidate autoComplete="off">
                <TextField
                    id="name"
                    label={t('Auth.Full name')}
                    variant="outlined"
                    type="text"
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    disabled
                    id="email"
                    label={t('Auth.Email')}
                    variant="outlined"
                    type="email"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="username"
                />
                <TextField
                    // error
                    id="password"
                    label={t('Auth.Password')}
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    autoComplete="new-password"
                />
                <TextField
                    // error
                    id="confirmPassword"
                    label={t('Auth.Confirm password')}
                    variant="outlined"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    margin="normal"
                    autoComplete="new-password"
                />
                <CheckboxLabel />
                <div className="btnHolder mt20 mb15">
                    <Button
                        disabled={isLoading}
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        onClick={onRegisterClicked}
                    >
                        {t('Auth.Register')}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default InviteRegisterPage;
