import React, {useState}  from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import { useAsyncDebounce } from 'react-table'
import {useTranslation} from "react-i18next";

const SearchInput = ({search, onChange}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(search)

    const onSearchChange = useAsyncDebounce(value => {
        onChange(value || undefined)
    }, 500)

    return (
        <TextField
            value={value || ''}
            onChange={e => {
                setValue(e.target.value)
                onSearchChange(e.target.value)
            }}
            id="search"
            label={t('search')}
            type='search'
            variant="outlined"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default SearchInput
