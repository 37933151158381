import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@material-ui/core';
import { useAuth } from "../../Contexts/AuthProvider";
import ConfirmModal from '../../components/modals/ConfirmModal'

const PlanItemCard = ({ item, active = false, disabled = false, onSelect }) => {
    const { t } = useTranslation()
    const { subscriptions } = useAuth()
    const [loading, setLoading] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const selectPlan = () => {
        if (onSelect) {
            setLoading(true)
            onSelect(item).then(() => setLoading(false))
        }
    }

    const wrapperClass = active ? 'splItem active' : 'splItem'

    return (
        <li className={wrapperClass}>
            <div className="splItemContent">
                <div className="splTitleHolder">
                    <div className="titlePart">
                        <div className="spTitle">{t(`PlansCards.${item.name}`, { defaultValue: item.name })}</div>
                        {item.description && <div className="spSubTitle">{t(`PlansCards.${item.description}`, { defaultValue: item.description })}</div>}
                    </div>
                </div>
                <div className="splTitleHolder">
                    <div className="pricePart">
                        <div className="price">

                            {item.slug !== 'enterprise' ? (
                                <>{`${item.price} ${t(`PlansCards.${item.currency}`, { defaultValue: item.currency })}`}</>
                            ) : (
                                <p>{t('Subscriptions.Contact us')}</p>
                            )}

                        </div> {item.slug !== 'enterprise' && <>{t(`PlansCards.month`)}</>}
                    </div>
                </div>
                <table className="spInfoList">
                    <tbody>
                        {
                            item.features && item.features.length &&
                            item.features.map(feature => (
                                <tr key={feature.id}>
                                    <td>{t(`PlansCards.${feature.name}`, { defaultValue: feature.name })}</td>
                                    <td>{t(`PlansCards.${feature.value}`, { defaultValue: feature.value })}</td>
                                </tr>
                            ))
                        }
                    </tbody>

                </table>
                <div className="btnHolder">
                    {item.name === 'Enterprise' ? (
                        <></>
                    ) : (
                        <Button
                            onClick={e => {
                                if (Array.isArray(subscriptions) && subscriptions.length > 0) {
                                    setShowConfirmModal(true)
                                }
                                else {
                                    selectPlan()
                                }
                            }
                            }
                            disabled={disabled || item.name === 'Enterprise'}
                            className='btn-billing'
                            // color='primary'
                            variant='contained'
                            size="small"
                        >
                            {loading ? <CircularProgress size={18} color="inherit" /> :
                                (item.name === 'Enterprise' ? t('Subscriptions.Contact us') : t('Subscriptions.Subscribe'))}
                        </Button>
                    )}
                    {/*<a href="/" className="btnLike">{t("New Profile.SubscriptionPage.Cancel Subscription")}</a>*/}
                </div>
            </div>
            <ConfirmModal
                message={t('Modals.Do you really want to buy a new plan?')}
                open={showConfirmModal}
                handleClose={(e) => setShowConfirmModal(false)}
                handleConfirm={(e) => {
                    selectPlan()
                    setShowConfirmModal(false)
                }}
            />
        </li>
    );
};

export default PlanItemCard;
