import React, { Fragment, useMemo } from 'react'
import ButtonMenu from './ButtonMenu'
import Chip from '@material-ui/core/Chip'
import { TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {useTranslation} from "react-i18next";

const SegmentFilterItem = props => {
    const { t } = useTranslation();
    const {
        item,
        attributeOptions,
        operatorOptions,
        valueOptions,
        onSelectAttribute,
        onSelectOperator,
        onUpdateValues,
        onRemove,
        singleValue,
    } = props

    const attributeLabel = useMemo(() => {
        if (!item || !item.id) return t('Segment Filter.Select Attribute')

        return item.name || item.id
    }, [item, t])

    const operatorLabel = useMemo(() => {
        if (!item || !item.operator ) return t('Segment Filter.Select Operator')

        const selectedOption = operatorOptions.find(option => option.id === item.operator)

        return selectedOption ? selectedOption.name : item.operator
    }, [item, operatorOptions, t])

    const visibleValueOptions = useMemo(() => {
        if (!item || !valueOptions) return []

        const selected = item.values

        return valueOptions.items.filter(item => {
            return !selected.find(selectedItem => selectedItem === item.id)
        })
    }, [item, valueOptions])


    const valueLabelsMap = useMemo(() => {
        const map = {}

        if (!valueOptions || !valueOptions.items) return map

        valueOptions.items.forEach(item => {
            const {id, name} = item
            map[id] = name
        })
        return map
    }, [valueOptions])

    const isReady = useMemo(() => {
        if (item.id && ['empty', 'not_empty'].includes(item.operator)) {
            return true
        }
        return item.id && item.operator && item.values && item.values.length
    }, [item])

    const handleSelectAttribute = (attr) => {
        if (onSelectAttribute) onSelectAttribute(attr)
    }

    const handleSelectOperator = (operator) => {
        if (onSelectOperator) onSelectOperator(operator)
    }

    const handleAddValue = (event) => {
        let value = event.target.value.trim()

        if (
            ((event.type === 'keydown' && event.code === 'Enter')
            || event.type === 'blur')
            && value
        ) {
            addValue(value)
            event.target.value = ''
        }
    }

    const addValue = value => {

        const normalizedValue = typeof value === 'string' ? value : value.id

        const newItemValues = [...item.values]
        newItemValues.push(normalizedValue)
        onUpdateValues(newItemValues)
    }

    const handleRemoveValue = (index) => {
        const newItemValues = [...item.values]
        newItemValues.splice(index, 1)
        onUpdateValues(newItemValues)
    }

    const renderValues = () => {
        if (!item || !item.values.length) return null

        return (
            <Fragment>
                {
                    item.values.map((item, index) => {
                        let label = ''
                        let disabled = false

                        if (valueOptions && !valueOptions.items.length) {
                            label = '...'
                            disabled = true
                        } else {
                            label = valueLabelsMap && valueLabelsMap[item]
                                ? valueLabelsMap[item]
                                : item
                        }


                        return (
                            <Chip
                                disabled={disabled}
                                key={index}
                                label={label}
                                onDelete={() => handleRemoveValue(index)}
                            />
                        )
                    })
                }
            </Fragment>
        )
    }

    const renderInput = () => {

        if (
            !item
            || !item.operator
            || ['empty', 'not_empty'].includes(item.operator)
        ) return null

        return (
            <div className="input">
                <TextField
                    variant="outlined"
                    placeholder={t("Segment Filter.Enter values")}
                    margin="none"
                    onKeyDown={handleAddValue}
                    onBlur={handleAddValue}
                />
            </div>
        )
    }

    const renderValueOptions = () => {
        if (singleValue && item.values.length >= 1) return null

        return (
            <ButtonMenu
                disabled={!visibleValueOptions.length}
                onSelect={addValue}
                label={valueOptions.label}
                options={visibleValueOptions}
                icon={<AddCircleOutlineIcon />}
            />
        )
    }

    const renderRemoveButton = () => {
        return (
            <div className="remove">
                <IconButton
                    size='small'
                    onClick={onRemove}>
                    <ClearIcon />
                </IconButton>
            </div>
        )
    }

    return (
        <div className="filterRow">
            <div className="buttons">
                <ButtonMenu
                    required={!isReady}
                    label={attributeLabel}
                    options={attributeOptions}
                    onSelect={handleSelectAttribute}
                />
                <ButtonMenu
                    label={operatorLabel}
                    options={operatorOptions}
                    disabled={!item || !item.id}
                    onSelect={handleSelectOperator}
                />
            </div>
            {renderValues()}
            {valueOptions ? renderValueOptions() : renderInput()}
            {renderRemoveButton()}
        </div>
    )
}

export default SegmentFilterItem
