import React, { useEffect, useState } from 'react';
import { ClickAwayListener, TextField } from '@material-ui/core';
import { useMutation } from 'react-query';
import { updateLeadAttribute } from '../../api';
import AlertToastr from '../../Utils/alert';

const LeadAttributeEditValue = ({attr, onEditDone}) => {

    const [value, setValue] = useState(() => attr.value || '')

    const updateMutation = useMutation(updateLeadAttribute)

    useEffect(() => {
        if (updateMutation.error) {
            AlertToastr.showErrorResponseAlert(updateMutation.error)
        }
    }, [updateMutation.error])

    const handleKeyDown = async e => {
        if (e.key === 'Enter') {

            if (value !== attr.value) {
                try {
                    const response = await updateMutation.mutateAsync({
                        id: attr.id,
                        value,
                        type: attr.type
                    })

                    onEditDone(response.data)
                    return;

                } catch (e) {
                    console.log(e)
                    return;
                }
            }

            onEditDone()
        }
    }

    const fieldValue = updateMutation.isLoading ? value + ' (Saving...)' : value

    return (
        <ClickAwayListener onClickAway={onEditDone}>
            <TextField
                // defaultValue={attr.value}
                error={!!updateMutation.error}
                value={fieldValue}
                onChange={e => setValue(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={updateMutation.isLoading}
            />
        </ClickAwayListener>
    );
};

export default LeadAttributeEditValue;
