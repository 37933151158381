import React, { useMemo } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import Stop from '@material-ui/icons/Stop'
import AlertToastr from './Utils/alert'
import { useMutation } from 'react-query'
import { lookupStart, lookupStop } from './api'
import { useAppDispatch, useAppState } from './Contexts/AppContext'
// import InlineLoader from './components/loaders/InlineLoader'
import { Tooltip } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const LookupSwitcher = () => {

    const {lookupPlay, lookupInfo, lookupDone} = useAppState()
    const {setLookupPlay} = useAppDispatch()

    const count = useMemo(() => {
        if (lookupInfo && lookupInfo.count) return lookupInfo.count
        return 0
    }, [lookupInfo])

    const time = useMemo(() => {
        if (lookupInfo && lookupInfo.time_left) return lookupInfo.time_left
        return ''
    }, [lookupInfo])

    const paid = useMemo(() => {
        return !!(lookupInfo && lookupInfo.is_paid);

    }, [lookupInfo])


    const startMutation = useMutation(lookupStart)
    const stopMutation = useMutation(lookupStop)


    const disabled = startMutation.isLoading || stopMutation.isLoading

    const start = async () => {
        if (disabled) return

        await startMutation.mutateAsync()

        setLookupPlay(true)
        AlertToastr.showAlert('Lookup started')
    }

    const stop = async () => {
        if (disabled) return

        await stopMutation.mutateAsync()

        setLookupPlay(false)
        AlertToastr.showAlert('Lookup stopped')
    }

    if (lookupInfo && lookupInfo.count === 0 && !lookupPlay) return  null

    const renderButton = () => {
        if (lookupDone) return (
            <div className="statusIcoHolder">
                <CheckCircleIcon/>
            </div>
        )
        // if (lookupStatusRequest.isLoading) return <InlineLoader />

        return (
            <div className="statusIcoHolder" style={{marginLeft: '5px'}}>
                {
                    lookupPlay
                        ? (
                            <Tooltip title="Stop lookup">
                                <Stop
                                    style={{width: '25px'}}
                                    cursor={disabled ? null : 'pointer'}
                                    onClick={stop} color={disabled ? 'disabled' : 'primary'}
                                />
                            </Tooltip>

                        )
                        : (
                            <Tooltip title="Start lookup">
                                <PlayArrowIcon
                                    // fontSize='large'
                                    style={{width: '25px'}}
                                    cursor={disabled ? null : 'pointer'}
                                    onClick={start} color={disabled ? 'disabled' : 'primary'}
                                />
                            </Tooltip>
                        )
                }
            </div>
        )
    }

    let label = count === 1 ? `1 phone left` : `${count} phones left`

    // if (lookupStatusRequest.isLoading) label = 'Loading...'
    if (lookupDone) label = 'Completed'

    if (count === 0 && !lookupPlay && !lookupDone) return null

    return (
        <li className="fileProgressItem lookUpItem">
            <div className="icoHolder">

                <HowToRegIcon style={{fontSize: 30}} />
            </div>
            <div className="progress">
                <div className="progressStatus">

                    <div
                        className="name text-mute text-semi-bold">
                        {
                            paid && (
                                <div className={`chipLike warning`} style={{padding: '3px 6px', marginRight: '6px'}}>
                                    <span className='chipLikeLabel'>paid</span>
                                </div>
                            )
                        }
                        {time}
                    </div>
                    <div className="qnt text-light text-mute">
                        <div>{label}</div>
                    </div>
                </div>
                <LinearProgress className='mt5' variant="determinate" value={100}/>
            </div>
            {renderButton()}
            {/*<div className="statusIcoHolder">*/}
            {/*    {renderButton()}*/}
            {/*</div>*/}
        </li>
    )
}

export default LookupSwitcher
