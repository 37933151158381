import React, {useState} from 'react'
import FlagUa from "../../../assets/img/flag-uk.svg";
import FlagEn from "../../../assets/img/flag-english.svg";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from 'react-i18next';


const LangToIcon = {en: FlagEn, ua: FlagUa}
const LangToTitle = {en: "English", ua: "Українська"} //, ru: "Русский"}


const FlagIcon = ({lang = 'ua'}) => {
  const setFlagIcon = LangToIcon.hasOwnProperty(lang) ? LangToIcon[lang] : FlagUa

  return (
      <div className='flag'><img src={setFlagIcon} alt=""/></div>
  )
}

const LanguageSelector = () => {

  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const langOpen = Boolean(anchorEl)

  const handleLangMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleLangMenuClose = () => {
    setAnchorEl(null)
  }

  const setLang = lang => {
    changeLanguage(lang)
    handleLangMenuClose()
  }

  const currentLng = i18n.language
  return (
    <div className='languageSelector'>
      <Button
        startIcon={<FlagIcon lang={currentLng} />}
        onClick={handleLangMenuClick}
        style={{minWidth: "140px"}}
      >
        {currentLng === "ua" ? "Українська" : null}
        {currentLng === "en" ? "English" : null}
        {currentLng === "ru" ? "Русский" : null}
      </Button>

      <Popover
        open={langOpen}
        anchorEl={anchorEl}
        onClose={handleLangMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        disableScrollLock={true}
      >
        <List component="nav" aria-label="main mailbox folders" className='popoverMenu'>
          {Object.keys(LangToIcon).map(lang => {
            return (
                <ListItem key={`lang_icon_${lang}`} button onClick={() => setLang(lang)}>
                  <ListItemIcon>
                    <FlagIcon lang={lang} />
                  </ListItemIcon>
                  <ListItemText primary={LangToTitle[lang]} />
                </ListItem>
            )
          })}
        </List>
      </Popover>
    </div>
  )
}

export default LanguageSelector