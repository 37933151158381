import React from 'react';
import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';

const SubscriptionListItem = ({item}) => {

	const unsubscribe = () => {
		alert('Coming soon :)');
	};

	const renderFeatureRow = (feature) => {
		let usageLabel = `${feature.feature_usage}/${feature.value}`
		if (feature.value === 'false') {
			usageLabel = '-'
		}
		if (feature.value === 'true') {
			usageLabel = '+'
		}
		return (
			<li key={feature.slug}>
				{feature.name}
				{' '}
				<strong>
					{usageLabel}
				</strong>
			</li>
		)
	};

	return (
		<Card>
			<CardContent>
				<Typography gutterBottom variant="body1" component="p">
					Current subscription: <strong>{item?.name}</strong>
				</Typography>
				<Typography gutterBottom variant="body1" component="p">
					Ends At: <strong>{item?.ends_at}</strong>
				</Typography>

				<ul style={{paddingLeft: '20px'}}>
					{item.features.map((feature) => renderFeatureRow(feature))}
				</ul>

			</CardContent>
			<CardActions>
				<Button
					onClick={unsubscribe}
					disabled={false}
					color="primary"
					variant="contained"
					size="small"
				>
					Unsubscribe
				</Button>
			</CardActions>
		</Card>
	);
};

export default SubscriptionListItem;
