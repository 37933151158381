import React, { useState, useCallback, useEffect, useMemo, Fragment } from 'react';
import Logo from '../../assets/img/leads_logo.svg'
import Button from '@material-ui/core/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ProfileIcon from '@material-ui/icons/Person'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {
    setSource,
    setList,
    setLead,
    setSources,
    setNewImport,
    setRunDataUpdate
} from '../../Store/modules/sources/actions'
import { setSegment, setSegmentLead } from '../../Store/modules/segments/actions'
import { useAuth } from '../../Contexts/AuthProvider'
import { connect } from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
// import Exit from '../../assets/img/exit.svg'
import File from '../../assets/img/file_blank.svg'
import LinearProgress from '@material-ui/core/LinearProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import AlertToastr from '../../Utils/alert'
import ImportSubscription from '../../Subscriptions/ImportSubscription'
import DataService from '../../Services/DataService'
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query'
import { useAppDispatch, useAppState } from '../../Contexts/AppContext'
import MenuItem from '../menus/MenuItem'
import BreadcrumbItem from '../menus/BreadcrumbItem'
import LookupSwitcher from '../../LookupSwitcher'
// import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { Alert } from '@material-ui/lab'
import EventEmitter from '../../Utils/EventEmitter'
import LanguageSelector from "./language-selector/language-selector.component";
import HeaderBalanceButton from "../HeaderBalanceButton";
import HeaderFreeSmsCounter from '../HeaderFreeSmsCounter'
import HeaderSendSms from "../HeaderSendSms";
import { useTranslation } from "react-i18next";
import ConfirmModal from '../modals/ConfirmModal';
import { Link } from 'react-router-dom';

// const ImportIcon = () => <img src={Exit} className="ico" alt=''/>

const HeaderComponent = (
    {
        setSources,
        newImport
    }) => {
    const { t } = useTranslation();
    const history = useHistory()
    const { logout, userName, accessDenied, user } = useAuth()
    const { warnings } = useAppState();
    const { setLookupInfo, setLookupPlay, setLookupDone, setWarnings } = useAppDispatch()
    const [imports, setImports] = useState([])
    const [currentImport, setCurrentImport] = useState(null)
    const [userLogin] = useState({ isLogged: true })

    const [anchorEl, setAnchorEl] = useState(null)
    const handleUserMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const { breadcrumbs, mainMenu } = useAppState()
    const open = Boolean(anchorEl)
    const handleUserMenuClose = () => {
        setAnchorEl(null)
    }
    const queryClient = useQueryClient()

    const warningsEnabled = useMemo(() => {
        return warnings.lookup
    }, [warnings])

    useEffect(() => {
        async function fetchActiveImports() {
            let response = await DataService.getActiveImports()
            if (!response || !response.data)
                return
            if (response.data.data) {
                let newImports = response.data.data.filter(i => i.parsed_leads_count > 0 && !i.duplicates_resolved).map((i) => {
                    return {
                        fileId: i.id,
                        status: i.processed === false ? 'Running' : 'Resolving',
                        filename: i.name,
                        parsedLeadsCount: i.parsed_leads_count,
                        resolvedDuplicates: i.duplicates_resolved_count
                    }
                })
                setImports(newImports)
            }
        }

        if (user)
            fetchActiveImports().then()
    }, [user])

    useEffect(() => {
        if (!currentImport) {
            return
        }
        if (currentImport.type === 'end_resolve_duplicates' || (currentImport.type === 'parse_finished' && currentImport.duplicates_resolved)) {
            queryClient.invalidateQueries('segmentLeads').then()
            queryClient.invalidateQueries('segmentsList').then()
            queryClient.invalidateQueries('sourceLists').then()
            queryClient.invalidateQueries('sourcesList').then()
            queryClient.invalidateQueries('sourcesTotal').then()
            queryClient.invalidateQueries('sourceView').then()
            queryClient.invalidateQueries('listLeads').then()
        }
    }, [currentImport, queryClient])

    const handleIncomingData = useCallback((imp) => {
        setCurrentImport(imp)

        setImports(imports => {
            const currentImport = imports.find(importData => imp.file_id === importData.fileId)
            switch (imp.type) {
                case 'parse':
                    if (!currentImport) {
                        imports.push(
                            {
                                fileId: imp.file_id,
                                status: 'Running',
                                filename: imp.client_original_name,
                                parsedLeadsCount: imp.parsed_leads_count
                            }
                        )
                    } else {
                        currentImport.status = 'Running'
                        currentImport.parsedLeadsCount = imp.parsed_leads_count
                    }
                    break
                case 'parse_finished':
                    if (!imp.duplicates_resolved)
                        break
                    if (!currentImport) {
                        imports.push(
                            {
                                fileId: imp.file_id,
                                status: 'Completed',
                                filename: imp.client_original_name
                            }
                        )
                    } else {
                        if (currentImport.status === 'Completed')
                            break
                        currentImport.status = 'Completed'
                    }
                    AlertToastr.showAlert(`File ${currentImport ? currentImport.filename : imp.client_original_name} import finished!`)
                    break
                case 'start_resolve_duplicates':
                    if (!currentImport) {
                        imports.push(
                            {
                                fileId: imp.file_id,
                                status: 'Resolving',
                                filename: imp.client_original_name,
                                resolvedDuplicates: 0
                            }
                        )
                    } else {
                        currentImport.status = 'Resolving'
                        currentImport.resolvedDuplicates = 0
                    }
                    break
                case 'resolve_duplicates':
                    if (!currentImport) {
                        imports.push(
                            {
                                fileId: imp.file_id,
                                status: 'Resolving',
                                filename: imp.client_original_name,
                                resolvedDuplicates: imp.duplicates_resolved
                            }
                        )
                    } else {
                        currentImport.status = 'Resolving'
                        currentImport.resolvedDuplicates = imp.duplicates_resolved
                    }
                    break
                case 'end_resolve_duplicates':
                    if (!currentImport) {
                        imports.push(
                            {
                                fileId: imp.file_id,
                                status: 'Completed',
                                filename: imp.client_original_name
                            }
                        )
                    } else {
                        if (currentImport.status === 'Completed')
                            break
                        currentImport.status = 'Completed'
                    }
                    AlertToastr.showAlert(`File ${currentImport ? currentImport.filename : imp.client_original_name} import finished!`)
                    break
                default:
                    break
            }
            return [...imports]
        })
    }, [])

    const handleLookupInfo = useCallback(data => {

        EventEmitter.emit('LookupData', data)
        console.log(data, 'lookup live')

        const { in_progress, looked_up_count, time_left, is_paid, available_account_balance } = data

        if (is_paid) {
            if (available_account_balance) {
                if (!available_account_balance['telnyx']) {
                    setWarnings(w => ({ ...w, lookup: true }))
                }
            }
        }

        if (looked_up_count === 0 && !in_progress) {
            setLookupDone(true)
        }
        setLookupPlay(in_progress)
        setLookupInfo({ count: looked_up_count, time_left, is_paid })
    }, [setLookupInfo, setLookupPlay, setLookupDone, setWarnings])

    const [anchorImportEl, setAnchorImportEl] = React.useState(null)
    // const handleImportingClick = (event) => {
    //     setAnchorImportEl(event.currentTarget)
    // }
    const openImporting = Boolean(anchorImportEl)
    const handleImportingClose = () => {
        setAnchorImportEl(null)
    }
    const [modalOpen, setModalOpen] = useState(false);

    const handleConfirmLogout = () => {
      userLogin.isLogged = false;
      setSources([]);
      logout();
      setModalOpen(false);
    };

    const handleLogoutClick = () => {
      setModalOpen(true);
    };

    const [showDrawer, setShowDrawer] = useState(false)
    const toggleDrawer = open => event => {

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setShowDrawer(open)
    }

    // const inProgressImportsCount = useMemo(() => {
    //     return [...imports].filter((imp) => imp.status === 'Running' || imp.status === 'Resolving').length
    // }, [imports])

    const resetImports = () => {
        setImports(imports => {
            return [...imports.filter(importData => importData.status === 'Running' || importData.status === 'Resolving')]
        })
    }

    useEffect(() => {
        if (!newImport)
            return
        setImports(imports => {
            imports.push(newImport)
            return [...imports]
        })
        setNewImport(null)
    }, [newImport])

    const renderMobileMenu = () => {
        return (
            <Drawer
                anchor='left'
                open={showDrawer}
                onClose={toggleDrawer(false)}
                className='mainMenuDrawer'
            >
                <div className="menuList mobile">
                    {mainMenu.map((item) => <MenuItem item={item} key={item.label} disabled={accessDenied} />)}
                </div>
            </Drawer>
        )
    }

    const renderBreadcrumbs = () => {
        return (
            <ol className="breadcrumbs">
                {breadcrumbs.map((item, idx) => <BreadcrumbItem item={item} key={idx} />)}
            </ol>
        )
    }

    // const renderProcessesButton = () => {
    //     return (
    //         <Button
    //             color={warningsEnabled ? 'secondary' : 'default'}
    //             endIcon={<ArrowDropDownIcon />}
    //             startIcon={warningsEnabled ? <ErrorIcon /> : <NotificationsNoneIcon />}
    //             onClick={handleImportingClick}
    //         >
    //             {/*{inProgressImportsCount > 0 ? `Importing ${inProgressImportsCount} files` : 'File importer1'}*/}
    //             {t('Header.System Processes')}
    //         </Button>
    //     )
    // }

    const gotoProfile = () => {
        history.push('/profile')
        handleUserMenuClose()
    }
    function getInitials(userName) {
        const words = userName.split(" ");
        if (words.length === 1) {
          return userName.charAt(0);
        } else {
          return words[0].charAt(0) + words[1].charAt(0);
        }
      }

    // const gotoOldProfile = () => {
    //     history.push('/old-profile')
    //     handleUserMenuClose()
    // }

    return (
        <header className="mainHeader">
            <div className="mobileMainMenuHolder">
                <IconButton onClick={toggleDrawer(true)}>
                    <MenuIcon />
                </IconButton>
                {renderMobileMenu()}
            </div>
            <div className="logoHolder">
              <Link to="/leads">
                <img height="50" src={Logo} alt=""/>
              </Link>
            </div>
            <div className="breadCrumbsHolder">
                {renderBreadcrumbs()}
            </div>
            <div className="userMenuHolder">
                <>
                    {
                        user && (
                            <Fragment>
                                <HeaderSendSms />
                                <HeaderFreeSmsCounter />
                                <HeaderBalanceButton />
                            </Fragment>
                        )
                    }

                    {/* {renderProcessesButton()} */}
                    <Popover
                        open={openImporting}
                        anchorEl={anchorImportEl}
                        onClose={handleImportingClose}
                        className='importPopover'
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        disableScrollLock={true}
                    >
                        <div className="importPopoverContent">
                            <div className="ipTitleWrapper">
                                <div className="title">{t('Header.System Processes')}</div>
                                <div className="linkColorNormal text-semi-bold text-mute clickable"
                                    onClick={(e) => resetImports()}>{t('Header.Clear')}
                                </div>
                            </div>

                            <div className="fileProgressHolder">
                                <ul className="fileProgress">
                                    {imports.map((importData) => {
                                        return (
                                            <li key={importData.fileId} className="fileProgressItem">
                                                <div className="icoHolder">
                                                    <img src={File} alt="" />
                                                </div>
                                                <div className="progress">
                                                    <div className="progressStatus">
                                                        <div
                                                            className="name text-mute text-semi-bold">{importData.filename}</div>
                                                        <div className="qnt text-light text-mute">
                                                            <div>
                                                                {(importData.status !== 'Completed') ? `Processed ${importData.status === 'Resolving' ? 'all' : importData.parsedLeadsCount} leads` : importData.status}
                                                            </div>
                                                            {importData.status === 'Resolving' && <div>
                                                                {`Resolved ${importData.resolvedDuplicates} duplicates`}
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    <LinearProgress className='mt5' variant="determinate" value={100} />
                                                </div>
                                                {importData.status === 'Completed' && <div className="statusIcoHolder">
                                                    <CheckCircleIcon />
                                                </div>}
                                                {importData.status === 'Failed' && <div className="statusIcoHolder">
                                                    <ErrorIcon />
                                                </div>}
                                            </li>
                                        )
                                    })}
                                    <LookupSwitcher />
                                </ul>
                                {warningsEnabled && (
                                    <Alert severity="error" style={{ width: '100%', marginTop: '15px' }}>
                                        Insufficient funds to continue paid lookup!
                                    </Alert>
                                )}
                            </div>
                        </div>
                    </Popover>
                </>
                <>
                    <div className="avaHeader">
                        <div>{getInitials(userName)}</div>
                    </div>                
                    <Button
                        endIcon={<ArrowDropDownIcon />}
                        onClick={handleUserMenuClick}
                    >
                        {userName}
                    </Button>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleUserMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        disableScrollLock={true}
                    >
                        <List component="nav" aria-label="main mailbox folders" className='popoverMenu'>
                            <ListItem button onClick={gotoProfile}>
                                <ListItemIcon>
                                    <ProfileIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('New Profile.Profile')} />
                            </ListItem>
                            {/* <ListItem button onClick={gotoOldProfile}>
                                <ListItemIcon>
                                    <ProfileIcon />
                                </ListItemIcon>
                                <ListItemText primary="Old Profile" />
                            </ListItem> */}
                             <ListItem button onClick={handleLogoutClick}>
                              <ListItemIcon>
                                <ExitToAppIcon />
                              </ListItemIcon>
                              <ListItemText primary="Log out" />
                            </ListItem>
                            <ConfirmModal
                              open={modalOpen}
                              handleClose={() => setModalOpen(false)}
                              handleConfirm={handleConfirmLogout}
                              message={t('Modals.Log out message')}
                              approveTitle={t('Modals.Log out')}
                            />
                        </List>
                    </Popover>

                    <ImportSubscription
                        onIncomingData={handleIncomingData}
                        onLookupData={handleLookupInfo}
                    />
                </>
                <LanguageSelector />
            </div>
        </header>
    )
}

const mapDispatchToProps = {
    setSources,
    setSource,
    setList,
    setLead,
    setNewImport,
    setRunDataUpdate,
    setSegment,
    setSegmentLead
}

const mapStateToProps = ({ sources, segments }) => ({
    newImport: sources.newImport,
    runDataUpdate: sources.runDataUpdate,
    segment: segments.segment
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
