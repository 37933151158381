import React, { Fragment, useEffect, useState } from 'react';
import { useAppDispatch } from '../../Contexts/AppContext';
import CreateSegmentIco from '../../assets/img/layers_alt.svg';
import EventEmitter from '../../Utils/EventEmitter';
import Delete from '../../assets/img-new/delete.svg';
import SaveSegmentModal from '../../components/modals/SaveSegmentModal';
import DeleteModal from '../../components/modals/DeleteModal';
import DataService from '../../Services/DataService';
import AlertToastr from '../../Utils/alert';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

const SegmentsPageActions = ({selectedRows}) => {
    const {t} = useTranslation();
    const {setContextMenu} = useAppDispatch();
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const queryClient = useQueryClient();


    useEffect(() => {
        const items = [
            {
                label: t('segments page.create audience'),
                icon: CreateSegmentIco,
                action: () => EventEmitter.emit('SegmentCreate'),
            },
        ];
        if (selectedRows.length) {
            items.push({
                label: t('segments page.delete selection'),
                icon: Delete,
                action: () => EventEmitter.emit('SegmentBulkDeletion'),
            });
        }

        setContextMenu(items);
    }, [setContextMenu, selectedRows, t]);

    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true);

        const listener = EventEmitter.addListener('SegmentBulkDeletion', onEvent);
        return () => {
            listener.remove();
        };
    }, []);

    useEffect(() => {
        const onEvent = () => setShowSaveModal(true);

        const listener = EventEmitter.addListener('SegmentCreate', onEvent);
        return () => {
            listener.remove();
        };
    }, []);


    const deleteItems = () => {
        setShowDeleteModal(false);
        DataService.deleteSegments(selectedRows)
            .then(() => {
                queryClient.invalidateQueries('segmentsList').then();
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors);
            });
    };

    return (
        <Fragment>
            <SaveSegmentModal
                open={showSaveModal}
                handleClose={() => setShowSaveModal(false)}
                isDuplicateSegmentMode={false}
                entityTitle={t('segments page.create audience')} />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteItems}
                handleClose={(e) => setShowDeleteModal(false)}
                instance={t("segments page.selected audiences")} />
        </Fragment>
    );
};

export default SegmentsPageActions;
