import React, { Fragment, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useMutation, useQueryClient } from 'react-query';
import { storeAttribute, updateAttribute } from '../../api';
import AlertToastr from '../../Utils/alert';
import {useTranslation} from "react-i18next";

const SaveAttributeModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        defaultValues,
        onSuccessCreate
    } = props;

    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues,
    });


    const nameRef = useRef();

    const storeAttributeMutation = useMutation(storeAttribute);
    const updateAttributeMutation = useMutation(updateAttribute);

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    useEffect(() => {
        if (storeAttributeMutation.error) {
            AlertToastr.showErrorResponseAlert(storeAttributeMutation.error);
        }
    }, [storeAttributeMutation.error]);

    useEffect(() => {
        if (updateAttributeMutation.error) {
            AlertToastr.showErrorResponseAlert(updateAttributeMutation.error);
        }
    }, [updateAttributeMutation.error]);

    const onSubmit = handleSubmit(async data => {
        if (defaultValues) {
            await updateAttributeMutation.mutateAsync({id: defaultValues.id, ...data});
            handleClose();
            queryClient.invalidateQueries('attributes').then();
        } else {
            try {
                const successResponse = await storeAttributeMutation.mutateAsync(data);
                if (onSuccessCreate) {
                    onSuccessCreate(successResponse.data)
                }
                handleClose();
                queryClient.invalidateQueries('attributes').then();
            } catch (e) {
                console.log(e);
            }
        }

    });

    const title = defaultValues ? t('Modals.Edit Attribute') : t('Modals.Add Attribute');

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modal"
                onEntered={() => {
                    nameRef.current.focus();
                }}
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className="mb15">{title}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <TextField
                            inputRef={(e) => {
                                register(e);
                                nameRef.current = e;
                            }}
                            name="name"
                            label={t("Modals.Name")}
                            variant="outlined"
                            margin="normal"
                        />
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={storeAttributeMutation.isLoading || updateAttributeMutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={storeAttributeMutation.isLoading || updateAttributeMutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    // type="submit"
                                    onClick={onSubmit}
                                >
                                    {t('Modals.Save')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default SaveAttributeModal;
