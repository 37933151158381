import React, { Fragment, useState, useEffect, useCallback, useMemo } from "react";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Folder from "../../assets/img/folder.svg";
import File from "../../assets/img/file_blank.svg";
import Button from "@material-ui/core/Button";
import MatchFormatModal from "./match-format.modal";
import ReactDropzone from "react-dropzone";
import DataService from "../../Services/DataService";
import { connect } from "react-redux";
import AlertToastr from "../../Utils/alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setNewImport } from "../../Store/modules/sources/actions";
import { useTranslation } from "react-i18next";
// import IOSSwitch from '../IOSSwitch'
// import { useMutation } from 'react-query'
// import { updateImportFileBlackList } from '../../api'

const ImportFileModal = ({ open, handleClose, source, list, setNewImport }) => {
    const [openMatchModal, setOpenMatchModal] = useState(false);
    const [selectedSource, setSelectedSource] = useState("");
    const [availableLists, setAvailableLists] = useState([]);
    const [availableSources, setAvailableSources] = useState([]);
    const [selectedList, setSelectedList] = useState("");
    const [isDropdownListMode, setIsDropdownListMode] = useState(true);
    const [isDropdownSourceMode, setIsDropdownSourceMode] = useState(true);
    const [newListName, setNewListName] = useState("");
    const [newSourceName, setNewSourceName] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [importInfo, setImportInfo] = useState(null);
    const [mappingInfo, setMappingInfo] = useState(null);
    const [filename, setFileName] = useState("");
    const [blackList, setBlackList] = useState(false);
    const [uploadClicked, setUploadClicked] = useState(false);
    const { t } = useTranslation();
    // const updateImportFileBlackListMutation = useMutation(updateImportFileBlackList)

    const isListOrSourceEmpty = useMemo(() => {
        return (!selectedSource && isDropdownSourceMode) ||
            (!newSourceName && !isDropdownSourceMode) ||
            (!selectedList && isDropdownListMode) ||
            (!newListName && !isDropdownListMode);
    }, [
        selectedSource,
        isDropdownSourceMode,
        newSourceName,
        selectedList,
        isDropdownListMode,
        newListName,
    ]);

    const onSourceChanged = useCallback(
        (source) => {
            if (!source || !source.id) {
                setIsDropdownListMode(false);
                return;
            }
            setSelectedSource(source);
            setIsDropdownListMode(true);
            setAvailableLists(source.lists);
            if (list) {
                setSelectedList(source.lists.find((l) => l.id === list.id));
                return;
            }
            if (source && source.lists[0]) {
                setSelectedList(source.lists[0]);
            } else setIsDropdownListMode(false);
        },
        [list]
    );

    useEffect(() => {
        async function fetchSources() {
            let response = await DataService.getSourcesWithList();
            if (!response || !response.data) return;
            if (response.data.data) setAvailableSources(response.data.data);
            if (source) {
                onSourceChanged(response.data.data.find((s) => s.id === source.id));
                return;
            }
            if (!response.data.data || !response.data.data[0]) {
                setIsDropdownListMode(false);
                setIsDropdownSourceMode(false);
                return;
            } else {
                setIsDropdownSourceMode(true);
                onSourceChanged(response.data.data[0]);
                return;
            }
        }

        if (!open) {
            return;
        }
        if (isUploading) return;
        fetchSources().then();
    }, [source, open, isUploading, onSourceChanged]);

    const handleClickOpenMatchModal = () => {
        setOpenMatchModal(true);
    };

    const onDrop = async (files) => {
        if (isListOrSourceEmpty) {
            setUploadClicked(true);
            AlertToastr.showErrorAlert(t("Modals.Please, select a Source first"));
            return;
        }
        setIsUploading(true);
        setFileName(files[0].name);
        let currentList = selectedList;
        let currentSource = selectedSource;
        if (!isDropdownSourceMode) {
            try {
                let response = await DataService.createSource(newSourceName);
                currentSource = response.data.data;
                setSelectedSource(response.data.data);
            } catch (error) {
                AlertToastr.showErrorResponseAlert(error);
            }
        }
        if (!isDropdownListMode) {
            try {
                let response = await DataService.createList(
                    currentSource.id,
                    newListName,
                    "active"
                );
                currentList = response.data.data;
                setSelectedList(response.data.data);
            } catch (error) {
                AlertToastr.showErrorResponseAlert(error);
            }
        }
        DataService.uploadFile(currentList.id, files[0], blackList)
            .then((response) => {
                setImportInfo(response.data.data);
                setMappingInfo(response.data.mapping);

                setIsFileUploaded(true);
            })
            .catch((error) => {
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.errors
                )
                    AlertToastr.showErrorAlert(error.response.data.errors);
            });
    };

    const onClose = () => {
        setIsUploading(false);
        setIsDropdownListMode(true);
        setIsDropdownSourceMode(true);
        setFileName("");
        setIsFileUploaded(false);
        setImportInfo(null);
        setMappingInfo(null);
        setNewListName("");
        setNewSourceName("");
        setBlackList(false);
        handleClose();
    };

    // const changeBlackListStatus = async () => {
    //     setBlackList(s => !s)
    //     if (importInfo) {
    //         await updateImportFileBlackListMutation.mutateAsync({
    //             id: importInfo.id,
    //             black_list: !blackList
    //         })
    //     }
    // }

    return (
        <Fragment>
            <Dialog open={open} onClose={onClose} className="modal">
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className="mb15">{t("Modals.Import File")}</h2>
                    </div>
                    <form noValidate autoComplete="off">
                        {/*<div className="align-center wrapper content-justified mt15">*/}
                        {/*    <div style={{fontWeight: '600', fontSize: '14px'}}>Add to black list</div>*/}
                        {/*    <IOSSwitch*/}
                        {/*        disabled={updateImportFileBlackListMutation.isLoading}*/}
                        {/*        color="primary"*/}
                        {/*        checked={blackList}*/}
                        {/*        onChange={changeBlackListStatus}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {isDropdownSourceMode && (
                            <TextField
                                value={selectedSource}
                                onChange={(e) => onSourceChanged(e.target.value)}
                                disabled={isUploading}
                                id="source"
                                label={t("Modals.Source")}
                                variant="outlined"
                                margin="normal"
                                select
                            >
                                {availableSources?.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        {!isDropdownSourceMode && (
                            <TextField
                                value={newSourceName}
                                onChange={(e) => setNewSourceName(e.target.value)}
                                disabled={isUploading}
                                error={
                                    ((!selectedSource && isDropdownSourceMode) ||
                                        (!newSourceName && !isDropdownSourceMode)) &&
                                    uploadClicked
                                }
                                id="newSourceName"
                                label={t("Modals.Source")}
                                variant="outlined"
                                margin="normal"
                            />
                        )}
                        {availableSources && availableSources.length > 0 && !isUploading && (
                            <span
                                onClick={(e) => {
                                    setIsDropdownSourceMode(!isDropdownSourceMode);
                                    setIsDropdownListMode(!isDropdownSourceMode);
                                }}
                                className="linkPrimary text-mute text-semi-bold cursor-pointer"
                            >
                                {isDropdownSourceMode
                                    ? t("Modals.Create new source")
                                    : t("Modals.Select existing source")}
                            </span>
                        )}
                        {isDropdownListMode && (
                            <TextField
                                value={selectedList}
                                onChange={(e) => setSelectedList(e.target.value)}
                                disabled={isUploading}
                                id="list"
                                label={t("Modals.List")}
                                variant="outlined"
                                margin="normal"
                                select
                            >
                                {availableLists.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        {!isDropdownListMode && (
                            <TextField
                                value={newListName}
                                onChange={(e) => setNewListName(e.target.value)}
                                disabled={isUploading}
                                id="newListName"
                                error={
                                    ((!selectedList && isDropdownListMode) ||
                                        (!newListName && !isDropdownListMode)) &&
                                    uploadClicked
                                }
                                label={t("Modals.List")}
                                variant="outlined"
                                margin="normal"
                            />
                        )}
                        <div className="linkHolder mb30">
                            {availableLists.length > 0 &&
                                isDropdownSourceMode &&
                                !isUploading && (
                                    <span
                                        onClick={(e) => setIsDropdownListMode(!isDropdownListMode)}
                                        className="linkPrimary text-mute text-semi-bold cursor-pointer"
                                    >
                                        {isDropdownListMode
                                            ? t("Modals.Create new list")
                                            : t("Modals.Select existing list")}
                                    </span>
                                )}
                        </div>

                        {!isUploading && (
                            <Fragment>
                                <p className="mb20">
                                    {t("Modals.Drag and drop, or click to use the browser")}
                                </p>
                                <ReactDropzone accept=".csv, .xml, .xlsx, .tsv" onDrop={onDrop}>
                                    {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                        isDragReject,
                                        rejectedFiles,
                                    }) => {
                                        return (
                                            <div
                                                className="fileDropHolder"
                                                {...getRootProps({
                                                    onClick: (e) => {
                                                        if (isListOrSourceEmpty) {
                                                            setUploadClicked(true);
                                                            AlertToastr.showErrorAlert(
                                                                t("Modals.Please, select a Source first")
                                                            );
                                                            e.stopPropagation();
                                                        }
                                                    },
                                                })}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="icoHolder mb15">
                                                    <img src={Folder} alt="" />
                                                </div>
                                                <div className="captionHolder text-center text-light text-mute">
                                                    {t("Modals.XLSX CSV supported")}
                                                </div>
                                            </div>
                                        );
                                    }}
                                </ReactDropzone>
                            </Fragment>
                        )}
                        {isUploading && !isFileUploaded && (
                            <div className="loaderHolder text-center">
                                <CircularProgress />
                            </div>
                        )}
                        {isFileUploaded && (
                            <div className="fileProgressHolder">
                                <ul className="fileProgress">
                                    <li className="fileProgressItem">
                                        <div className="icoHolder">
                                            <img src={File} alt="" />
                                        </div>
                                        <div className="progress">
                                            <div className="progressStatus mb5">
                                                <div className="name text-mute text-semi-bold">
                                                    {filename}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={onClose}
                                >
                                    {t("Modals.Cancel")}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={!isFileUploaded}
                                    onClick={handleClickOpenMatchModal}
                                >
                                    {t("Modals.Continue")}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
            <MatchFormatModal
                open={openMatchModal}
                importInfo={importInfo}
                mappingInfo={mappingInfo}
                handleClose={(e) => {
                    setOpenMatchModal(false);
                    onClose();
                }}
                handleBack={(e) => {
                    setOpenMatchModal(false);
                }}
                filename={filename}
                setNewImport={setNewImport}
            />
        </Fragment>
    );
};

const mapDispatchToProps = {
    setNewImport,
};

export default connect(null, mapDispatchToProps)(ImportFileModal);
