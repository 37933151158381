import React, { Fragment, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useMutation } from 'react-query'
import { resendActivationCode } from '../../api'
import AlertToastr from '../../Utils/alert'
import {useTranslation} from "react-i18next";

const ResendActivationCodeModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        onSuccess
    } = props

    const {
        register,
        handleSubmit,
    } = useForm({
        mode: 'onTouched',
    })


    const nameRef = useRef()

    const mutation = useMutation(resendActivationCode)

    useEffect(() => {
        if (mutation.error) {
            AlertToastr.showErrorResponseAlert(mutation.error)
        }
    }, [mutation.error])


    const onSubmit = handleSubmit(async data => {
        try {
            await mutation.mutateAsync(data.email)
            // reset()
            onSuccess()
            handleClose()
            // queryClient.invalidateQueries('alphaNames').then()
            // queryClient.invalidateQueries('activeAlphaNames').then()
        } catch (e) {
            console.log(e)
        }

    })


    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='modal'
                onEntered={() => {
                    nameRef.current.focus()
                }}
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{t('Modals.Resend activation code')}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <TextField
                            inputRef={(e) => {
                                register(e)
                                nameRef.current = e
                            }}
                            name="email"
                            label={t('Modals.Email')}
                            variant="outlined"
                            margin='normal'
                        />
                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={mutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={mutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => {
                                    }}
                                >
                                    {t('Modals.Resend')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default ResendActivationCodeModal
