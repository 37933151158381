import React, { useEffect, useRef } from 'react'
import { useAppDispatch } from '../Contexts/AppContext'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getPhoneValidationTemplate, updatePhoneValidationTemplate } from '../api'
import {useTranslation} from "react-i18next";

const IgnorePhoneFormat = () => {
    const { t } = useTranslation();
    const valuesRef = useRef()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const {
        register,
        handleSubmit,
        reset
    } = useForm({
        defaultValues: {values: ''},
        mode: 'onTouched',
    })
    const queryClient = useQueryClient()
    const {setContextMenu} = useAppDispatch()

    useEffect(() => {
        setContextMenu([])
    }, [setContextMenu])

    const getPhoneValidationTemplateRequest = useQuery(
        ['phoneValidationTemplate'],
        () => getPhoneValidationTemplate(),
        {keepPreviousData: true}
    )
    const storePhoneValidationMutation = useMutation(updatePhoneValidationTemplate)

    useEffect(() => {
        if (getPhoneValidationTemplateRequest.isSuccess) {
            reset({
                values: getPhoneValidationTemplateRequest.data.data.values
            })
            valuesRef.current.focus()
        }
    }, [
        getPhoneValidationTemplateRequest.isSuccess,
        getPhoneValidationTemplateRequest.data,
        reset,
    ])

    useEffect(() => {
        setBreadcrumbs([
            {label: t('Ignore Phone Format.Settings'), link: '/settings'},
            {label: t('Ignore Phone Format.Ignore Phone Format')}
        ])
        setSection('settings')
    }, [setBreadcrumbs, setSection, t])

    const onSubmit = handleSubmit(async data => {
        const response = await storePhoneValidationMutation.mutateAsync(data.values)
        queryClient.invalidateQueries('phoneValidationTemplate').then()
        reset({values: response.data.values})
    })

    const loading = getPhoneValidationTemplateRequest.isLoading || storePhoneValidationMutation.isLoading

    return (
        <div className="pageTableBlock">
            <form onSubmit={onSubmit}>
                <TextField
                    inputRef={(e) => {
                        register(e)
                        valuesRef.current = e
                    }}
                    name="values"
                    label={t("Ignore Phone Format.Formats")}
                    variant="outlined"
                    margin='normal'
                    multiline
                    disabled={loading}
                    rows={7}
                />
                <div className="btnHolder">
                    <Button
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                    >
                        {t("Ignore Phone Format.Save")}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default IgnorePhoneFormat
