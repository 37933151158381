import React, {useRef, useState} from 'react';
import {Button, Card, CardActions, CardContent, CircularProgress, Typography} from '@material-ui/core';
import {useTranslation} from "react-i18next";

const PlanListItem = ({item, disabled, onSelect}) => {
    const { t } = useTranslation();
    const formLiqPayRef = useRef(null)
    const hasLiqPayData = item => item && Boolean(item.lp_data) && Boolean(item.lp_signature)
    const [loading, setLoading] = useState(false)

    const selectPlan = () => {
        if (onSelect) {
            setLoading(true)
            onSelect(item).then(() => setLoading(false))
        }
    }

    return (
        <Card style={{ width: '250px' }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {item.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    disabled, onSelect
                </Typography>
                {
                    item.features && item.features.length && (
                        <div style={{margin: '10px 0'}}>
                            <Typography gutterBottom variant="h6" component="div" color="textSecondary">
                                Features:
                            </Typography>
                            <ul style={{marginLeft: '20px'}}>
                                {
                                    item.features.map(feature => (
                                        <li key={feature.id}>
                                            {feature.name}: <strong>{feature.value}</strong>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
                <Typography gutterBottom variant="h5" component="div">
                    {`${item.price} ${item.currency}`}
                </Typography>
            </CardContent>
            <CardActions>

                {hasLiqPayData(item) &&
                    <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8" ref={formLiqPayRef}>
                        <input type="hidden" name="data" value={item.lp_data} />
                        <input type="hidden" name="signature" value={item.lp_signature} />
                    </form>
                }

                <Button
                    onClick={selectPlan}
                    disabled={disabled}
                    color='primary'
                    variant='contained'
                    size="small"
                >
                    {loading ? <CircularProgress size={22} color="inherit" /> : t('Subscriptions.Subscribe')}
                </Button>
            </CardActions>
        </Card>
    );
};

export default PlanListItem;
