import React, { Fragment, useEffect, useState } from 'react'
import { useAppDispatch } from '../../Contexts/AppContext'
import EventEmitter from '../../Utils/EventEmitter'
import Exit from '../../assets/img/exit.svg'
import PlusCircle from '../../assets/img/plus_circle.svg'
import ImportFileModal from '../../components/modals/ImportFileModal'
import SaveSourceModal from '../../components/modals/SaveSourceModal'
import EditIcon from '../../assets/img-new/edit.svg'
import DeleteModal from '../../components/modals/DeleteModal'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import { useHistory } from 'react-router-dom'
import SaveListModal from '../../components/modals/SaveListModal'
import Delete from '../../assets/img-new/delete.svg'
import { useMutation, useQueryClient } from 'react-query'
import { listsLookup } from '../../api'
import Chevron from '../../assets/img/chevron_big_right.svg'
import ConfirmModal from '../../components/modals/ConfirmModal'
import {useTranslation} from "react-i18next";

const SourcePageActions = ({source, loading, selectedRows, bulkDone}) => {
    const { t } = useTranslation();
    const {setContextMenu} = useAppDispatch()
    const history = useHistory()
    const [showImportModal, setShowImportModal] = useState(false)
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showSaveListModal, setShowSaveListModal] = useState(false)
    const [showDeleteListsModal, setShowDeleteListsModal] = useState(false)
    const [showLookupModal, setShowLookupModal] = useState(false)
    const queryClient = useQueryClient()
    const lookupMutation = useMutation(listsLookup)

    useEffect(() => {
        const items = [
            {
                label: t('Sources.Import File'),
                icon: Exit,
                action: () => EventEmitter.emit('ImportFile'),
                disabled: loading
            },
            {
                label: t('Sources.Edit Source'),
                icon: EditIcon,
                action: () => EventEmitter.emit('SourceEdit'),
                disabled: loading
            },
            {
                label: t('Sources.Remove source'),
                icon: Delete,
                action: () => EventEmitter.emit('SourceDelete'),
                disabled: loading
            },
            {
                label: t('Sources.Add New List'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('SourceListCreate'),
                disabled: loading
            }
        ]

        if (selectedRows.length) {

            items.push(                {
                label: t('Sources.Run Lookup'),
                icon: Chevron,
                action: () => EventEmitter.emit('ListsRunLookup'),
            })

            items.push(                {
                label: t('Sources.Delete Selection'),
                icon: Delete,
                action: () => EventEmitter.emit('SourceListsBulkDeletion')
            })
        }

        setContextMenu(items)
    }, [setContextMenu, loading, selectedRows, t])

    //listener: import file
    useEffect(() => {
        const onEvent = () => setShowImportModal(true)

        const listener = EventEmitter.addListener('ImportFile', onEvent)
        return () => { listener.remove() }
    }, [])

    //listener: source edit
    useEffect(() => {
        const onEvent = () => setShowSaveModal(true)

        const listener = EventEmitter.addListener('SourceEdit', onEvent)
        return () => { listener.remove() }
    }, [])

    //listener: source delete
    useEffect(() => {
        const onEvent = () => setShowDeleteModal(true)

        const listener = EventEmitter.addListener('SourceDelete', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowSaveListModal(true)

        const listener = EventEmitter.addListener('SourceListCreate', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowDeleteListsModal(true)

        const listener = EventEmitter.addListener('SourceListsBulkDeletion', onEvent)
        return () => { listener.remove() }
    }, [])

    useEffect(() => {
        const onEvent = () => setShowLookupModal(true)

        const listener = EventEmitter.addListener('ListsRunLookup', onEvent)
        return () => { listener.remove() }
    }, [lookupMutation, selectedRows])

    const deleteSource = () => {
        setShowDeleteModal(false)
        DataService.deleteSource(source.id)
            .then(response => {
                history.push(`/sources`)
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const deleteItems = () => {
        setShowDeleteListsModal(false)
        DataService.deleteLists(selectedRows)
            .then(() => {
                queryClient.invalidateQueries('sourceLists').then()
                queryClient.invalidateQueries('availableSources').then()
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.errors)
                    AlertToastr.showErrorAlert(error.response.data.errors)
            })
    }

    const lookupItems = async () => {
        await lookupMutation.mutateAsync(selectedRows)
        setShowLookupModal(false)
        AlertToastr.showAlert(t('Sources.Lookup has been started'))
        if (bulkDone) bulkDone()
    }

    return (
        <Fragment>
            <ImportFileModal
                source={source}
                open={showImportModal}
                handleClose={() => setShowImportModal(false)}
            />
            <SaveSourceModal
                source={source}
                open={showSaveModal}
                handleClose={(event) => {
                    setShowSaveModal(false)
                }}
            />
            <DeleteModal
                open={showDeleteModal}
                handleDelete={deleteSource}
                handleClose={() => setShowDeleteModal(false)}
                instance={'the source'}
            />
            <SaveListModal
                open={showSaveListModal}
                handleClose={(e) => setShowSaveListModal(false)}
                sourceId={source?.id}
            />
            <DeleteModal
                open={showDeleteListsModal}
                handleDelete={deleteItems}
                handleClose={() => setShowDeleteListsModal(false)}
                instance={'selected lists'}
            />
            <ConfirmModal
                loading={lookupMutation.isLoading}
                message={t('Sources.Do you want to lookup leads in selected lists')}
                open={showLookupModal}
                handleClose={(e) => setShowLookupModal(false)}
                handleConfirm={lookupItems}
            />
        </Fragment>
    )
}

export default SourcePageActions
