import React, { useState, useEffect, useMemo } from 'react'
import { Redirect, useHistory } from 'react-router-dom';
import { useQueries, useQuery } from 'react-query'
import { getSegmentAggregations, getSegments } from '../../api'
import SearchInput from '../../components/SearchInput'
import LoadingValue from '../../components/LoadingValue'
import { useAppDispatch } from '../../Contexts/AppContext'
import BasicTable from '../../components/BasicTable.jsx'
import SegmentsPageActions from './SegmentsPageActions'
import {toServerDatetimeFormat} from '../../Utils/dates'
import useStickyState from '../../Hooks/useStickyState'
import {useTranslation} from "react-i18next";
import { useAuth } from '../../Contexts/AuthProvider';

const SegmentsPage = () => {
    const {user} = useAuth();
    const { t } = useTranslation();
    const [list, setList] = useState([])
    const [pagination, setPagination] = useState(null)
    const [search, setSearch] = useState('')
    const [sorting, setSorting] = useStickyState([{key: "created_at", direction: "desc"}], 'segmentSortBy')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(100)
    const history = useHistory()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [selectedRows, setSelectedRows] = useState([])
    const tableColumns = useMemo(() => {
        return [
            {
                Header: t('segments page.audience name'),
                accessor: 'name'
            },
            {
                Header: t('segments page.created date'),
                accessor: 'created_at',
                Cell: ({value}) => toServerDatetimeFormat(value)
            },
            {
                Header: t('segments page.segments'),
                accessor: 'segments_count',
                Cell: ({row, value}) => {
                    return (
                        <div onClick={(e) => {
                            e.stopPropagation()
                            history.push(`/audiences/${row.original.id}/segments`)
                        }
                        }>{value}</div>
                    )
                }
            },
            {
                Header: t('segments page.leads'),
                accessor: 'leads_count',
                disableSortBy: true,
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            // {
            //     Header: 'Duplicates',
            //     accessor: 'duplicates_count',
            //     disableSortBy: true,
            //     Cell: ({value}) => <LoadingValue value={value}/>
            // },
            // {
            //     Header: t('segments page.status'),
            //     accessor: 'status',
            //     disableSortBy: true,
            //     className: 'text-right',
            //     Cell: ({value}) => (
            //         <div className="chipLike success">
            //             <span className="chipLikeLabel">Active</span>
            //         </div>
            //     )
            // }
        ]
    }, [history, t])
    const tableData = useMemo(() => {
        return list.map(item => {
            return {...item, status: true}
        })
    }, [list])


    const tableSorting = useMemo(() => {
        if (!sorting) return null;

        return sorting.map(item => ({
            id: item.key, desc: item.direction === 'desc'
        }))
    }, [sorting])

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ['segmentsList', page, limit, search, sorting],
        () => getSegments(page, limit, search, sorting),
        {keepPreviousData: true, enabled: !!user && !user.isTeamOperator()}
    )

    const segmentAggregationsRequests = useQueries(list.map(segment => {
        return {
            queryKey: ['segmentAggregations', segment.id],
            queryFn: () => getSegmentAggregations(segment.id),
            cacheTime: 1000 * 300,
            refetchOnMount: false,
            // enabled: false
        }
    }))

    useEffect(() => {
        segmentAggregationsRequests.forEach(req => {
            if (req.isSuccess) {
                setList(prev => {
                    const updatedSegment = req.data.data;
                    const newList = [...prev]
                    const index = newList.findIndex(s => s.id === updatedSegment.id)
                    if (index !== -1) {
                        newList[index] = {
                            ...newList[index],
                            leads_count: updatedSegment.leads_count,
                            duplicates_count: updatedSegment.duplicates_count,
                        }
                    }
                    return newList
                })
            }
        })
    }, [segmentAggregationsRequests])



    useEffect(() => {
        if (isSuccess) {
            setList(data.data)
            setPagination(data.meta)
        }
    }, [isSuccess, data])

    useEffect(() => {
        setBreadcrumbs([{label: t('main menu.audiences')}])
        setSection('segments')
    }, [setBreadcrumbs, setSection, t])

    useEffect(() => {
        setPage(1)
    }, [search])

    const handleItemClick = item => {
        history.push(`/audiences/${item.id}`)
    }

    const renderSearchBlock = () => {
        return (
            <div className="searchHolder">
                <div className="inputHolder">
                    <SearchInput
                        search={search}
                        onChange={s => setSearch(s)}
                    />
                </div>
            </div>
        )
    }

    if (user && user.isTeamOperator()) {
        return <Redirect to="/leads" />;
    }

    return (
        <div className="pageHolder">
            <div className="pageTableBlock">
                {renderSearchBlock()}
                <div className="tableHolder hasCheckboxes rowClickable">
                    <BasicTable
                        columns={tableColumns}
                        data={tableData}
                        loading={isLoading}
                        fetching={isFetching}
                        isSelectionColumn={true}
                        onSelectedRows={setSelectedRows}
                        onRowClick={handleItemClick}
                        onSort={setSorting}
                        initSort={tableSorting}
                        emptyDataText={t('segments page.empty text')}
                        pagination={pagination}
                        pageLimit={limit}
                        onPageChange={setPage}
                        onLimitChange={(l) => {
                            setPage(1)
                            setLimit(l)
                        }}
                    />
                </div>
            </div>
            <SegmentsPageActions
                selectedRows={selectedRows}
            />
        </div>
    )
}

export default SegmentsPage
