import React, { useEffect } from 'react';
import FormChangePassword from '../../components/Formik/form/ChangePassword.form';
import AlertToastr from '../../Utils/alert';
import { changePassword } from '../../api';
import { useMutation } from 'react-query';
import { useAuth } from '../../Contexts/AuthProvider';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../Contexts/AppContext';

const ChangePassword = () => {
    const { t } = useTranslation();
    const changePasswordMutation = useMutation(changePassword);
    const { login } = useAuth();
    const { setBreadcrumbs, setContextMenu } = useAppDispatch();

    useEffect(() => {
        setBreadcrumbs([{label: t('New Profile.Security')}])
        setContextMenu([]);
    }, [setBreadcrumbs, setContextMenu, t]);

    const handleOnSubmit = async (values, resetForm) => {
        try {
            const response = await changePasswordMutation.mutateAsync(values);
            const { access_token } = response.data;
            login(access_token);
            resetForm();
            AlertToastr.showAlert(t('Auth.Password updated successfully'));
        } catch (e) {
            AlertToastr.showErrorAlert(e.response.data.message);
        }
    };
    return (
        <>
            <div className="contentPartTitleHolder">
                <div className="contentPartTitle">{t('New Profile.Security')}</div>
            </div>
            {/*<div className="text mb45">*/}
            {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore*/}
            {/*    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.*/}
            {/*</div>*/}
            <FormChangePassword onSubmit={handleOnSubmit} />
        </>
    );
};

export default ChangePassword;
