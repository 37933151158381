import React, { useState, Fragment } from 'react'
import HeaderComponent from './Header.component'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useAppState } from '../../Contexts/AppContext'
import MenuItem from '@material-ui/core/MenuItem'
import MainMenuItem from '../menus/MenuItem'
import CaretImg from '../../assets/img/caret.svg'
import Menu from '@material-ui/core/Menu'
import { useAuth } from '../../Contexts/AuthProvider'
import ChatBtn from "./chat-btn/chat-btn.component";
import { useTranslation } from "react-i18next";

const ContextMenuItem = ({ item }) => {
    const {allowActions} = useAuth()
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => setAnchorEl(event.currentTarget)

    const handleClose = () => setAnchorEl(null)

    const disabled = item.disabled || !allowActions

    return !item.items ? (
        <div
            className="menuListItem"
            onClick={!disabled ? item.action : () => { }}
            style={disabled ? { opacity: '.3', cursor: 'not-allowed' } : {}}
        >
            <img
                src={item.icon}
                className="menuIco"
                alt={item.label}
                style={item.iconStyle || {}}
            />
            <span className="caption">{item.label}</span>
        </div>
    ) : (
        <Fragment>
            <div
                className="menuListItem"
                onClick={!disabled ? handleClick : () => { }}
                style={disabled ? { opacity: '.3', cursor: 'not-allowed' } : {}}
            >
                <img
                    src={item.icon}
                    className="menuIco"
                    alt={item.label}
                    style={item.iconStyle || {}}
                />
                <span className="caption">{item.label}</span>
            </div>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {
                    item.items.map(menuItem => {
                        return (
                            <MenuItem
                                key={menuItem.label}
                                onClick={() => {
                                    if (menuItem.action) {
                                        menuItem.action()
                                    }
                                    setAnchorEl(null)
                                }}
                            >
                                {t(`${menuItem.label}`)}
                            </MenuItem>
                        )
                    })
                }

            </Menu>
        </Fragment>

    )
}

const MainLayout = props => {
    const {
        children,
        source,
        list,
        lead,
        segment,
        segmentLead
    } = props
    const { accessDenied } = useAuth()
    const [anchorEl, setAnchorEl] = useState(null)
    const { mainMenu, contextMenu, contextLinks } = useAppState()
    const handleMobileAsideMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const open = Boolean(anchorEl)
    const handleMobileAsideMenuClose = () => {
        setAnchorEl(null)
    }

    const renderMainMenu = () => {
        return (
            <div className="menuList">
                {mainMenu.filter(i => !i.hide).map(item => <MainMenuItem item={item} key={item.label} disabled={accessDenied} />)}
            </div>
        )
    }

    const renderAsideMenu = () => {
        return (
            <div className="menuList">
                {
                    contextMenu.map(item => {
                        return <ContextMenuItem key={item.label} item={item} />
                    })
                }
                {
                    contextLinks.map((item, index) => {
                        const itemClass = item.disabled ? "menuListItem disabled" : "menuListItem"
                        return (
                            <div className={itemClass} key={index}>
                                <img src={CaretImg} className="ico" alt={item.label} />
                                <a className="btn" href={item.url} onClick={(e) => {
                                    item.disabled && e.preventDefault()
                                }}>{item.label || ''}</a>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div className="mainLayout">
            <HeaderComponent
                source={source}
                list={list}
                lead={lead}
                segment={segment}
                segmentLead={segmentLead} />

            <aside className="mainMenu">
                {renderMainMenu()}
            </aside>
            <aside className="asideMenu">
                {renderAsideMenu()}
            </aside>
            <main className="mainLayoutContent">
                <div className="mobileSubMenu">
                    <h3>Sources</h3>
                    <div className="mobileAsideMenu">
                        <IconButton onClick={handleMobileAsideMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleMobileAsideMenuClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            {renderAsideMenu()}
                        </Popover>
                    </div>
                </div>
                <div className="mainContainer">
                    {children}
                </div>
            </main>
            {/* <ChatBtn /> */}
        </div>
    )
}

export default MainLayout
