import React, { useState } from 'react'
import Button from "@material-ui/core/Button";
import AddCircleIcon from "../assets/img-new/add-button.png";
import AddBalanceModal from "./modals/AddBalanceModal";
import { useAuth } from "../Contexts/AuthProvider";
import { useAppState } from "../Contexts/AppContext";
import { CircularProgress } from "@material-ui/core";
import UserBalance from "./balance/UserBalance";

export const isBalance = balance => balance != null

export const HasBalance = ({ not = null, children }) => {
    const { isLoggedIn } = useAuth()
    const { balance } = useAppState()

    if (!isLoggedIn) return null

    return isBalance(balance) ? <>{children}</> : not
}

const HeaderBalanceButton = () => {
    const { role } = useAuth()
    const { balance } = useAppState()
    const [open, setOpen] = useState(false)

    const handleBalanceClick = () => {
        if(role === 'team_operator') return false
        if (isBalance(balance)) setOpen(true)
    }

    return (
        <HasBalance not={<Button color={'default'}><CircularProgress size={19} /></Button>}>
            <Button color={'default'} onClick={handleBalanceClick}>
                {role === 'team_operator' ? (
                    <></>
                ) : (
                    <img className='add-button-img' src={AddCircleIcon} alt="Add" />
                )}

                <UserBalance />
            </Button>
            <AddBalanceModal open={open} handleClose={() => setOpen(false)} />
        </HasBalance>
    )
}

export default HeaderBalanceButton

