import React from 'react'
import {useTranslation} from "react-i18next";

const AddAttributeButton = ({disabled, onClick}) => {
    const { t } = useTranslation();
    const className = disabled
        ? 'text-mute text-semi-bold cursor-pointer'
        : 'text-mute text-semi-bold cursor-pointer linkPrimary';

    return (
        <span
            className={className}
            style={!disabled ? {} : {color: '#ccc'}}
            onClick={(e) => {
                if (disabled) return
                onClick()
            }}
        >
            {t('Audience Filter.Add an attribute')}
        </span>
    )
}

export default AddAttributeButton
