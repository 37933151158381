import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { CircularProgress } from "@material-ui/core";
import { useMutation } from "react-query";
import { balanceTopUp } from "../../api";
import { useTranslation } from "react-i18next";

export const LiqPayForm = React.forwardRef(({ data = '', signature = '' }, ref) => {
    return (
        <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8" ref={ref}>
            <input type="hidden" name="data" value={data} />
            <input type="hidden" name="signature" value={signature} />
        </form>
    )
})

const AddBalanceModal = ({ open, handleClose = () => { }, onSuccessCreate = () => { } }) => {
    const formLiqPayRef = React.createRef()
    const [amount, setAmount] = useState(0)
    const [liqpay, setForm] = useState({ data: '', signature: '' })
    const [errorMessage, setError] = useState('')
    const mutation = useMutation(balanceTopUp)
    const { t } = useTranslation();
    const handlePay = async () => {
        try {
            if (!/^\d+(\.\d{1,2})?$/.test(amount.toString()) || parseFloat(amount.toString()) === 0) {
                setError(t("Transaction.Correct number"))
                return
            }

            const form = formLiqPayRef.current
            const data = await mutation.mutateAsync({ amount, redirectUrl: window.location.href })
            setForm(data)

            if (form) {
                form.submit()
                handleClose()
                onSuccessCreate()
            }
        } catch (error) {
            setError(t("Transaction.Correct number"))
        }
    }

    useEffect(() => setError(''), [amount])

    return (
        <Dialog open={open} onClose={handleClose} className='modal'>
            <div className="modalBody">
                <div className="modalTitleHolder mb30">
                    <h2 className='mb15'>{t("Modals.Top Up Balance")}</h2>
                </div>

                <LiqPayForm
                    data={liqpay.data}
                    signature={liqpay.signature}
                    ref={formLiqPayRef}
                />

                <TextField
                    error={Boolean(errorMessage)}
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    id="top_up_balance"
                    label={t("Modals.Amount")}
                    variant="outlined"
                    margin='normal'
                    helperText={errorMessage}
                />

                <div className="btnHolder hasPadTop twoBtn">
                    <div className="part">
                        <Button disabled={mutation.isLoading} variant="outlined" color="primary" fullWidth onClick={handleClose}>
                            {t("Modals.Cancel")}
                        </Button>
                    </div>
                    <div className="part">
                        <Button disabled={mutation.isLoading} variant="contained" color="primary" fullWidth onClick={handlePay}>
                            {mutation.isLoading ? <CircularProgress size={24} color="inherit" /> : t("Modals.Pay")}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default AddBalanceModal
