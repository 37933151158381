import React from 'react';
import PlanListItem from './PlanListItem';

const PlanList = ({items, onPlanSelect, disabled}) => {
    return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {
                items.map(item => {
                    return (
                        <PlanListItem
                            disabled={disabled}
                            key={item.id}
                            item={item}
                            onSelect={onPlanSelect}
                        />
                    )
                })
            }
        </div>
    );
};

export default PlanList;
