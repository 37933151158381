import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from '../../Contexts/AppContext'
import { useQuery } from 'react-query'
import { getCampaigns, getCampaignsWithAggregations } from '../../api'
import { useHistory } from 'react-router-dom'
import ListTable from '../../components/ListTable/ListTable'
import CampaignsActions from './CampaignsActions'
import {toServerDatetimeFormat} from '../../Utils/dates'
import CampaignStatus from '../../components/CampaignStatus'
import TableProgressCell from '../../components/TableProgressCell'
import LoadingValue from '../../components/LoadingValue'
import { storageKeys } from '../../config'
import {useTranslation} from "react-i18next";

const Campaigns = () => {
    const { t } = useTranslation();
    const [list, setList] = useState([])
    const [pagination, setPagination] = useState(null)
    const [search, setSearch] = useState()
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(15)
    const history = useHistory()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const [selectedRows, setSelectedRows] = useState([])
    const tableColumns = useMemo(() => {
        return [
            {
                Header: t('Campaigns.Started Date'),
                accessor: 'started_at',
                Cell: ({value}) => toServerDatetimeFormat(value)
            },
            {
                Header: 'ID',
                accessor: 'id'
            },
            {
                Header: t('Campaigns.Name'),
                accessor: 'name'
            },
            // {
            //     Header: t('Campaigns.Type'),
            //     accessor: 'type',
            //     Cell: ({row}) => row.original.type_label
            // },
            {
                Header: t('Alpha Names.Alpha Name'),
                accessor: 'alpha_name',
            },
            {
                Header: t('Campaigns.SID'),
                accessor: 'ms_name',
                Cell: ({row}) => row.original.ms_name || '-'
            },
            {
                Header: t('Campaigns.Records'),
                accessor: 'records',
            },
            {
                Header: t('Campaigns.Steps'),
                accessor: 'campaign_steps_count',
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Campaigns.Progress'),
                accessor: 'percentage_sent_of_all_steps',
                // Cell: ({row}) => row.original.amount_sent
                Cell: ({value}) => {
                    const progress = <TableProgressCell value={value} />
                    return <LoadingValue value={value} renderedValue={progress} />
                }
            },
            {
                Header: t('Campaigns.Cost'),
                accessor: 'actual_cost',
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Campaigns.Sent'),
                accessor: 'amount_sent',
                Cell: ({row, value}) => {
                    if(!!row.original.approximate_sms_parts && parseInt(row.original.approximate_sms_parts) > 0) {
                        return <LoadingValue value={value} renderedValue={<>{value} <span className="xNum">x{row.original.approximate_sms_parts}</span></>} />
                    }

                    return <LoadingValue value={value} />
                }
            },
            {
                Header: t('Campaigns.Delivered'),
                accessor: 'amount_delivered',
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Campaigns.DeliveredPercent'),
                accessor: 'percentage_delivered',
                Cell: ({value}) => <LoadingValue value={value} suffix="%"/>
            },
            {
                Header: t('Campaigns.Clicked'),
                accessor: 'amount_clicked',
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Campaigns.ClickedPercent'),
                accessor: 'percentage_clicked',
                Cell: ({value}) => <LoadingValue value={value} suffix="%"/>
            },
            {
                Header: t('Campaigns.Error'),
                accessor: 'amount_error',
                Cell: ({value}) => <LoadingValue value={value}/>
            },
            {
                Header: t('Campaigns.Status'),
                accessor: 'status',
                className: 'col-sticky text-right',
                Cell: ({value}) => <CampaignStatus status={value} />
            }
        ]
    }, [t])

    const campaignRequest = useQuery(
        ['campaignsList', page, limit, search],
        () => getCampaigns(page, limit, search),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (campaignRequest.isSuccess) {
            setList(campaignRequest.data.data)
            setPagination(campaignRequest.data.meta)
        }
    }, [campaignRequest.isSuccess, campaignRequest.data])

    const campaignAggregationRequest = useQuery(
        ['campaignsAggregationList', page, limit, search, sorting],
        () => getCampaignsWithAggregations(page, limit, search, sorting),
        {keepPreviousData: true, enabled: !campaignRequest.isFetching, refetchOnWindowFocus: true}
    )

    useEffect(() => {
        if (campaignAggregationRequest.isSuccess) {
            setList(campaignAggregationRequest.data.data)
        }
    }, [campaignAggregationRequest.isSuccess, campaignAggregationRequest.data])

    useEffect(() => {
        setBreadcrumbs([{label: t('Campaigns.Campaigns')}])
        setSection('campaigns')
    }, [setBreadcrumbs, setSection, t])

    const handleItemClick = item => {
        history.push(`/campaigns/${item?.id}`)
    }

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])

    const handleSearchChange = useCallback((str) => {
        setPage(1)
        setSearch(str)
    }, [setPage, setSearch])

    return (
        <div className="pageHolder">
            <div className="pageTableBlock">
                <ListTable
                    columns={tableColumns}
                    data={list}
                    loading={campaignRequest.isLoading}
                    fetching={campaignRequest.isFetching}
                    isSelectionColumn={true}
                    onSelectedRows={setSelectedRows}
                    onRowClick={handleItemClick}
                    onSort={setSorting}
                    emptyDataText={t('Campaigns.No Campaigns Found')}
                    pagination={pagination}
                    pageLimit={limit}
                    onPageChange={setPage}
                    onLimitChange={handleLimitChange}
                    onSearch={handleSearchChange}
                    hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInCampaign}
                />
            </div>
            <CampaignsActions
                selectedRows={selectedRows}
            />
        </div>
    )
}

export default Campaigns
