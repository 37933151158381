import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { getImportFileFailedLeads } from '../api'
import LeadsTableComponent from './sources/LeadsTableComponent'

const ImportFileFailedLeads = ({fileId}) => {
    const [leads, setLeads] = useState([])
    const [search, setSearch] = useState('')
    const [sorting, setSorting] = useState({key: 'id', direction: 'desc'})
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [pagination, setPagination] = useState(null);
    const [columns, setColumns] = useState([])
    const [orderableColumnIds, setOrderableColumnIds] = useState([])

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ['importFileFailedLeads', fileId, page, limit, search, sorting],
        () => getImportFileFailedLeads(fileId, page, limit, search, sorting),
        {
            keepPreviousData: true,
        }
    )

    useEffect(() => {
        if (isSuccess) {
            setLeads(data.data)
            setPagination(data.meta)

            if (data.attributes) {
                const attributes = getLeadAttributes(data.attributes)
                setColumns(attributes)
            }

            if (data.orderable) {
                setOrderableColumnIds([...data.orderable, 'id'])
            }
        }
    }, [isSuccess, data])

    const leadsValues = useMemo(() => {
        if (!columns || columns.length === 0)
            return
        let leadsValues = []
        leads.forEach((lead) => {
            let leadValues = []
            columns.forEach((column) => {
                if (!column.selected)
                    return
                switch (column.id) {
                    case 'id':
                        leadValues.push({ id: column.id, value: lead.id })
                        break
                    case 'duplicates':
                        leadValues.push({ id: column.id, value: lead.duplicates, selected: false })
                        break
                    case 'status':
                        leadValues.push({ id: column.id, value: lead?.status, selected: false })
                        break
                    default:
                        const value = lead.lead_values.filter(value => value.attribute.id === column.id).map(attr => attr.value).join('; ')
                        leadValues.push({ id: column.id, value: value ? value : '', selected: false })
                }
            })
            leadsValues.push({ id: lead.id, leadValues: leadValues })
        })
        return leadsValues
    }, [columns, leads])

    const getLeadAttributes = (leadAttributes) => {
        let newColumns = []
        newColumns.push({ id: 'id', name: 'ID', selected: true, type: 'string' })
        leadAttributes.forEach(attr => {
            if (newColumns.find(col => col.id === attr.id))
                return
            newColumns.push({ id: attr.id, name: attr.name, selected: true, type: attr.type })
        });
        // newColumns.push({ id: 'duplicates', name: 'Duplicates', selected: true, type: 'string' })
        // newColumns.push({ id: 'status', name: 'Status', selected: true, type: 'string' })

        return newColumns
    }

    const handleSelectedIdChange = (leadId, checked) => {
        leads.find(l => l.id === leadId).selected = checked
        setLeads([...leads])
    }

    const leadSelected = lead => {
        // history.push(`/leads/${lead?.id}`)
    }

    return (
        <div className="pageHolder">
            <LeadsTableComponent
                data={data}
                limit={limit}
                setLimit={setLimit}
                setPage={setPage}
                isSuccess={isSuccess}
                search={search}
                setSearch={setSearch}
                sorting={sorting}
                setSorting={setSorting}
                leadsValues={leadsValues}
                handleSelectedIdChange={handleSelectedIdChange}
                columns={columns}
                setColumns={setColumns}
                orderableColumnIds={orderableColumnIds}
                leadSelected={leadSelected}
                pagination={pagination}
                isLoading={isLoading}
                isFetching={isFetching}
            />
        </div >
    )
}

export default ImportFileFailedLeads
