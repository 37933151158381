import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useMutation, useQueryClient } from 'react-query';
import { storeSMSTemplate, updateSMSTemplate } from '../../api';
import AlertToastr from '../../Utils/alert';
import MenuItem from '@material-ui/core/MenuItem';
import ReactHookFormSelect from '../form/ReactHookFormSelect';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';
import IOSSwitch from '../IOSSwitch';

const SaveSMSTemplateModal = props => {
    const { t } = useTranslation();
    const {
        simpleMode,
        simpleModeMessage,
        open,
        handleClose,
        defaultValues,
        typeOptions,
        availableAttributes,
    } = props;

    const messageRef = useRef(null);
    const queryClient = useQueryClient();
    const [messageCursorPosition, setMessageCursorPosition] = useState();

    const {
        control,
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
    } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues,
    });

    const storeMutation = useMutation(storeSMSTemplate);
    const updateMutation = useMutation(updateSMSTemplate);

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    useEffect(() => {
        if (storeMutation.error) {
            AlertToastr.showErrorResponseAlert(storeMutation.error);
        }
    }, [storeMutation.error]);

    useEffect(() => {
        if (updateMutation.error) {
            AlertToastr.showErrorResponseAlert(updateMutation.error);
        }
    }, [updateMutation.error]);

    const onSubmit = handleSubmit(async data => {

        if (defaultValues) {
            await updateMutation.mutateAsync({ id: defaultValues.id, ...data })
            handleClose()
            queryClient.invalidateQueries('smsTemplates').then()
        } else {
            let storeData = {...data}
            if (simpleMode) {
                storeData.type_id = '0'
                storeData.message = simpleModeMessage
            }
            try {
                await storeMutation.mutateAsync(storeData)
                handleClose()
                queryClient.invalidateQueries('smsTemplates').then()
                if (simpleMode) {
                    AlertToastr.showAlert(t("SMS Templates.Success Save"))
                }
            } catch (e) {
                console.log(e)
            }
        }

    });

    const setCursorPosition = e => {
        setMessageCursorPosition(e.target.selectionStart);
    };

    const insertToken = token => {
        let value = watch('message');
        let textToInsert = `{{${token}}}`;
        let cursorPosition = messageCursorPosition === undefined ? value.length : messageCursorPosition;
        let textBeforeCursorPosition = value.substring(0, cursorPosition);
        let textAfterCursorPosition = value.substring(cursorPosition, value.length);
        let newValue = `${textBeforeCursorPosition}${textToInsert} ${textAfterCursorPosition}`;
        setValue('message', newValue, { shouldDirty: false });

        const messageInput = messageRef.current.children[1].children[0];
        const nextCursorPosition = cursorPosition + textToInsert.length + 1;
        messageInput.focus();
        setMessageCursorPosition(nextCursorPosition);

        setTimeout(() => {
            messageInput.setSelectionRange(nextCursorPosition, nextCursorPosition);
        }, 5);
    };


    const renderTitle = () => {
        if (simpleMode) {
            return t('SMS Templates.Save As Template')
        }

        return defaultValues
            ? t('SMS Templates.Update SMS Template')
            : t('SMS Templates.Add SMS Template');
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modal"
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className="mb15">{renderTitle()}</h2>
                    </div>
                    <form onSubmit={onSubmit}>
                        <TextField
                            inputRef={register}
                            name="name"
                            label={t('SMS Templates.Fields.Name')}
                            variant="outlined"
                            margin="normal"
                        />

                        {
                            !simpleMode && (
                                <Fragment>
                                    {
                                        typeOptions.length && (
                                            <ReactHookFormSelect
                                                variant="outlined"
                                                margin="normal"
                                                id="type-select"
                                                name="type_id"
                                                label={t('SMS Templates.Fields.Type')}
                                                control={control}
                                                defaultValue={(typeOptions.length && `${typeOptions[0].key}`) || ''}
                                            >
                                                {typeOptions.map((option) => (
                                                    <MenuItem key={option.key} value={option.key}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </ReactHookFormSelect>
                                        )
                                    }

                                    <Fragment>
                                        <Controller
                                            name="message"
                                            control={control}
                                            defaultValue=""
                                            render={({ onChange, onBlur, value }) => (
                                                <TextField
                                                    ref={messageRef}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    label={t('SMS Templates.Fields.Message')}
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    margin="normal"
                                                    onClick={setCursorPosition}
                                                />
                                            )}
                                        />
                                        {availableAttributes.map((attr) => {
                                            return (
                                                <Chip
                                                    key={attr.id}
                                                    label={attr.name}
                                                    onClick={() => insertToken(attr.slug || attr.name)}
                                                    className="tagLikeChip"
                                                />
                                            );
                                        })}
                                    </Fragment>
                                </Fragment>
                            )
                        }

                        <div className="align-center wrapper content-justified mb15">
                            {t('SMS Templates.Fields.Public')}
                            <IOSSwitch
                                defaultChecked={defaultValues ? defaultValues.public : false}
                                color="primary"
                                name="public"
                                inputRef={register}
                            />
                        </div>

                        <div className="btnHolder hasPadTop twoBtn">
                            <div className="part">
                                <Button
                                    disabled={storeMutation.isLoading || updateMutation.isLoading}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                    onClick={(e) => {}}
                                >
                                    {t('Modals.Save')}
                                </Button>
                            </div>
                            <div className="part">
                                <Button
                                    disabled={storeMutation.isLoading || updateMutation.isLoading}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default SaveSMSTemplateModal;
