import React, {useEffect, useRef, useState} from 'react';
import { useAppDispatch } from '../../Contexts/AppContext';
import { useMutation, useQuery } from 'react-query';
import {balanceSubscribe, getSubscriptions} from '../../api';
import PlanList from '../../components/subscriptions/PlanList';
import SubscriptionList from '../../components/subscriptions/SubscriptionList';
import {useTranslation} from "react-i18next";
import { useAuth } from '../../Contexts/AuthProvider';

const Subscriptions = () => {
    const { subscriptions } = useAuth()
    const { t } = useTranslation();
    const formLiqPayRef = useRef(null)
    const [availablePlans, setAvailablePlans] = useState([]);
    const [liqpay, setForm] = useState({data: '', signature: ''})
    const [activeSubscriptions, setActiveSubscriptions] = useState([]);

    const {setBreadcrumbs, setSection, setContextMenu} = useAppDispatch();

    const subscriptionsRequest = useQuery(
        ['subscriptions'],
        () => getSubscriptions(),
        {keepPreviousData: true},
    );

    const subscribeMutation = useMutation(balanceSubscribe)

    useEffect(() => {
        setContextMenu([]);
    }, [setContextMenu]);

    //breadcrumbs
    useEffect(() => {
        setBreadcrumbs([
            // {label: 'Profile', link: '/change-password'},
            {label: t('Profile.Subscriptions')},
        ]);
        setSection('settings');
    }, [setBreadcrumbs, setSection, t]);

    useEffect(() => {
        if (subscriptionsRequest.isSuccess) {
            const {available_plans, active_subscriptions} = subscriptionsRequest.data
            setAvailablePlans(available_plans);
            setActiveSubscriptions(active_subscriptions);
        }
    }, [
        subscriptionsRequest.isSuccess,
        subscriptionsRequest.data
    ])


    const planSelect = async plan => {
        const data = await subscribeMutation.mutateAsync(plan.id)

        // old behavior
        if(data && data.hasOwnProperty('active_subscriptions')) {
            setActiveSubscriptions(data.active_subscriptions);
        // liqpay behavior
        } else if(data) {
            setForm(data.data)
            formLiqPayRef.current.submit()
        }
    }

    const disabled = subscribeMutation.isLoading || !!activeSubscriptions.length

    return (
        <div className="pageHolder">
            {
                activeSubscriptions && (
                    <SubscriptionList
                        items={subscriptions}
                    />
                )
            }
            <PlanList
                disabled={disabled}
                onPlanSelect={planSelect}
                items={availablePlans}
            />

            <form method="POST" action="https://www.liqpay.ua/api/3/checkout" acceptCharset="utf-8" ref={formLiqPayRef}>
                <input type="hidden" name="data" value={liqpay.data} />
                <input type="hidden" name="signature" value={liqpay.signature} />
            </form>

        </div>
    );
};

export default Subscriptions;
