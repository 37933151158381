import React, { Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import { ListItem, ListItemText } from '@material-ui/core';

const SelectSMSTemplateModal = props => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        options,
        onSelect,
    } = props;

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modal"
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className="mb15">{'Select template'}</h2>
                    </div>
                    <div>
                        <List disablePadding>
                            {
                                options.map(item => {
                                    const {name, message} = item
                                    return (
                                        <ListItem
                                            button
                                            key={item.id}
                                            onClick={() => onSelect(item.message)}
                                        >
                                            <ListItemText
                                                primary={name}
                                                secondary={message}
                                            />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                        <div className="btnHolder hasPadTop text-center">
                            {/*<div className="part">*/}
                                {/*<Button*/}
                                {/*    variant="contained"*/}
                                {/*    color="primary"*/}
                                {/*    fullWidth*/}
                                {/*    type="submit"*/}
                                {/*    onClick={(e) => {*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    {t('Modals.Save')}*/}
                                {/*</Button>*/}
                            {/*</div>*/}
                            {/*<div className="part">*/}
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    // fullWidth
                                    onClick={handleClose}
                                >
                                    {t('Modals.Cancel')}
                                </Button>
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
};

export default SelectSMSTemplateModal;
