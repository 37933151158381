import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppState } from '../Contexts/AppContext'
import PlusCircle from '../assets/img/plus_circle.svg'
import EventEmitter from '../Utils/EventEmitter'
import DeleteModal from '../components/modals/DeleteModal'
import SaveSMSGatewayModal from '../components/modals/SaveSMSGatewayModal'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getSMSGateways, removeSMSGateway } from '../api'
import ListTable from '../components/ListTable/ListTable'
import ShortUrlDomainItemContextMenu from '../components/ShortUrlDomainItemContextMenu'
import DisabledValue from '../components/DisabledValue';
import {useTranslation} from "react-i18next";

const SMSGateways = () => {
    const { t } = useTranslation();
    const {config, configIsLoading} = useAppState()
    const gatewaysOptions = useMemo(() => config['campaign_gateway_list'] || [], [config])
    const gatewaysAttributes = useMemo(() => config['setting_gateway_message_fields'] || {}, [config])
    const {setBreadcrumbs, setSection, setContextMenu} = useAppDispatch()

    const [pagination, setPagination] = useState(null)
    const [sorting, setSorting] = useState([])
    const [,setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [list, setList] = useState([])

    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [currentModel, setCurrentModel] = useState(false)
    const queryClient = useQueryClient()

    const tableColumns = useMemo(() => {

        const gatewaysLabelsMapping = {}
        gatewaysOptions.forEach(item => { gatewaysLabelsMapping[item.key] = item.label })

        return [
            {
                Header: t('SMS Gateways.Name'),
                accessor: 'name',
                Cell: ({value, row}) => row.original.default ? <DisabledValue value={value} /> : value
            },
            {
                Header: t('SMS Gateways.Gateway'),
                accessor: 'type',
                Cell: ({value, row}) => {
                    const label = gatewaysLabelsMapping[value] ?? ''
                    return row.original.default ? <DisabledValue value={label} /> : label
                }
            },
            {
                Header: t('SMS Gateways.Status'),
                accessor: 'active',
                Cell: ({value, row}) => {
                    const label = (
                        <div className={value ? "chipLike success" : "chipLike"}>
                            <span className="chipLikeLabel">{value ? 'Active' : 'Inactive'}</span>
                        </div>
                    )
                    return row.original.default ? <DisabledValue value={label} /> : label
                }
            },
            {
                Header: '',
                id: 'actions',
                className: 'text-right',
                Cell: ({value, row}) => {
                    if (row.original.default) return null;
                    return (
                        <ShortUrlDomainItemContextMenu
                            item={row.original}
                            onEdit={() => handleEditItem(row.original)}
                            onDelete={() => showDeleteConfirmation(row.original)}
                        />
                    )
                }
            }
        ]
    }, [gatewaysOptions, t])

    //breadcrumbs
    useEffect(() => {
        setBreadcrumbs([
            {label: t('SMS Gateways.Settings'), link: '/settings'},
            {label: t('SMS Gateways.SMS Gateways')}
        ])
        setSection('settings')
    }, [setBreadcrumbs, setSection, t])

    //context menu
    useEffect(() => {
        setContextMenu([
            {
                label: t('SMS Gateways.Add SMS Gateway'),
                icon: PlusCircle,
                action: () => EventEmitter.emit('SMSGatewayCreate'),
                disabled: configIsLoading
            },
        ])
    }, [setContextMenu, configIsLoading, t])

    useEffect(() => {
        const onEvent = () => setShowCreateModal(true)

        const listener = EventEmitter.addListener('SMSGatewayCreate', onEvent)
        return () => { listener.remove() }
    }, [])

    const listRequest = useQuery(
        ['smsGateways', sorting],
        () => getSMSGateways(sorting),
    {keepPreviousData: true}
    )

    useEffect(() => {
        if (listRequest.isSuccess) {
            setList(listRequest.data.data)
            setPagination(listRequest.data.meta)
        }
    },[listRequest.isSuccess, listRequest.data])

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])

    const deleteRequest = useMutation(removeSMSGateway)

    const handleEditItem = (item) => {
        if (item.default) return;
        setCurrentModel({
            ...item,
            ...item.params
        })
        setShowUpdateModal(true)
    }

    const showDeleteConfirmation = (item) => {
        setCurrentModel(item)
        setShowDeleteModal(true)
    }

    const deleteItem = async () => {
        if (!currentModel) return;

        await deleteRequest.mutateAsync(currentModel.id)
        setShowDeleteModal(false)
        await queryClient.invalidateQueries('smsGateways')
        setCurrentModel(null)
    }

    const modelLabel = currentModel ? `"${currentModel.name}" SMS gateway` : 'SMS gateway'

    return (
        <div className="pageHolder">
            <ListTable
                columns={tableColumns}
                data={list}
                loading={listRequest.isLoading}
                fetching={listRequest.isFetching}
                onSort={setSorting}
                emptyDataText={t('SMS Gateways.No SMS Gateway')}
                pagination={pagination}
                disablePagination
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                disableSearchBlock={true}
                onRowClick={handleEditItem}
            />

            <DeleteModal
                // loading={deleteShortUrlDomainRequest.isLoading}
                open={showDeleteModal}
                handleDelete={deleteItem}
                handleClose={() => setShowDeleteModal(false)}
                instance={modelLabel}
            />

            <SaveSMSGatewayModal
                gatewaysOptions={gatewaysOptions}
                gatewaysAttributes={gatewaysAttributes}
                open={showCreateModal}
                handleClose={() => {
                    setShowCreateModal(false)
                }}
            />

            {
                currentModel && (
                    <SaveSMSGatewayModal
                        gatewaysOptions={gatewaysOptions}
                        gatewaysAttributes={gatewaysAttributes}
                        defaultValues={currentModel}
                        open={showUpdateModal}
                        handleClose={() => {
                            setCurrentModel(null)
                            setShowUpdateModal(false)
                        }}
                    />
                )
            }
        </div>
    )
}

export default SMSGateways
