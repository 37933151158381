import React, { useEffect, useMemo, useState } from 'react'
import { Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { PrivateRoute } from '../../components/routes'
import CampaignStart from './CampaignStart'
import CampaignAudience from './CampaignAudience'
import CampaignStatistics from './CampaignStatistics'
import CampaignLogs from './CampaignLogs'
import { useAppDispatch, useAppState } from '../../Contexts/AppContext'
import CampaignActions from './CampaignActions'
import { useCampaignState } from '../../Contexts/CampaignContext'
import InlineLoader from '../../components/loaders/InlineLoader'
import { useMutation, useQueryClient } from 'react-query'
import { updateCampaign } from '../../api'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import AlertToastr from '../../Utils/alert'
import Chip from "@material-ui/core/Chip";
import {useTranslation} from "react-i18next";

const CampaignStatusMenu = ({campaign}) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient()
    const {config}=useAppState()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const [localStatus, setLocalStatus] = useState(() => campaign && campaign.status)
    const allCampaignStatuses = useMemo(() => {
        return config['campaign_status_list'] || []
    }, [config])
    const campaignStatuses = useMemo(() => {
        return config['campaign_manual_status_list'] || []
    }, [config])
    const campaignVisibleStatuses = useMemo(() => {
        const list = campaignStatuses.map(c => {
            c.label = t(`CampaignStatuses.${c.key}`)
            return c
        })

        return localStatus ? list.filter(item => item.key !== localStatus) : list
    }, [campaignStatuses, localStatus])
    const localStatusLabel = useMemo(() => {
        const statusModel = allCampaignStatuses.find(item => item.key === localStatus)
        return statusModel ? t(`CampaignStatuses.${statusModel.key}`) : ''
    } , [localStatus, allCampaignStatuses])

    const updateCampaignMutation = useMutation(updateCampaign)

    useEffect(() => {
        if (campaign) setLocalStatus(campaign.status)
    }, [campaign])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }


    const handleClose =  () => {
        setAnchorEl(null)
    }

    const setStatus = async (key, label) => {
        setAnchorEl(null)
        setLocalStatus(key)
        await handleChangeStatus(key)
    }

    const handleChangeStatus = async(status) => {
        if (campaign) {
            try {
                await updateCampaignMutation.mutateAsync({
                    id: campaign.id,
                    status
                })
                queryClient.invalidateQueries('campaignView').then()
                queryClient.invalidateQueries('campaignsList').then()
            } catch (error) {
                AlertToastr.showErrorResponseAlert(error)
                setLocalStatus(campaign.status)
            }
        }
    }


    return (
        <div className='campaignStatus'>
            { updateCampaignMutation.isLoading ? <InlineLoader /> : (
                localStatusLabel && <Chip
                    className={`tagLikeChip ${localStatus}`}
                    onClick={handleClick}
                    label={localStatusLabel}
                    style={{margin: '0'}}
                />
            )}

            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {
                    campaignVisibleStatuses.map(item => (
                        <MenuItem key={item.key} onClick={() => setStatus(item.key, item.label)}>{item.label}</MenuItem>
                    ))
                }
            </Menu>
        </div>
    )
}

const Campaign = () => {
    const { t } = useTranslation();

    const { campaign, campaignRequest } = useCampaignState()
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const {path, url} = useRouteMatch()
    const history = useHistory()
    const {pathname} = useLocation()
    const tabNameToIndex = useMemo(() => {
        return {
            0: `${url}`,
            1: `${url}/audience`,
            2: `${url}/statistics`,
            3: `${url}/logs`
        }
    }, [url])
    const indexToTabName = useMemo(() => {
        return {
            [`${url}`]: 0,
            [`${url}/audience`]: 1,
            [`${url}/statistics`]: 2,
            [`${url}/logs`]: 3
        }
    }, [url])
    const [value, setValue] = React.useState(() => {
        return indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0
    })

    const handleChange = (event, newValue) => {
        setValue(newValue)
        history.push(tabNameToIndex[newValue])
    }

    useEffect(() => {
        setBreadcrumbs([
            {label: t("Campaigns.Campaigns"), link: '/campaigns'},
            {label: campaign && campaign.name, loading: campaignRequest.isLoading}
        ])
        setSection('campaigns')
    }, [setBreadcrumbs, setSection, campaign, campaignRequest.isLoading, t])

    useEffect(() => {
        const index = indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0

        setValue(index)
    }, [pathname, indexToTabName])

    return (
        <div className="pageHolder">
            <div className='tabsWithStatusHolder'>
                <Tabs
                    style={{flexGrow: 1}}
                    value={value}
                    onChange={handleChange}
                    indicatorColor='primary'
                    className='pageTabs biggerPad'
                >
                    <Tab label={t("Campaigns.Campaign")} disableRipple/>
                    <Tab label={t("Campaigns.Audience")} disableRipple/>
                    <Tab label={t("Campaigns.Statistics")} disableRipple/>
                    <Tab label={t("Campaigns.Logs")} disableRipple/>
                </Tabs>
                { campaign && <CampaignStatusMenu campaign={campaign}/> }
            </div>

            <Switch>
                <PrivateRoute path={path} exact component={CampaignStart}/>
                <PrivateRoute path={`${path}/audience`} exact component={CampaignAudience}/>
                <PrivateRoute path={`${path}/statistics`} component={CampaignStatistics}/>
                <PrivateRoute path={`${path}/logs`} component={CampaignLogs}/>
            </Switch>
            <CampaignActions
                campaign={campaign}
                loading={campaignRequest.isLoading}
            />
        </div>
    )
}

export default Campaign
