import React from 'react'
import { NavLink } from 'react-router-dom'
import InlineLoader from '../loaders/InlineLoader'

const BreadcrumbItem = ({item}) => {

    const {label, link, loading} = item

    if (loading) {
        return <span className="breadcrumbsItem active"><InlineLoader size={20} /></span>
    }

    if (!link) {
        return <span className="breadcrumbsItem active">{label}</span>
    }

    return (
        <NavLink
            className="breadcrumbsItem"
            to={link}
            exact
        >
            {item.label}
        </NavLink>
    )
}

export default BreadcrumbItem
