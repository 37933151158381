import React, { useEffect, useState } from 'react'
import InlineLoader from './loaders/InlineLoader'
import { useQuery } from 'react-query'
import { getSourcesTotal } from '../api'
import {useTranslation} from "react-i18next";

const SourcesHeader = () => {
    const { t } = useTranslation();
    const [aggregations, setAggregations] = useState({
        total_leads: '-',
        total_today: '-',
        duplicates_today: '-'
    })

    const {isLoading, data} = useQuery('sourcesTotal', getSourcesTotal, { keepPreviousData : true })

    useEffect(() => {
        if (!data) return
        setAggregations(data.data)
    }, [data])

    const items = [
        {
            label: t('Sources Header.Total Leads'),
            count: aggregations['total_leads']
        },
        {
            label: t('Sources Header.Today'),
            count: aggregations['total_today']
        },
        {
            label: t('Sources Header.Duplicate today'),
            count: aggregations['duplicates_today']
        }
    ]

    return (
        <div className="pageHeadingBlockWrapper">
            {
                items.map(item => (
                    <div className="part" key={item.label}>
                        <div className="partContent">
                            <h2 className="mb10">{item.label}</h2>
                            <div>{isLoading ? <InlineLoader /> : item.count}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default SourcesHeader
