import React from 'react';
import EventEmitter from './Utils/EventEmitter';
import { t } from 'i18next';

export const getHtmlSortClass = (direction, isFetching = false) => {
    const className = direction === 'asc' ? 'hasArrow' : 'hasArrow up';
    return isFetching ? `${className} loading` : className;
};

export const formatLeadLog = logData => {
    const logInfo = []
    logData.forEach((log) => {

        if (log.type === 'auto' || log.type === 'manual') {
            const details = []
            log.diff_log.forEach((diffLog) => {
                let detailInfo = ''
                if (diffLog.action === 'added_to_black_list') {

                    detailInfo = `${diffLog.value_to} ${t('LeadLog.added to black list')}`
                } else if (diffLog.action === 'detected_ignored_phone_number_template') {
                    detailInfo = `${diffLog.value_to} ${t('LeadLog.(matched with ignored phone template)')}`
                } else if (diffLog.action === 'detected_invalid_phone_number_format') {
                    detailInfo = `${diffLog.value_to} ${t('LeadLog.(detected invalid format)')}`

                } else {
                    detailInfo = diffLog.action === 'updated'
                        ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}`
                        : `${diffLog.value_to ? diffLog.value_to : ''}`
                }
                let detail = {
                    attribute: diffLog.lead_attribute_name,
                    detail: detailInfo
                }
                details.push(detail)
            })

            let logAction = ''
            if (log.action === 'resolving_duplicates') {

                logAction = `${t('LeadLog.Lead duplicated from')} ${log.filename}`
            } else if (log.action === 'attribute_created') {
                logAction = `${t('LeadLog.Lead attribute created manually')}`
            } else if (log.action === 'created') {
                logAction = `${t('LeadLog.Lead created manually')}`
            } else if (log.action === 'updated') {
                logAction = `${t('LeadLog.Lead updated manually')}`
            } else {
                logAction = `${t('LeadLog.Lead imported from')} ${log.filename}`
            }


            const logLine = {
                date: log.created_at,
                message: logAction,
                details: details
            }
            logInfo.push(logLine)
        }
        else if (log.type === 'phone_carrier') {

            const details = []
            let phone = log.filename ?? 'xxx'

            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_value,
                    detail: diffLog.action === 'updated' ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}` : `${diffLog.value_to ? diffLog.value_to : ''}`
                }
                details.push(detail)
            })

            const logLine = {
                date: log.created_at,

                message: `${t('LeadLog.Phone')} ${phone} ${t('LeadLog.carrier info has been looked up')}`,

                details: details
            }
            logInfo.push(logLine)
        }
        else if (log.type === 'phone_status') {

            const details = []
            let phone = log.filename ?? 'xxx'

            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_value,
                    detail: diffLog.action === 'updated' ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}` : `${diffLog.value_to ? diffLog.value_to : ''}`
                }
                details.push(detail)
            })

            const logLine = {
                date: log.created_at,

                message: `${t('LeadLog.Phone')} ${phone} ${t('LeadLog.status was updated')}`,

                details: details
            }
            logInfo.push(logLine)
        }
        else if (log.type === 'phone_campaign') {

            const details = []
            let campaign = log.filename ?? 'xxx'
            const logLine = {
                date: log.created_at,

                message: `${t('LeadLog.Lead has been added to')} ${campaign} ${t('LeadLog.Campaign')}.`,

                details: details
            }
            logInfo.push(logLine)
        }
        else if (log.type === 'link_clicked') {

            const details = []
            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_value,
                    detail: diffLog.action === 'updated' ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}` : `${diffLog.value_to ? diffLog.value_to : ''}`
                }
                details.push(detail)
            })
            const logLine = {
                date: log.created_at,
                message: t('LeadLog.Clicked link of campaign', {name: log.filename ?? ''}),
                details: details
            }
            logInfo.push(logLine)
        }
        else if (log.type === 'phone_campaign_sent_message')
        {

            const details = []
            let campaign = log.filename ?? 'xxx'

            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_value,
                    detail: diffLog.action === 'updated' ? `${diffLog.value_from ? diffLog.value_from : ''} -> ${diffLog.value_to ? diffLog.value_to : ''}` : `${diffLog.value_to ? diffLog.value_to : ''}`
                }
                details.push(detail)
            })

            const logLine = {
                date: log.created_at,

                message: log.action === 'created' ? `${t('LeadLog.Campaign')} ${campaign} ${t('LeadLog.has been sent message to this Lead')}.` : `${t('LeadLog.The sent message to')} ${campaign} ${t('LeadLog.has been updated')}`,

                details: details
            }
            logInfo.push(logLine)
        } else if (log.type === 'single_sms') {

            const details = []
            const logLine = {
                date: log.created_at,

                message: t('LeadLog.Lead created with Single SMS Feature'),

                details: details
            }
            logInfo.push(logLine)
        }  else if (log.type === 'send_single_sms') {
            const details = []
            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_name,
                    detail: diffLog.value_to
                }
                details.push(detail)
            })

            const logLine = {
                date: log.created_at,

                message: t('LeadLog.Single SMS has been sent'),

                details: details
            }
            logInfo.push(logLine)

        }  else if (log.type === 'send_single_sms_check_campaign') {
            const details = []
            let campaign = log.filename ?? ''
            log.diff_log.forEach((diffLog) => {
                let detail = {
                    attribute: diffLog.lead_attribute_name,
                    detail: diffLog.value_to
                }
                details.push(detail)
            })

            const logLine = {
                date: log.created_at,
                message: t('Leads History.Single SMS has been sent', {campaign}),
                details: details
            }
            logInfo.push(logLine)
        } else {
            let detail = {
                attribute: log.diff_log[0].lead_attribute_name,
                detail: log.diff_log[0].action === 'updated' ? `${log.diff_log[0].value_from ? log.diff_log[0].value_from : ''} -> ${log.diff_log[0].value_to}` : log.diff_log.value_to
            }
            const logLine = {
                date: log.created_at,

                message: `${log.diff_log[0].lead_attribute_name} ${t('LeadLog.was updated')}`,

                details: [
                    detail
                ]
            }
            logInfo.push(logLine)
        }
    })
    return logInfo
}

export const formatLeadAttributes = (leadValues) => {
    const leadAttributes = leadValues.map(attr => {
        const attrObj = {
            id: attr.id,
            name: attr.attribute.name,
            value: attr.value,
            // newValue: attr.value,
            // isEditing: false,
            position: attr.attribute.sort_order,
            type: attr.attribute.type,
            status: attr.status,
            status_label: attr.status_label,
        };
        if (attr.attribute.type === 'phone') {
            attrObj.carrier_name = attr.carrier_name;
            attrObj.carrier_type = attr.carrier_type;
            attrObj.clicked = attr.clicked;
            attrObj.ban = attr.ban;
        }
        return attrObj;
    });

    let sortedAttributes = [...leadAttributes];
    sortedAttributes.sort((a, b) => {
        if (a.position < b.position) {
            return -1;
        }
        if (a.position > b.position) {
            return 1;
        }
        return 0;
    });
    return sortedAttributes;
};

export const formatBlackListRow = ({ value, row }) => {
    const v = value || value === 0 ? value : '-';
    return row.original.status === 'blacklisted'
        ? <span style={{ opacity: .5 }}>{v}</span>
        : v;
};

export const snakeToTitle = (str) => {
    return str.toString().split('_').map(function (item) {
        return item.charAt(0).toUpperCase() + item.substring(1);
    }).join(' ');
};


export const attrToTitle = (name = '') => {

    const nameToTile = {
        'status': t('Campaigns.Status'), 'processing': t('CampaignStatuses.processing'), 'scheduled': t('CampaignStatuses.scheduled'),
        'active': t('CampaignStatuses.active'), 'draft': t('CampaignStatuses.draft'), 'paused': t('CampaignStatuses.paused'),
        'completed': t('CampaignStatuses.completed'), 'failed': t('CampaignStatuses.failed'),
        'name': t('Modals.Name'), 'sent': t('Campaigns.Sent'), 'sending': t('Charts.Sending'), 'position': t('CampaignLog.Position')
    }

    if((typeof name === 'string' || name instanceof String) && nameToTile.hasOwnProperty(name.toLowerCase())) {
        return nameToTile[name.toLowerCase()]
    }

    return name
}

export const formatCampaignLog = logData => {
    return logData.map(log => {
        let descType = snakeToTitle(log.type)

        if (log.type === 'campaign_created') {
            descType = t('CampaignLog.campaign_created')
        } else if (log.type === 'campaign_segment_add') {
            descType = t('CampaignLog.campaign_segment_add')
        } else if (log.type === 'campaign_step_created') {
            descType = t('CampaignLog.campaign_step_created')
        } else if (log.type === 'campaign_updated') {
            descType = t('CampaignLog.campaign_updated')
        } else if (log.type === 'campaign_step_updated') {
            descType = t('CampaignLog.campaign_step_updated')
        } else if (log.type === 'campaign_policy_not_enough_balance') {
            descType = t('CampaignLog.campaign_policy_not_enough_balance')
            const newData = {...log.data, attributes: [{name: 'Name', value_to: descType}]}
            return {...log, desc: descType, data: newData}
        } else if (log.type === 'campaign_segment_deleted') {
            descType = t('CampaignLog.campaign_segment_deleted')
        }

        return {...log, desc: descType}
    })
}

export const translateTitle = (title) => {
    switch (snakeToTitle(title)) {
        case 'Draft':
            return t('LeadLog.Draft')
        case 'Scheduled':
            return t('LeadLog.Scheduled')
        case 'Paused':
            return t('LeadLog.Paused')
        case 'Flagged':
            return t('LeadLog.Flagged')
        case 'Active':
            return t('LeadLog.Active')
        case 'Blacklisted':
            return t('LeadLog.Blacklisted')
        case 'ActiveCampaign':
            return t('LeadLog.Active Campaign')
        case 'There is not enough balance on the account!':
            return t('CampaignLog.campaign_policy_not_enough_balance')
        default:
            return snakeToTitle(title)
    }

}

export const statusContextMenu = () => {
    return [
        {
            label: t('LeadLog.Active'),
            action: () => EventEmitter.emit('LeadsBulkChangeStatus', 'active'),
            status: 'active',
        },
        {
            label: t('LeadLog.Blacklisted'),
            action: () => EventEmitter.emit('LeadsBulkChangeStatus', 'blacklisted'),
            status: 'blacklisted',
        },
        {
            label: t('LeadLog.Flagged'),
            action: () => EventEmitter.emit('LeadsBulkChangeStatus', 'flagged'),
            status: 'flagged',
        },
    ];
};

export const statusClassMap = () => ({
    active: 'success',
    pending: 'pending',
    blacklisted: 'blackList',
    flagged: 'flagged',
    inactive: 'inactive',
});

export const lookupStatusClassMap = () => ({
    0: 'pending',
    1: 'success',
    2: 'paused',
    3: 'completed',
});


export const campaignStatuses = () => {
    return ['draft', 'scheduled', 'paused', 'activeCampaign'].map(item => (
        { key: item, label: translateTitle(item) }
    ));
};

export const campaignStatusContextMenu = (statuses = ['draft', 'scheduled', 'paused']) => {
    return statuses.map(item => ({
        label: translateTitle(item),
        action: () => EventEmitter.emit('CampaignsBulkChangeStatus', item),
        status: item,
    }));
};

export const campaignStatusClassMap = () => ({
    active: 'success',
    draft: 'pending',
    scheduled: 'scheduled',
    paused: 'paused',
    completed: 'completed',
    failed: 'failed',
});

export const campaignStepEditDisabled = (campaign, step, isLast = false) => {
    if (!campaign || !step) return true;

    if (['active', 'completed', 'failed'].includes(campaign.status)) return true;
    if (campaign.status === 'draft') return false;

    if (campaign.status === 'scheduled') {
        return step.status !== 'not_sent';
    }

    if (campaign.status === 'paused') {
        return !((step.status === 'not_sent') && !isLast);
    }

    return false;
};

export const campaignStepDeleteDisabled = (campaign, step, isLast = false) => {
    if (!campaign || !step) return true;

    if (['active', 'completed', 'failed'].includes(campaign.status)) return true;
    if (campaign.status === 'draft') return false;

    if (['scheduled', 'paused'].includes(campaign.step)) {
        return !((step.status === 'not_sent') && !isLast);
    }

    return false;
};

export const filterHasEmptyValuesItems = (filterArray) => {

    const hasFiltersWithEmptyOperators = filterArray.filter(item => !item.operator).length;

    if (hasFiltersWithEmptyOperators) return true;

    const hasFiltersWithEmptyValues = filterArray.filter(item => {
        if (!item.operator) return true;

        if (['empty', 'not_empty'].includes(item.operator)) return false;

        if (!item.values) return true;

        if (!Array.isArray(item.values)) return true;

        return !item.values.length;
    }).length;

    return hasFiltersWithEmptyValues;

    // const resultArray = filterArray
    //     .filter(item => {
    //         return ['eq', 'not_eq'].includes(item.operator)
    //     })
    //     .filter(item  => {
    //         return item.operator
    //     })
    //     .filter(item => {
    //         if (!item.values) return true
    //         return Array.isArray(item.values) && !item.values.length;
    //     })
    //
    // return !!resultArray.length
};

export const filterObjectArray = (filtersIds) => {
    const availableTranslateFilters = {
        'contains': t('LeadLog.Contains'),
        'not_contains': t('LeadLog.Not Contains'),
        'eq': t('LeadLog.Equals'),
        'not_eq': t('LeadLog.Not Equal'),
        'eq_strict': t('LeadLog.Equals'),
        'not_eq_strict': t('LeadLog.Not Equal'),
        'empty': t('LeadLog.Empty'),
        'not_empty': t('LeadLog.Not Empty'),
        'unique': t('LeadLog.Unique'),
        'first_appeared_in_list': t('LeadLog.First Appeared'),
    };

    return filtersIds.filter(fId => Boolean(availableTranslateFilters[fId])).map(fId => {
        return { id: fId, name: availableTranslateFilters[fId] }
    })
};

export const filterAttributeMap = (attr) => {
    const map = {
        'source': ['eq', 'not_eq', 'unique'],
        'list': ['eq', 'not_eq', 'unique', 'first_appeared_in_list'],
        'first_appeared_in_list': ['eq'],
        'status': ['eq', 'not_eq'],
        'engagement': ['eq', 'not_eq'],
        'engagement_period': ['eq'],
        'sms_status': ['eq', 'not_eq'],
        'campaigns': ['eq_strict', 'not_eq_strict'],
        'duplicates': ['eq', 'not_eq'],
    };

    if (map[attr.id]) {
        return filterObjectArray(map[attr.id]);
    }

    return filterObjectArray([
        'contains', 'not_contains',
        'eq', 'not_eq',
        'empty', 'not_empty',
    ]);
};

export const defaultAttributeFilter = (attr) => {
    const filters = filterAttributeMap(attr);

    return filters[0] ? filters[0].id : null;
};

export const teamRoleOptions = () => {
    const roleOptions = [
        { key: 'team_admin', label: t('Team.Roles.Admin') },
        { key: 'team_operator', label: t('Team.Roles.Operator') },
    ];
    return roleOptions;
};

