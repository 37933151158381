import React, { useEffect, useMemo } from 'react';
import { Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { PrivateRoute } from '../../components/routes';
import ChangePasswordPage from '../auth/ChangePasswordPage';
import {useTranslation} from "react-i18next";
import Subscriptions from './Subscriptions';
import UserActionsTest from './UserActionsTest';
import TransactionsOld from "./TransactionsOld";

const Profile = () => {
    const { t } = useTranslation();
    const {path, url} = useRouteMatch()
    const history = useHistory()
    const {pathname} = useLocation()

    const tabNameToIndex = useMemo(() => {
        return {
            0: `${url}`,
            1: `${url}/change-password`,
            2: `${url}/transactions`,
            3: `${url}/test`,
        }
    }, [url])

    const indexToTabName = useMemo(() => {
        return {
            [`${path}`]: 0,
            [`${path}/change-password`]: 1,
            [`${path}/transactions`]: 2,
            [`${path}/test`]: 3,
        }
    }, [path])

    const [value, setValue] = React.useState(() => {
        return indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push(tabNameToIndex[newValue])
    };

    useEffect(() => {
        const index = indexToTabName[pathname] !== undefined ? indexToTabName[pathname] : 0
        setValue(index)
    }, [pathname, indexToTabName])

    return (
        <div className="pageHolder">
            <Tabs value={value} onChange={handleChange} indicatorColor='primary' className='pageTabs'>
                <Tab label={t("Profile.Subscriptions")} disableRipple/>
                <Tab label={t("Profile.Change Password")} disableRipple/>
                <Tab label={t("Profile.Transactions")} disableRipple/>
                <Tab label="Test" disableRipple/>
            </Tabs>
            <Switch>
                <PrivateRoute path={`${path}`} component={Subscriptions} exact/>
                <PrivateRoute path={`${path}/change-password`}  component={ChangePasswordPage}/>
                <PrivateRoute path={`${path}/transactions`} component={TransactionsOld}/>
                <PrivateRoute path={`${path}/test`} component={UserActionsTest}/>
            </Switch>
        </div>
    )
};

export default Profile;
