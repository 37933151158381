import React from 'react'
import InlineLoader from './loaders/InlineLoader'
import {useTranslation} from "react-i18next";

const SourceHeader = ({ source, loading }) => {
    const { t } = useTranslation();
    const renderName = () => {
        return loading ? <InlineLoader size={30} /> : source?.name
    }

    const items = [
        {
            label: t('Source Header.Total Leads'),
            count: source?.total_leads
        },
        {
            label: t('Source Header.Today'),
            count: source?.total_today
        },
        {
            label: t('Source Header.Last 30 Days'),
            count: source?.total_last_30_days
        },
        {
            label: t('Source Header.Duplicate'),
            count: source?.duplicate
        }
    ]

    return (
        <div className="pageHeadingBlockWrapper">
            <div className="part main">
                <div className="partContent">
                    <div className="hasSwitch mb15">
                        <h2 className="textPrimary">{renderName()}</h2>
                    </div>
                </div>
            </div>
            {
                items.map(item => (
                    <div className="part" key={item.label}>
                        <div className="partContent">
                            <h2 className="mb10">{item.label}</h2>
                            <div>{loading ? <InlineLoader /> : item.count}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default SourceHeader
