import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getSegmentLeads } from '../api'
import ListTable from './ListTable/ListTable'
import { formatBlackListRow } from '../helpers'
import {toServerDatetimeFormat} from '../Utils/dates'
import LeadStatus from './LeadStatus'
import { storageKeys } from '../config'
import {useTranslation} from "react-i18next";

const SegmentLeads = ({segmentId, setSelectedRows, setVisibleColumns, onSearch, onSorting}) => {
    const { t } = useTranslation();
    const leadValue = formatBlackListRow
    const [search, setSearch] = useState()
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const history = useHistory()

    const [leads, setLeads] = useState([])
    const [leadsAttributes, setLeadsAttributes] = useState([])
    const [pagination, setPagination] = useState(null)
    const tableColumns = useMemo(() => {
        const attributes = leadsAttributes.map(attr => {
            return {
                Header: attr.name,
                accessor: `${attr.id}`,
                Cell: leadValue
            }
        })
        return [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: leadValue
            },
            {
                Header: t('Segment Leads.Updated Date'),
                accessor: 'updated_at',
                Cell: ({value, row}) => {
                    return leadValue({value: toServerDatetimeFormat(value), row})
                }
            },
            ...attributes,
            {
                Header: t('Segment Leads.Duplicates'),
                accessor: 'duplicates',
                Cell: leadValue
            },
            // {
            //     Header: t('Segment Leads.Status'),
            //     accessor: 'status',
            //     className: 'col-sticky text-right',
            //     Cell: ({value}) => <LeadStatus status={value} />
            // }
        ]
    }, [leadsAttributes, leadValue, t])
    const tableValues = useMemo(() => {
        if (!leads) return []
        return leads.map(item => {
            const leadValues = item.lead_values
            const newItem = {...item}
            leadValues.forEach(value => {
                const attrId = value && value.attribute && value.attribute.id
                if (attrId)
                    newItem[attrId] = newItem[attrId]
                        ? `${newItem[attrId]}; ${value.value}`
                        : value.value
            })
            delete newItem['lead_values']
            return newItem
        })
    }, [leads])
    const tableRecordsInfo = useMemo(() => {
        const info = []
        if (pagination) {
            if (pagination.total)
                info.push({count: pagination.total, label: t('Sources.Leads')})
            if (pagination.total_blacklisted)
                info.push({count: pagination.total_blacklisted, label: t('Sources.Blacklisted')})
        }
        return info
    }, [pagination])

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ['segmentLeads', segmentId, page, limit, search, sorting],
        () => getSegmentLeads(segmentId, page, limit, search, sorting),
        {keepPreviousData: true}
    )

    useEffect(() => {
        onSorting(sorting)
    }, [sorting, onSorting])

    useEffect(() => {
        onSearch(search)
    }, [search, onSearch])

    useEffect(() => {
        if (isSuccess) {
            setLeads(data.data)
            setPagination(data.meta)
            if (data.attributes) {
                setLeadsAttributes(data.attributes)
            }
        }
    }, [isSuccess, data])

    const handleItemClick = item => {
        // history.push(`/audiences/${segmentId}/${item?.id}`)
        history.push(`/leads/${item?.id}`)
    }

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])

    const handleSearchChange = useCallback((str) => {
        setPage(1)
        setSearch(str)
    }, [setPage, setSearch])

    return (
        <ListTable
            columns={tableColumns}
            data={tableValues}
            loading={isLoading}
            fetching={isFetching}
            isSelectionColumn={false}
            onSelectedRows={setSelectedRows}
            onRowClick={handleItemClick}
            onSort={setSorting}
            emptyDataText={t('Segment Leads.No Leads Found')}
            pagination={pagination}
            pageLimit={limit}
            onPageChange={setPage}
            onLimitChange={handleLimitChange}
            onSearch={handleSearchChange}
            onHideColumns={setVisibleColumns}
            recordsInfo={tableRecordsInfo}
            hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInSegment}
        />
    )
}

export default SegmentLeads
