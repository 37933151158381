import React, { Fragment, useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IOSSwitch from '../IOSSwitch'
import MenuItem from '@material-ui/core/MenuItem'
import ReactHookFormSelect from '../form/ReactHookFormSelect'
import { useForm } from 'react-hook-form'
import { useCampaignState } from '../../Contexts/CampaignContext'
import { useMutation } from 'react-query'
import { storeSegmentFromCampaign } from '../../api'
import AlertToastr from '../../Utils/alert'
import { useHistory } from 'react-router-dom'
import {useTranslation} from "react-i18next";

const SaveCampaignSegmentModal = props => {
    const { t } = useTranslation();
    const [title, setTitle] = useState(t('Modals.Create Segment'))
    const {smsStatuses, engagements} = useCampaignState()
    const {availableSegments} = useCampaignState()
    const [isUpdateDynamically, setIsUpdateDynamically] = useState(true)
    const [createdSegment, setCreatedSegment] = useState(null)
    const history = useHistory()

    const {
        open,
        handleClose,
        campaign
    } = props

    const {
        control,
        register,
        handleSubmit,
    } = useForm({
        mode: 'onTouched'
    })

    const storeSegmentFromCampaignMutation = useMutation(storeSegmentFromCampaign)

    useEffect(() => {
        if (storeSegmentFromCampaignMutation.error) {
            AlertToastr.showErrorResponseAlert(storeSegmentFromCampaignMutation.error)
        }
    }, [storeSegmentFromCampaignMutation.error])

    const onSubmit = handleSubmit(async (data) => {
        const response = await storeSegmentFromCampaignMutation.mutateAsync({
            id: campaign.id,
            ...data,
            ignore_new_leads: isUpdateDynamically,
            sms_status: smsStatuses,
            engagement: engagements
        })
        setTitle(t('Modals.Segment has been created'))
        setCreatedSegment(response.data)
    })

    const onClose = () => {
        setTitle(t('Modals.Create Segment'))
        setCreatedSegment(null)
        setIsUpdateDynamically(true)
        handleClose()
    }

    const renderForm = () => (
        <form onSubmit={onSubmit}>
            <ReactHookFormSelect
                disabled={false}
                variant="outlined"
                margin="normal"
                id="type-select"
                name="audience_id"
                label={t("Modals.Audience")}
                control={control}
                defaultValue={availableSegments[0] && availableSegments[0].id}
            >
                {availableSegments.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </ReactHookFormSelect>
            <TextField
                inputRef={register}
                name="name"
                id="source"
                label={t("Modals.Name")}
                variant="outlined"
                margin='normal'
            />
            <div className="align-center wrapper content-justified">
                {t("Modals.Update dynamically")}
                <IOSSwitch
                    color="primary"
                    checked={!!isUpdateDynamically}
                    onChange={() => {
                        setIsUpdateDynamically(!isUpdateDynamically)
                    }}
                />
            </div>

            <div className="btnHolder hasPadTop twoBtn">
                <div className="part">
                    <Button
                        disabled={false}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={onClose}
                    >
                        {t('Modals.Cancel')}
                    </Button>
                </div>
                <div className="part">
                    <Button
                        type="submit"
                        disabled={storeSegmentFromCampaignMutation.isLoading}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        {t('Modals.Create')}
                    </Button>
                </div>
            </div>
        </form>
    )

    const renderSuccessButtons = () => {
        return (
            <div className="btnHolder hasPadTop twoBtn">
                <div className="part">
                    <Button
                        type="submit"
                        disabled={false}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => history.push(`/audiences/${createdSegment?.parent?.id}/segments/${createdSegment?.id}`)}
                    >
                        {t('Modals.Go to Segment')}
                    </Button>
                </div>
                <div className="part">
                    <Button
                        disabled={false}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={onClose}
                    >
                        Ok
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                className='modal'
            >
                <div className="modalBody">
                    <div className="modalTitleHolder mb30">
                        <h2 className='mb15'>{title}</h2>
                    </div>
                    {createdSegment ? renderSuccessButtons() : renderForm()}
                </div>
            </Dialog>
        </Fragment>
    )
}

export default SaveCampaignSegmentModal
