import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CampaignStatisticsHeader from '../../components/CampaignStatisticsHeader'
// import { useCampaignState } from '../../Contexts/CampaignContext'
// import CampaignPieChart from '../../components/charts/CampaignPieChart'
// import CampaignBarChart from '../../components/charts/CampaignBarChart'
import { useQuery } from 'react-query'
import { getCampaignStatistics, getCampaignStatisticsLeads } from '../../api'
import { useHistory, useParams } from 'react-router-dom'
import { formatBlackListRow } from '../../helpers'
import ListTable from '../../components/ListTable/ListTable'
import {toServerDatetimeFormat} from '../../Utils/dates'
import LeadStatus from '../../components/LeadStatus'
import HCPie from '../../components/charts/HCPie'
import HCBar from '../../components/charts/HCBar'
import PageLoader from '../../components/loaders/PageLoader'
import CampaignStatisticFilters from '../../components/CampaignStatisticFilters'
import { storageKeys } from '../../config'
import {useTranslation} from "react-i18next";

// const filterTypeOptions = [
//     {key: 'amount_short_link', label: 'Short Link'},
//     {key: 'amount_eng', label: 'Engaged'},
//     {key: 'amount_eng_last', label: 'Engaged last 90 days'},
//     {key: 'amount_not_eng', label: 'Not Engaged'},
//     {key: 'amount_sent', label: 'Sent'},
//     {key: 'amount_status_sent', label: 'Status sent'},
//     {key: 'amount_status_delivered', label: 'Status Delivered'},
//     {key: 'amount_status_queued', label: 'Status Queued'},
//     {key: 'amount_status_sending', label: 'Status Sending'},
//     {key: 'amount_status_error', label: 'Status Error'},
//     {key: 'amount_status_blocked', label: 'Status Blocked'},
//     {key: 'amount_status_inactive', label: 'Status Inactive'},
// ]

const CampaignStatistics = () => {
    // const {campaign} = useCampaignState()
    const { t } = useTranslation();
    const {campaignId} = useParams()
    const [campaignStat, setCampaignStat] = useState({})
    const [, setStepStats] = useState([])

    const leadValue = formatBlackListRow
    const [filters, setFilters] = useState({})
    const [leads, setLeads] = useState([])
    const [leadsAttributes, setLeadsAttributes] = useState([])
    const [, setSelectedRows] = useState([])
    const [pagination, setPagination] = useState(null)
    const [search, setSearch] = useState()
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [filterType, setFilterType] = useState(null)
    const history = useHistory();
    const tableColumns = useMemo(() => {
        const attributes = leadsAttributes.map(attr => {
            return {
                Header: attr.name,
                accessor: `${attr.id}`,
                Cell: leadValue
            }
        })
        return [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: leadValue
            },
            {
                Header: t('Campaigns.Updated Date'),
                accessor: 'updated_at',
                Cell: ({value, row}) => {
                    return leadValue({value: toServerDatetimeFormat(value), row})
                }
            },
            ...attributes,
            {
                Header: t('Campaigns.Duplicates'),
                accessor: 'duplicates',
                Cell: leadValue
            },
            {
                Header: t('Campaigns.Status'),
                accessor: 'status',
                className: 'text-right',
                Cell: ({value}) => <LeadStatus status={value} />
            }
        ]
    }, [leadsAttributes, leadValue, t])
    const tableValues = useMemo(() => {
        return leads.map(item => {
            const leadValues = item.lead_values
            const newItem = {...item}
            leadValues.forEach(value => {
                const attrId = value && value.attribute && value.attribute.id
                if (attrId)
                    newItem[attrId] = newItem[attrId]
                        ? `${newItem[attrId]}; ${value.value}`
                        : value.value
            })
            delete newItem['lead_values']
            return newItem
        })
    }, [leads])

    const tableRecordsInfo = useMemo(() => {
        const info = []
        if (pagination) {
            if (pagination.total)
                info.push({count: pagination.total, label: t('Sources.Leads')})
            if (pagination.total_blacklisted)
                info.push({count: pagination.total_blacklisted, label: t('Sources.Blacklisted')})
        }
        return info
    }, [pagination])

    const campaignStatisticsRequest = useQuery(
        ['campaignStatistics', campaignId],
        () => getCampaignStatistics(campaignId),
        {keepPreviousData: true}
    )
    useEffect(() => {
        if (campaignStatisticsRequest.isSuccess) {
            setCampaignStat(campaignStatisticsRequest.data.data.campaign)
            setStepStats(campaignStatisticsRequest.data.data.campaign_steps)
            // setCampaign(campaignRequest.data.data)
        }
    }, [campaignStatisticsRequest.isSuccess, campaignStatisticsRequest.data])

    const campaignStatisticsLeadsRequest = useQuery(
        ['campaignStatisticsLeads', campaignId, page, limit, search, sorting, filterType, filters],
        () => getCampaignStatisticsLeads(campaignId, page, limit, search, sorting, filterType, filters),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (campaignStatisticsLeadsRequest.isSuccess) {
            setLeads(campaignStatisticsLeadsRequest.data.data)
            setPagination(campaignStatisticsLeadsRequest.data.meta)

            if (campaignStatisticsLeadsRequest.data.attributes) {
                setLeadsAttributes(campaignStatisticsLeadsRequest.data.attributes)
            }
        }
    }, [campaignStatisticsLeadsRequest.isSuccess, campaignStatisticsLeadsRequest.data])

    const handleFilter = filter => {
        setFilterType(filter)
        // console.log('filter: ' + filter)
    }

    const handleItemClick = item => {
        history.push(`/leads/${item?.id}`)
    }

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])

    const handleSearchChange = useCallback((str) => {
        setPage(1)
        setSearch(str)
    }, [setPage, setSearch])

    const renderNoStatisticsData = () => {
        return (
            <div className="pageHolder" style={{paddingTop: '15px', color: 'rgb(144 144 144)', fontSize: '14px'}}>
                <p className="text-center">{t("Campaigns.Statistic is unavailable")}</p>
                <p className="text-center">{t("Campaigns.There is no data available to build a statistic")}</p>
            </div>
        )
    }

    if (!campaignStat.status || campaignStatisticsRequest.isLoading) {
        return (
            <div className="pageHolder">
                <PageLoader />
            </div>
        )
    }

    if (!campaignStat.status || campaignStat.status === 'draft' || campaignStat.status === 'scheduled') {
        return renderNoStatisticsData()
    }

    const renderHeader = () =>  {
        return (
            <div>
                <div className="mt15 mb15" style={{
                    display: 'flex'
                }}>
                    <div className="pageHeadingBlock "
                         style={{display: 'flex', flexDirection: 'column', padding: '10px', marginRight: '15px'}}>
                        <div style={{width: '350px', height: '250px'}} >
                            <HCPie campaignStat={campaignStat} currentFilter={filterType} />
                        </div>
                    </div>
                    <div style={{flexGrow: 1}}>
                        <div className="pageHeadingBlock" style={{paddingTop: '10px'}}>
                            <h2 style={{fontSize: '18px', marginBottom: '25px'}} className="text-center">
                                {t("Campaigns.Lead Engagement Statistic")}
                            </h2>
                            <CampaignStatisticsHeader
                                loading={campaignStatisticsLeadsRequest.isLoading}
                                campaignStat={campaignStat}
                                onFilter={handleFilter}
                            />
                        </div>
                        <div className="pageHeadingBlock mt15" style={{padding: '10px'}}>
                            <HCBar campaignStat={campaignStat} currentFilter={filterType} />
                        </div>
                    </div>
                </div>
                {/*<div><pre style={{fontSize: '0.7em'}}>{JSON.stringify(campaignStat, null, 2)}</pre></div>*/}
                {/*<div><pre style={{fontSize: '0.7em'}}>{JSON.stringify(stepsStat, null, 2)}</pre></div>*/}
            </div>
        )
    }

    return (
        <div className="pageHolder">
            {renderHeader()}
            <CampaignStatisticFilters onChange={setFilters} />
            {/*{renderTypeFilter()}*/}
            <ListTable
                columns={tableColumns}
                data={tableValues}
                loading={campaignStatisticsLeadsRequest.isLoading}
                fetching={campaignStatisticsLeadsRequest.isFetching}
                isSelectionColumn={true}
                onSelectedRows={setSelectedRows}
                onRowClick={handleItemClick}
                onSort={setSorting}
                emptyDataText={t('Campaigns.No Leads Found')}
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                onSearch={handleSearchChange}
                recordsInfo={tableRecordsInfo}
                hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInCampaign}
            />
        </div>
    )
}

export default CampaignStatistics
