import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import {toServerDatetimeFormat} from '../Utils/dates'
import {useTranslation} from "react-i18next";
import SmsCounter from "./modals/SmsCounter";

const CampaignStepItem = ({item, onEdit = () => {}, onDelete = () => {}, disableEdit= false, disableDelete = false}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose =  () => {
        setAnchorEl(null)
    }

    const handleEdit = () => {
        setAnchorEl(null)
        onEdit()
    }

    const handleDelete = () => {
        setAnchorEl(null)
        onDelete()
    }

    const renderRow = (label, value, {hide = false, valueIsGray = false} = {}) => {
        if (!value || !label || !!hide) return null

        return (
            <div className="sliBodyHolder">
                <div className="caption">
                    {label}
                </div>
                <div className='qnt' style={!valueIsGray ? {color: '#000'}: {}}>
                    {`${value}`}
                </div>
            </div>
        )
    }

    return (
        <li className="stepsListItem">
            <div className="sliContent">
                <div className="sliTitleHolder">
                    <div className="sliTitle">
                    {t("Campaigns.Step")} {item?.name.replace(/\D/g, '')} - {t("Campaigns.Sms")}
                    <span className={`status ${item?.status}`} style={{paddingLeft: '5px'}}>
                        ({item?.status === 'not_sent' ? t('Campaigns.Status NotSent') : t('Campaigns.Status Sent')})
                    </span>
                    </div>
                    <div className="sliSubMenu">
                        <IconButton aria-label="expand row" size="small" onClick={() => setShowDetails(s => !s)}>
                            {showDetails ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                        </IconButton>
                        <IconButton onClick={handleClick}>
                            <MoreVertIcon/>
                        </IconButton>
                    </div>
                    {
                        item && (
                            <Menu
                                id="fade-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <MenuItem
                                    // disabled={['lookup_finished', 'sent'].includes(item.status)}
                                    disabled={disableEdit}
                                    onClick={handleEdit}>
                                    {t('Campaign Step Item.Edit')}
                                </MenuItem>
                                <MenuItem
                                    disabled={disableDelete}
                                    onClick={handleDelete}
                                >
                                    {t('Campaign Step Item.Delete')}
                                </MenuItem>
                            </Menu>
                        )
                    }
                </div>
                {renderRow(t('Campaign Step Item.Start Time'), toServerDatetimeFormat(item.started_at))}
                {renderRow(t('Campaign Step Item.Delay'), item.delay_label)}
                {renderRow(item?.message, ` `, {
                    valueIsGray: true
                })}

                <div style={{position: "relative"}}>
                    <SmsCounter
                        text={item?.message || ""}
                        styleParam={{position: "absolute", top: -20, right: 15, left: "initial"}}
                    />
                </div>


                <Collapse in={showDetails} timeout="auto" unmountOnExit>
                    <Box>
                        {renderRow(t('Campaign Step Item.Domain'), item.short_url)}
                        {renderRow(t('Campaign Step Item.Cost'), item.actual_cost, {
                            hide: item.type === 'lookup'
                        })}
                        {renderRow(t('Campaign Step Item.Sent'), item.amount_sent)}
                        {renderRow(t('Campaign Step Item.Delivered'), item.amount_delivered)}
                        {renderRow(t('Campaign Step Item.DeliveredPercent'), item.percentage_delivered)}
                        {renderRow(t('Campaign Step Item.Clicked'), item.amount_clicked)}
                        {renderRow(t('Campaign Step Item.ClickedPercent'), item.percentage_clicked)}
                        {renderRow(t('Campaign Step Item.Error'), item.amount_error)}
                        {renderRow(t('Campaign Step Item.Limit Cap'), item.limit_cap)}

                    </Box>
                </Collapse>
            </div>
        </li>
    )
}

export default CampaignStepItem
