import React, { useEffect, useState, useMemo, useCallback } from 'react'
import DataService from '../../Services/DataService'
import AlertToastr from '../../Utils/alert'
import ListTable from '../../components/ListTable/ListTable'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { getList, getListLeads, getSource } from '../../api'
import ListHeader from '../../components/ListHeader'
import { useAppDispatch } from '../../Contexts/AppContext'
import ListPageActions from './ListPageActions'
import { formatBlackListRow } from '../../helpers'
import {toServerDatetimeFormat} from '../../Utils/dates'
import LeadStatus from '../../components/LeadStatus'
import { storageKeys } from '../../config'
import EventEmitter from '../../Utils/EventEmitter'
import {useTranslation} from "react-i18next";

const ListPage = () => {
    const { t } = useTranslation();
    const leadValue = formatBlackListRow
    const {sourceId, listId} = useParams()
    const [list, setList] = useState(null)
    const [leadsAttributes, setLeadsAttributes] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [source, setSource] = useState(null)
    const [leads, setLeads] = useState([])
    const [search, setSearch] = useState()
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)

    const [pagination, setPagination] = useState(null)
    const history = useHistory()
    const queryClient = useQueryClient()
    const [updatingStatus, setUpdatingStatus] = useState(false)
    const {setBreadcrumbs, setSection} = useAppDispatch()
    const tableColumns = useMemo(() => {
        const attributes = leadsAttributes.map(attr => {
            return {
                Header: attr.name,
                accessor: `${attr.id}`,
                Cell: leadValue
            }
        })
        return [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: leadValue
            },
            {
                Header: t('Sources.Updated Date'),
                accessor: 'updated_at',
                Cell: ({value, row}) => {
                    return leadValue({value: toServerDatetimeFormat(value), row})
                }
            },
            ...attributes,
            {
                Header: t('Sources.Duplicates'),
                accessor: 'duplicates',
                Cell: leadValue
            },
            {
                Header: t('Sources.Status'),
                accessor: 'status',
                className: 'col-sticky text-right',
                Cell: ({value}) => <LeadStatus status={value} />
            }
        ]
    }, [leadsAttributes, leadValue, t])
    const tableValues = useMemo(() => {
        return leads.map(item => {
            const leadValues = item.lead_values
            const newItem = {...item}
            leadValues.forEach(value => {
                const attrId = value && value.attribute && value.attribute.id
                if (attrId)
                    newItem[attrId] = newItem[attrId]
                        ? `${newItem[attrId]}; ${value.value}`
                        : value.value
            })
            delete newItem['lead_values']
            return newItem
        })
    }, [leads])

    const [showQuickInsertForm, setShowQuickInsertForm] = useState(false)

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ['listLeads', listId, page, limit, search, sorting],
        () => getListLeads(listId, page, limit, search, sorting),
        {keepPreviousData: true}
    )

    const tableRecordsInfo = useMemo(() => {
        const info = []
        if (pagination) {
            if (pagination.total)
                info.push({count: pagination.total, label: t('Sources.Leads')})
            if (pagination.total_blacklisted)
                info.push({count: pagination.total_blacklisted, label: t('Sources.Blacklisted')})
        }
        return info
    }, [pagination])

    const {
        isSuccess: isListSuccess,
        data: listData,
        isLoading: isListLoading
    } = useQuery(
        ['listView', listId],
        () => getList(listId),
        {keepPreviousData: true}
    )

    const {
        isSuccess: isSourceSuccess,
        data: sourceData,
        isLoading: isSourceLoading
    } = useQuery(
        ['sourceView', sourceId],
        () => getSource(sourceId),
        {keepPreviousData: true}
    )

    useEffect(() => {
        setBreadcrumbs([
            {label: t('Sources.Sources'), link: '/sources'},
            {label: source && source.name, link: `/sources/${sourceId}`, loading: !source},
            {label: list && list.name, loading: !list}
        ])
        setSection('sources')
    }, [setBreadcrumbs, source, list, sourceId, setSection, t])

    useEffect(() => {
        if (isSuccess) {
            setLeads(data.data)
            setPagination(data.meta)
        }
    }, [isSuccess, data])

    useEffect(() => {
        if (isListSuccess) {
            setList(listData.data)

            if (listData.attributes) {
                setLeadsAttributes(listData.attributes)
            }
        }
    }, [isListSuccess, listData, setList])

    useEffect(() => {
        if (isSourceSuccess) setSource(sourceData.data)
    }, [isSourceSuccess, sourceData, setSource])

    useEffect(() => {
        const onEvent = () => setShowQuickInsertForm(s => !s)

        const listener = EventEmitter.addListener('LeadQuickInsert', onEvent)
        return () => { listener.remove() }
    }, [])

    const updateListStatus = () => {
        setUpdatingStatus(true)
        DataService.updateList(list.id, list.name, !list.is_active ? 'active' : 'inactive')
            .then(() => {
                queryClient.invalidateQueries('listView').then(() => {
                    setUpdatingStatus(false)
                })
            })
            .catch((error) => {
                setUpdatingStatus(false)
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            })
    }

    const handleItemClick = item => {
        history.push(`/sources/${source?.id}/${list?.id}/${item?.id}`)
    }

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])

    const handleSearchChange = useCallback((str) => {
        setPage(1)
        setSearch(str)
    }, [setPage, setSearch])

    const onQuickInsertSuccess = () => {
        setShowQuickInsertForm(false)
        AlertToastr.showAlert(t('Sources.Lead has been successfully added'))
    }

    return (
        <div className="pageHolder">
            <div className="pageHeadingBlock mb15">
                <ListHeader
                    list={list}
                    isSuccess={isListSuccess}
                    isLoading={isListLoading}
                    isFetching={updatingStatus}
                    updateListStatus={updateListStatus}
                />
            </div>
            <ListTable
                showInsertRow={true}
                columns={tableColumns}
                data={tableValues}
                loading={isLoading}
                fetching={isFetching}
                isSelectionColumn={true}
                onSelectedRows={setSelectedRows}
                onRowClick={handleItemClick}
                onSort={setSorting}
                emptyDataText={t('Sources.No Leads Found')}
                pagination={pagination}
                pageLimit={limit}
                onPageChange={setPage}
                onLimitChange={handleLimitChange}
                onSearch={handleSearchChange}
                recordsInfo={tableRecordsInfo}
                hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInList}
                showQuickInsertForm={showQuickInsertForm}
                onQuickInsertFormSuccess={onQuickInsertSuccess}
                quickInsertFormData={{
                    source_id: sourceId,
                    list_id: listId
                }}
            />
            <ListPageActions
                source={source}
                list={list}
                selectedRows={selectedRows}
                loading={isListLoading || isSourceLoading}
            />
        </div>
    )
}

export default ListPage
