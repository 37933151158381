import { useEffect } from 'react';
import Echo from 'laravel-echo';
import { useAuth } from '../Contexts/AuthProvider';
import { useAppDispatch } from '../Contexts/AppContext';
import AlertToastr from '../Utils/alert';
import { useQueryClient } from 'react-query';

window.Pusher = require('pusher-js');

const ImportSubscription = ({ onIncomingData, onLookupData }) => {

    const { token, userId } = useAuth();
    const { addExportFile, setBalance } = useAppDispatch();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!token || !userId)
            return;

        if (window.Echo)
            return;

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: 'local',
            cluster: 'mt1',
            forceTLS: false,
            wsHost: process.env.REACT_APP_PUSHER_HOST,
            wsPort: process.env.REACT_APP_PUSHER_PORT,
            wssPort: process.env.REACT_APP_PUSHER_PORT,
            disableStats: true,
            encrypted: true,
            authEndpoint: process.env.REACT_APP_AUTH_ENDPOINT,
            auth: { headers: { Authorization: `Bearer ${token}` } },
        });
        window.Echo.private(`App.Models.User.${userId}`)
            .listen('TeamOperatorAction', data => {
                if (data['action'] === 'lead_created') {
                    queryClient.invalidateQueries('leadsList').then();
                }
            })
            .listen('ImportStatus', e => {
                onIncomingData(e);
                const { type, client_original_name, parsed_leads_count, duplicates_resolved, current, total, error } = e;
                const value = parsed_leads_count || duplicates_resolved || '-';
                let message = `${type} (${client_original_name})@ ${value}`;
                if (type === 'start_resolve_duplicates') {
                    message = `${type} (${client_original_name})@ total steps: ${total}`;
                }
                if (current && total) message = `step ${current} from ${total}: ${message}`;
                console.log(message);
                if (error && error === 'import_limit_reached') {
                    AlertToastr.showErrorAlert('import_limit_reached');
                }
                console.log(error, 'import error');
            })
            .listen('ExportStatus', data => addExportFile(data))
            .listen('BalanceChanged', data => {
                if (data && data.hasOwnProperty('balance')) setBalance(data.balance);
            })
            .listen('LookupStatus', data => {
                onLookupData(data);
            });

        return () => {

        };
    }, [
        setBalance,
        onIncomingData,
        onLookupData,
        token,
        userId,
        addExportFile,
        queryClient,
    ]);

    return null;
};

export default ImportSubscription;
