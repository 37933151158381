import React from 'react'
import Route from './route'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../../Contexts/AuthProvider'

const GuestRoute = ({ ...rest }) => {
    const { isLoggedIn } = useAuth()
    return (
        !isLoggedIn ? <Route {...rest} /> : <Redirect to='/' />
    )
}

export default GuestRoute
