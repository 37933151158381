import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getCampaignLeads } from '../../api'
import ListTable from '../../components/ListTable/ListTable'
import SyncCampaignResourcesBlock from '../../components/SyncCampaignResourcesBlock'
import { formatBlackListRow } from '../../helpers'
import {toServerDatetimeFormat} from '../../Utils/dates'
import LeadStatus from '../../components/LeadStatus'
import { storageKeys } from '../../config'
import {useTranslation} from "react-i18next";

const CampaignAudience = () => {
    const { t } = useTranslation();
    const leadValue = formatBlackListRow
    const {campaignId} = useParams()
    const [search, setSearch] = useState()
    const [sorting, setSorting] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const history = useHistory()

    const [leads, setLeads] = useState([])
    const [leadsAttributes, setLeadsAttributes] = useState([])
    const [pagination, setPagination] = useState(null)
    const tableColumns = useMemo(() => {
        const attributes = leadsAttributes.map(attr => {
            return {
                Header: attr.name,
                accessor: `${attr.id}`,
                Cell: leadValue
            }
        })
        return [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: leadValue
            },
            {
                Header: t('Campaigns.Updated Date'),
                accessor: 'updated_at',
                Cell: ({value, row}) => {
                    return leadValue({value: toServerDatetimeFormat(value), row})
                }
            },
            ...attributes,
            {
                Header: t('Campaigns.Duplicates'),
                accessor: 'duplicates',
                Cell: leadValue
            },
            {
                Header: t('Campaigns.Status'),
                accessor: 'status',
                className: 'text-right',
                Cell: ({value}) => <LeadStatus status={value} />
            }
        ]
    }, [leadsAttributes, leadValue, t])
    const tableValues = useMemo(() => {
        if (!leads) return []
        return leads.map(item => {
            const leadValues = item.lead_values
            const newItem = {...item}
            leadValues.forEach(value => {
                const attrId = value && value.attribute && value.attribute.id
                if (attrId)
                    newItem[attrId] = newItem[attrId]
                        ? `${newItem[attrId]}; ${value.value}`
                        : value.value
            })
            delete newItem['lead_values']
            return newItem
        })
    }, [leads])
    const tableRecordsInfo = useMemo(() => {
        const info = []
        if (pagination) {
            if (pagination.total)
                info.push({count: pagination.total, label: t('Sources.Leads')})
            if (pagination.total_blacklisted)
                info.push({count: pagination.total_blacklisted, label: t('Sources.Blacklisted')})
        }
        return info
    }, [pagination])

    const {isSuccess, data, isLoading, isFetching} = useQuery(
        ['campaignLeads', campaignId, page, limit, search, sorting],
        () => getCampaignLeads(campaignId, page, limit, search, sorting),
        {keepPreviousData: true}
    )

    useEffect(() => {
        if (isSuccess) {
            setLeads(data.data)
            setPagination(data.meta)
            if (data.attributes) {
                setLeadsAttributes(data.attributes)
            }
        }
    }, [isSuccess, data])

    const handleItemClick = item => {
        history.push(`/leads/${item?.id}`)
    }

    const handleLimitChange = useCallback((l) => {
        setPage(1)
        setLimit(l)
    }, [setPage, setLimit])

    const handleSearchChange = useCallback((str) => {
        setPage(1)
        setSearch(str)
    }, [setPage, setSearch])

    return (
        <div className='campaignsPageHolder'>
            <SyncCampaignResourcesBlock />
            <div className="pageTableBlock">
                <ListTable
                    columns={tableColumns}
                    data={tableValues}
                    loading={isLoading}
                    fetching={isFetching}
                    isSelectionColumn={false}
                    // onSelectedRows={setSelectedRows}
                    onRowClick={handleItemClick}
                    onSort={setSorting}
                    emptyDataText={t('Campaigns.No Leads Found')}
                    pagination={pagination}
                    pageLimit={limit}
                    onPageChange={setPage}
                    onLimitChange={handleLimitChange}
                    onSearch={handleSearchChange}
                    recordsInfo={tableRecordsInfo}
                    // onHideColumns={setVisibleColumns}
                    hiddenColumnsStorageKey={storageKeys.leadsHiddenColumnsInCampaignAudience}
                />
            </div>
        </div>
    )
}

export default CampaignAudience
