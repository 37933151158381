import React, { Fragment, useState } from 'react'
import InlineLoader from './loaders/InlineLoader'
import {useTranslation} from "react-i18next";

const ListHeader = props => {
    const { t } = useTranslation();
    const {
        list,
        isLoading,
    } = props

    const [dummyAttributes] = useState([
        // { label: 'Company Name', value: 'Leadflow'},
        // { label: 'Phone', value: '+133322233344'},
        // { label: 'Email', value: 'info@leadsgate.com'}
    ])

    const renderName = () => {
        return isLoading ? <InlineLoader size={30} /> : list?.name
    }

    const renderAttributes = () => {
        return (
            <Fragment>
                {
                    dummyAttributes.map((item, index) => {
                        return (
                            <p className='text-mute mb5' key={index}>
                                <span className="text-semi-bold">{item.label}:</span>&nbsp;<span className="text-light">{item.value}</span>
                            </p>
                        )
                    })
                }
            </Fragment>
        )
    }

    const items = [
        {
            label: t('List Header.Total Leads'),
            count: list?.total_leads
        },
        {
            label: t('List Header.Today'),
            count: list?.total_today
        },
        {
            label: t('List Header.Last 30 Days'),
            count: list?.total_last_30_days
        },
        {
            label: t('List Header.Duplicate'),
            count: list?.duplicate
        }
    ]



    return (
        <div className="pageHeadingBlockWrapper">
            <div className="part main">
                <div className="partContent">
                    <div className="hasSwitch mb15">
                        <h2 className="textPrimary">{renderName()}</h2>
                    </div>
                    {renderAttributes()}
                </div>
            </div>
            {
                items.map(item => (
                    <div className="part" key={item.label}>
                        <div className="partContent">
                            <h2 className="mb10">{item.label}</h2>
                            <div>{isLoading ? <InlineLoader /> : item.count}</div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ListHeader
