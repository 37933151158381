import React from 'react';
import './LeadAttributeValue.css'

const LeadAttributeValue = ({value, ...rest}) => {
    return (
        <span className='caption attr-value' {...rest} style={{width: '100%'}}>
            {value}
        </span>
    );
};

export default LeadAttributeValue;
