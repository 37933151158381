import React, { useState } from "react";
import RequestDemoModal from "../modals/RequestDemoModal";
import { useAppDispatch, useAppState } from "../../Contexts/AppContext";

const ModalContainer = () => {

  const {isRequestDemoModalOpen} = useAppState();
  const {setRequestDemoModalOpen} = useAppDispatch();
  

  return (
    <RequestDemoModal
      isOpen={isRequestDemoModalOpen}
      onClose={() => setRequestDemoModalOpen(false)}
    />
  );
};

export default ModalContainer;
